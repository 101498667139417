import { ChangeEvent, Dispatch, SetStateAction } from "react"
import { Contact, InvitePerson } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { User } from "../../../globalStates/LoggedInUser"
import DateCalendarPicker from "../calendarEntry/DateCalendarPicker"
import LocationPicker from "../calendarEntry/LocationPicker"
import TextareaPersonalNote from "../calendarEntry/TextareaPersonalNote"
import TimezonePicker from "../calendarEntry/TimezonePicker"
import { buildDetailLink } from "../../../contentArea/detailPages/DetailNavLink"
import { MeetingSlotsDay } from "../../MeetingSlots"
import TimerCounter from "../calendarEntry/TimerCounter"
import TextInputField from "../calendarEntry/TextInput"
import {
    IconCalendarEntry,
    IconCalendarEntryGreen,
    IconClock,
    IconClockGreen,
    IconSearchSmall,
    IconValidInput
} from "../../Icons"
import { InputProps } from "./CalendarEntryModalData2"
import SearchBarPicker from "./SearchBarPicker"
import { CalendarEntry, CalendarEntryParticipation, deleteCalendarEntryForUser } from "../../../backendServices/GraphQLServices"
import { saveMeetings } from "../../../communicationArea/ICal"
import { ChimeContext } from "../../../conference/context/ChimeContext"
import { RouterChildContext } from "react-router-dom"
import { CalendarEntryParticipationStatus as CalendarEntryStatus } from "../../../API"
import {
    ButtonLink,
    Buttons,
    ExportMeeting,
    ParticipantStatus,
    RadioLabel,
    SubmitButtonContainerLinks,
    ToggleName,
    ToggleSwitchBackground,
    ToggleSwitcherWrapper,
    ValidationDiv
} from "./CalendarEntryModal.styled"
import TextLimit from "../../TextLimit"
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch"

export enum CalendarEntryModalViewMode {
    CREATE,
    EDIT,
    VIEW
}

export enum LocationPickerMode {
    STAND = "stand",
    CUSTOM = "custom",
    NO_LOCATION_PICKED = "no_location_picked"
}

export interface LocationOption {
    organizationName: string
    hallName: string
    standName: string
    standAlias: string
    label: string
    value: string
}

export interface Location {
    standId?: string // locationActionParam: stand_alias
    locationName?: string // stand_name | user location description
    locationActionType?: LocationActionType
}

export enum LocationActionType {
    STANDLINK = "standlink",
    CUSTOM = "",
    NONE = "" // redundant for better code readability
}

export enum CalendarEntryParticipationStatusColor {
    ACCEPTED = "#00B300",
    DECLINED = "#E30613",
    REQUESTED = "#FF8900"
}

export enum CalendarEntryParticipationStatus {
    REQUESTED = "REQUESTED",
    ACCEPTED = "ACCEPTED",
    DECLINED = "DECLINED"
}

export const renderInput = (
    item: InputProps,
    personClicked?: (person: Contact) => void,
    contactsInvited?: InvitePerson[],
    predefinedStartTime?: Date,
    meetingSlotsTable?: MeetingSlotsDay[],
    viewMode?: CalendarEntryModalViewMode,
    date?: Date | null,
    setDate?: Dispatch<SetStateAction<Date | null>>,
    setStartTime?: Dispatch<SetStateAction<Date | null>>,
    startTime?: Date | null,
    endTime?: Date | null,
    setEndTime?: Dispatch<SetStateAction<Date | null>>,
    selected?: string,
    location?: Location,
    setLocation?: Dispatch<SetStateAction<Location | undefined>>,
    setTitle?: Dispatch<SetStateAction<string>>,
    title?: string,
    selectedTimezone?: string,
    setSelectedTimezone?: Dispatch<SetStateAction<string>>,
    setShowErrorModal?: Dispatch<SetStateAction<boolean>>,
    setNote?: Dispatch<SetStateAction<string | undefined>>,
    note?: string | undefined,
    calendarEntry?: CalendarEntry,
    closeModal?: () => void,
    setIsVirtualMeeting?: Dispatch<SetStateAction<boolean>>,
    isVirtualMeeting?: boolean,
    setErrorModalMessage?: Dispatch<SetStateAction<string>>,
    canEditMeeting?: boolean,
    currentLocation?: string,
    setCurrentLocation?: Dispatch<SetStateAction<string>>,
    showErrorModal?: boolean,
    userStatus?: CalendarEntryStatus
) => {
    switch (item.inputType) {
        case "textarea":
            return (
                <TextareaPersonalNote
                    placeholder={item.inputPlaceholder}
                    setNote={setNote}
                    note={note}
                    calendarEntry={calendarEntry}
                    viewMode={canEditMeeting ? CalendarEntryModalViewMode.EDIT : viewMode}
                    maxNoteLength={255}
                />
            )
        case "select":
            return (
                <TimezonePicker
                    selectedTimezone={selectedTimezone}
                    setSelectedTimezone={setSelectedTimezone}
                    calendarEntry={calendarEntry}
                    viewMode={canEditMeeting ? CalendarEntryModalViewMode.EDIT : viewMode}
                />
            )
        case "text-with-dropdown":
            return (
                <LocationPicker
                    placeholder={item.inputPlaceholder}
                    customPlaceholder={item.customPlaceholder}
                    selected={selected}
                    location={location}
                    setLocation={setLocation}
                    calendarEntry={calendarEntry}
                    showToggleSwitcher={item.showToggleSwitcher}
                    setIsVirtualMeeting={setIsVirtualMeeting}
                    isVirtualMeeting={isVirtualMeeting}
                    viewMode={canEditMeeting ? CalendarEntryModalViewMode.EDIT : viewMode}
                    canEditMeeting={canEditMeeting}
                    currentLocation={currentLocation}
                    setCurrentLocation={setCurrentLocation}
                />
            )
        case "date":
            return (
                <DateCalendarPicker
                    className={item.className}
                    placeholder={item.inputPlaceholder}
                    showIconInInput={item.showIconInInput}
                    icon={item.icon}
                    predefinedStartTime={predefinedStartTime}
                    meetingSlotsTable={meetingSlotsTable}
                    time={item.time}
                    viewMode={canEditMeeting ? CalendarEntryModalViewMode.EDIT : viewMode}
                    date={date}
                    setDate={setDate}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    setShowErrorModal={setShowErrorModal}
                    calendarEntry={calendarEntry}
                    setErrorModalMessage={setErrorModalMessage}
                    showErrorModal={showErrorModal}
                />
            )
        case "search":
            return (
                <SearchBarPicker
                    viewMode={canEditMeeting ? CalendarEntryModalViewMode.EDIT : viewMode}
                    calendarEntryModal={true}
                    selectedTimezone={selectedTimezone}
                    // start={dateWithUTCIncrasedByTimezone(selectedTimezone, start!, day!)}
                    // end={dateWithUTCIncrasedByTimezone(selectedTimezone, end!, day!)}
                    personClicked={personClicked}
                    excludeIds={contactsInvited?.map((p) => p.id)}
                    contactsInvited={contactsInvited}
                    calendarEntry={calendarEntry}
                    closeModal={closeModal}
                    userStatus={userStatus}
                />
            )
        case "counter":
            return <TimerCounter startTime={startTime} endTime={endTime} calendarEntry={calendarEntry} viewMode={viewMode} />
        default:
            return (
                <TextInputField
                    className={item.className}
                    inputType={item.inputType}
                    inputPlaceholder={item.inputPlaceholder}
                    showIconInInput={item.showIconInInput}
                    icon={item.icon}
                    setTitle={setTitle}
                    title={title}
                    calendarEntry={calendarEntry}
                    viewMode={canEditMeeting ? CalendarEntryModalViewMode.EDIT : viewMode}
                    showErrorModal={showErrorModal}
                />
            )
    }
}

export const renderIcon = (icon: string | undefined, activeCalendarIcon?: boolean) => {
    switch (icon) {
        case "calendar":
            return activeCalendarIcon ? (
                <IconCalendarEntryGreen
                    width="14px"
                    height="14px"
                    fill={branding.calendarEntryModalPageContent.calendarIconActiveColor}
                />
            ) : (
                <IconCalendarEntry width="14px" height="14px" fill={branding.sideIconBar.sideIconColorDark} />
            )
        case "clock":
            return activeCalendarIcon ? (
                <IconClockGreen fill={branding.calendarEntryModalPageContent.calendarIconActiveColor} />
            ) : (
                <IconClock fill={branding.sideIconBar.sideIconColorDark} />
            )
        case "search":
            return <IconSearchSmall />
    }
}

export function shouldUseHallName(data: LocationOption): boolean {
    if (data.hallName === null || data.hallName === "" || data.hallName === undefined) {
        return false
    }
    if (data.standName.includes(data.hallName)) {
        return false
    }
    if (data.standName.includes("|")) {
        return false
    }
    return true
}

export function removePerson(
    item: any,
    setContactsInvited: Dispatch<SetStateAction<InvitePerson[]>>,
    contactsInvited: InvitePerson[]
) {
    setContactsInvited(contactsInvited.filter((it) => it.id !== item.id))
}

export function openDetailPage(contact: InvitePerson, user: User) {
    if (contact.id !== user?.profileId) {
        let link =
            window.location.hostname === "localhost"
                ? `http://localhost:3000${buildDetailLink(contact.id, contact.firstName + contact.lastName ?? "", "user")}`
                : `https://${window.location.hostname}${buildDetailLink(
                      contact.id,
                      contact.firstName + contact.lastName ?? "",
                      "user"
                  )}`
        window.open(link, "_blank", "noopener,noreferrer")
    }
}

export function showModalMeetingTitle(viewMode?: CalendarEntryModalViewMode, title?: string, canEditMeeting?: boolean) {
    switch (viewMode) {
        case CalendarEntryModalViewMode.CREATE:
            return branding.calendarEntryModalPageContent.createTitle
        case CalendarEntryModalViewMode.EDIT:
            return branding.calendarEntryModalPageContent.editTitle
        case CalendarEntryModalViewMode.VIEW:
            return canEditMeeting
                ? branding.calendarEntryModalPageContent.editTitle
                : branding.calendarEntryListView.viewButtonTitle
        default:
            return title
    }
}

export function loadTime(time: string, day?: Date, startDate?: Date, endDate?: Date) {
    switch (time) {
        case "start":
            return startDate
        case "end":
            return endDate
        default:
            return day
    }
}

export const clearLocationSearchInput = (setCurrentValue?: Dispatch<SetStateAction<string>>) => {
    setCurrentValue!("")
}

export const showLocationDropdownListAndCloseIcon = (
    currentValue: string,
    setOpenLocationDropdownList: Dispatch<SetStateAction<boolean>>,
    setShowCrossIcon: Dispatch<SetStateAction<boolean>>
) => {
    if (currentValue!.length === 0) {
        setOpenLocationDropdownList(false)
        setShowCrossIcon(true)
    } else {
        setOpenLocationDropdownList(true)
        setShowCrossIcon(false)
    }
}

export function onInputTextChangedNotes(
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>,
    maxLength: number,
    setText: Dispatch<SetStateAction<string | undefined>>
) {
    let value = event.target.value ?? ""
    if (value.length > maxLength) {
        setText(value.slice(0, maxLength))
        return
    }
    setText(event.target.value)
}

export function onInputTextChanged(
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>,
    maxLength: number,
    setText: Dispatch<SetStateAction<string>>
) {
    let value = event.target.value ?? ""
    if (value.length > maxLength) {
        setText(value.slice(0, maxLength))
        return
    }
    setText(event.target.value)
}

export function calculateStartEndDate(startTime: Date, endTime: Date): number {
    let sum: number = 0

    if (startTime && endTime) {
        const endHours = startTime.getHours() > 0 && endTime.getHours() === 0 ? 24 : endTime.getHours()

        sum = endHours * 60 + endTime.getMinutes() - (startTime.getHours() * 60 + startTime.getMinutes())
    }

    return sum
}

export const handleHorizontalScroll = (element: HTMLDivElement | null, speed: number, distance: number, step: number) => {
    let scrollAmount = 0
    const sliderTimer = setInterval(() => {
        element!.scrollLeft += step
        scrollAmount += Math.abs(step)
        if (scrollAmount >= distance) {
            clearInterval(sliderTimer)
        }
    }, speed)
}

export function generateHoursInterval(startHourInMinute: number, endHourInMinute: number, interval: number) {
    const times = []

    for (let i = 0; startHourInMinute < 24 * 60; i++) {
        if (startHourInMinute > endHourInMinute) break

        var hh = Math.floor(startHourInMinute / 60) // getting hours of day in 0-24 format

        var mm = startHourInMinute % 60 // getting minutes of the hour in 0-55 format

        times[i] = ("0" + (hh % 24)).slice(-2) + ":" + ("0" + mm).slice(-2)

        startHourInMinute = startHourInMinute + interval
    }

    return times
}

export function createPersonLabel(contact: InvitePerson): string {
    let label = ""
    if (!contact.firstName && !contact.lastName) label = contact.name ?? ""
    else label = [contact.firstName, contact.lastName].filter(Boolean).join(" ")
    return label
}

export function calculateDivWidth(timer: number) {
    let divWidth = timer * 3
    return divWidth
}

export function calculatePositionLeft(time: Date | null, left: number, position?: "start" | "end") {
    if (time) {
        let hours = time?.getHours()! === 0 && time?.getMinutes()! === 0 && position === "end" ? 24 : time?.getHours()
        left = hours * 180
        if (time.getMinutes() === 30) left += 90
        else if (time.getMinutes() === 15) left += 45
        else if (time.getMinutes() === 45) left += 135
    }

    return left
}

export function convertTimeIntoWidth(startTime: Date, endTime: Date) {
    let startMinutes = new Date(startTime).getMinutes()
    let startHours = new Date(startTime).getHours()
    let startSum = 0

    let endMinutes = new Date(endTime).getMinutes()
    let endHours = new Date(endTime).getHours()
    let endSum = 0

    startSum = startHours * 180
    if (startMinutes === 30) startSum += 90
    else if (startMinutes === 15) startSum += 45
    else if (startMinutes === 45) startSum += 135

    endSum = endHours * 180
    if (endMinutes === 30) endSum += 90
    else if (endMinutes === 15) endSum += 45
    else if (endMinutes === 45) endSum += 135

    return endSum - startSum
}

export function getPositionLeft(startTime: Date) {
    let startMinutes = new Date(startTime).getMinutes()
    let startHours = new Date(startTime).getHours()
    let startSum = 0

    startSum = startHours * 180
    if (startMinutes === 30) startSum += 90
    else if (startMinutes === 15) startSum += 45
    else if (startMinutes === 45) startSum += 135

    return startSum
}

export function exportMeeting(calendarEntry: CalendarEntry) {
    if (calendarEntry) saveMeetings([calendarEntry])
}

export const resetTimes = (
    setStartTime: Dispatch<SetStateAction<Date | null>>,
    setEndTime: Dispatch<SetStateAction<Date | null>>
) => {
    const resetStartDate = new Date()
    const resetEndDate = new Date()
    resetEndDate.setMinutes(resetStartDate.getMinutes() + 10)
    setStartTime(resetStartDate)
    setEndTime(resetEndDate)
}

export async function deleteCalendarEntry(
    setShowCancelModal: Dispatch<SetStateAction<boolean>>,
    profileId: string | undefined,
    calendarEntry: CalendarEntry | undefined,
    onCloseModalView: () => void,
    onRemovedFromList?: () => void
) {
    setShowCancelModal(false)
    if (profileId != null && calendarEntry?.id != null) {
        const result = await deleteCalendarEntryForUser(profileId, calendarEntry.id)

        if (result != null) {
            onCloseModalView()
            if (onRemovedFromList) {
                onRemovedFromList()
            }
        } else {
            // TODO ERROR WHAT TO SHOW HERE?
        }
    }
}

export async function joinMeeting(
    calendarEntry: CalendarEntry | undefined,
    closeModal: () => void,
    chimeContext: ChimeContext,
    history: RouterChildContext["router"]["history"]
) {
    if (calendarEntry) {
        closeModal()
        if (branding.configuration.useConferenceRoomV2) {
            history.push(`/meetingV2/ce_${calendarEntry.id}`)
        } else {
            chimeContext.createOrJoinMeeting(calendarEntry.id, "calenderEntry")
        }
    }
}

export function showButtons(
    viewMode: CalendarEntryModalViewMode | undefined,
    closeModal: () => void,
    calendarEntry: CalendarEntry | undefined,
    user: User | undefined,
    setShowDeclineModal: Dispatch<SetStateAction<boolean>>,
    chimeContext: ChimeContext,
    history: RouterChildContext["router"]["history"],
    setShowCancelModal: Dispatch<SetStateAction<boolean>>,
    createOrUpdateCalendarEntry?: (selectedTimezone: string) => void,
    selectedTimezone?: string,
    userStatus?: any,
    acceptMeeting?: (participation: CalendarEntryParticipation) => void,
    isUserWithRequestedStatus?: CalendarEntryParticipation | undefined,
    isPastMeeting?: boolean
) {
    switch (viewMode) {
        case CalendarEntryModalViewMode.CREATE:
            return (
                <SubmitButtonContainerLinks isFlexEnd={true}>
                    <Buttons>
                        <ButtonLink
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.preventDefault()
                                closeModal()
                            }}
                            backgroundColor={branding.calendarEntryModalPageContent.acceptCancelButtonBackgroundColor}
                            color={branding.calendarEntryModalPageContent.acceptCancelButtonTextColor}
                            borderColor={branding.calendarEntryModalPageContent.acceptCancelButtonBorderColor}
                        >
                            {branding.calendarEntryModalPageContent.cancel}
                        </ButtonLink>
                        <ButtonLink
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.preventDefault()
                                createOrUpdateCalendarEntry!(selectedTimezone!)
                            }}
                            backgroundColor={branding.calendarEntryModalPageContent.joinSendInvitebuttonBackgroundColor}
                            borderColor={branding.calendarEntryModalPageContent.joinSendInvitebuttonBorderColor}
                            color={branding.calendarEntryModalPageContent.joinSendInvitebuttonTextColor}
                        >
                            {branding.calendarEntryModalPageContent.sendInvite}
                        </ButtonLink>
                    </Buttons>
                </SubmitButtonContainerLinks>
            )
        case CalendarEntryModalViewMode.VIEW:
            return (
                <SubmitButtonContainerLinks>
                    <ExportMeeting
                        color={branding.calendarEntryModalPageContent.exportMeetingTextColor}
                        onClick={() => exportMeeting(calendarEntry!)}
                    >
                        {branding.calendarEntryModalPageContent.exportMeeting}
                    </ExportMeeting>
                    {calendarEntry?.userId !== user?.profileId &&
                    userStatus?.status === CalendarEntryParticipationStatus.ACCEPTED ? (
                        <Buttons>
                            {!isPastMeeting && (
                                <ButtonLink
                                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        e.preventDefault()
                                        setShowDeclineModal(true)
                                    }}
                                    color={branding.calendarEntryModalPageContent.declineMeetingButtonTextColor}
                                    borderColor={branding.calendarEntryModalPageContent.declineMeetingButtonBorderColor}
                                    backgroundColor={branding.calendarEntryModalPageContent.declineMeetingButtonBackgroundColor}
                                >
                                    {branding.calendarEntryListView.declineButtonTitle}
                                </ButtonLink>
                            )}
                            <ButtonLink
                                onClick={() => joinMeeting(calendarEntry, closeModal, chimeContext, history)}
                                backgroundColor={branding.calendarEntryModalPageContent.joinSendInvitebuttonBackgroundColor}
                                color={branding.calendarEntryModalPageContent.joinSendInvitebuttonTextColor}
                                borderColor={branding.calendarEntryModalPageContent.joinSendInvitebuttonBorderColor}
                            >
                                {branding.calendarEntryListView.callButtonTitle}
                            </ButtonLink>
                        </Buttons>
                    ) : calendarEntry?.userId === user?.profileId &&
                      userStatus.status === CalendarEntryParticipationStatus.ACCEPTED ? (
                        <Buttons>
                            {isPastMeeting && (
                                <ButtonLink
                                    disabled
                                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        e.preventDefault()
                                        setShowCancelModal(true)
                                    }}
                                    color={branding.calendarEntryModalPageContent.declineMeetingButtonTextColor}
                                    borderColor={branding.calendarEntryModalPageContent.declineMeetingButtonBorderColor}
                                    backgroundColor={branding.calendarEntryModalPageContent.declineMeetingButtonBackgroundColor}
                                >
                                    {branding.calendarEntryListView.deleteButtonTitle}
                                </ButtonLink>
                            )}
                            {!isPastMeeting && (
                                <ButtonLink
                                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        e.preventDefault()
                                        setShowCancelModal(true)
                                    }}
                                    color={branding.calendarEntryModalPageContent.declineMeetingButtonTextColor}
                                    borderColor={branding.calendarEntryModalPageContent.declineMeetingButtonBorderColor}
                                    backgroundColor={branding.calendarEntryModalPageContent.declineMeetingButtonBackgroundColor}
                                >
                                    {branding.calendarEntryListView.deleteButtonTitle}
                                </ButtonLink>
                            )}
                            <ButtonLink
                                onClick={() => joinMeeting(calendarEntry, closeModal, chimeContext, history)}
                                backgroundColor={branding.calendarEntryModalPageContent.joinSendInvitebuttonBackgroundColor}
                                color={branding.calendarEntryModalPageContent.joinSendInvitebuttonTextColor}
                                borderColor={branding.calendarEntryModalPageContent.joinSendInvitebuttonBorderColor}
                            >
                                {branding.calendarEntryListView.callButtonTitle}
                            </ButtonLink>
                        </Buttons>
                    ) : userStatus?.status === CalendarEntryParticipationStatus.REQUESTED ? (
                        <Buttons>
                            <ButtonLink
                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    e.preventDefault()
                                    setShowDeclineModal(true)
                                }}
                                color={branding.calendarEntryModalPageContent.declineMeetingButtonTextColor}
                                backgroundColor={branding.calendarEntryModalPageContent.declineMeetingButtonBackgroundColor}
                                borderColor={branding.calendarEntryModalPageContent.declineMeetingButtonBorderColor}
                            >
                                {branding.calendarEntryListView.declineButtonTitle}
                            </ButtonLink>
                            {!isPastMeeting && (
                                <ButtonLink
                                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        e.preventDefault()
                                        acceptMeeting!(isUserWithRequestedStatus!)
                                        closeModal()
                                    }}
                                    color={branding.calendarEntryModalPageContent.acceptCancelButtonTextColor}
                                    backgroundColor={branding.calendarEntryModalPageContent.acceptCancelButtonBackgroundColor}
                                    borderColor={branding.calendarEntryModalPageContent.acceptCancelButtonBorderColor}
                                >
                                    {branding.calendarEntryModalPageContent.acceptButtonText}
                                </ButtonLink>
                            )}
                        </Buttons>
                    ) : (
                        <Buttons>
                            {!isPastMeeting && (
                                <ButtonLink
                                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        e.preventDefault()
                                        acceptMeeting!(isUserWithRequestedStatus!)
                                        closeModal()
                                    }}
                                    color={branding.calendarEntryModalPageContent.acceptCancelButtonTextColor}
                                    backgroundColor={branding.calendarEntryModalPageContent.acceptCancelButtonBackgroundColor}
                                    borderColor={branding.calendarEntryModalPageContent.acceptCancelButtonBorderColor}
                                >
                                    {branding.calendarEntryModalPageContent.acceptButtonText}
                                </ButtonLink>
                            )}
                        </Buttons>
                    )}
                </SubmitButtonContainerLinks>
            )
        case CalendarEntryModalViewMode.EDIT:
            return (
                <SubmitButtonContainerLinks>
                    <ExportMeeting
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.preventDefault()
                            setShowCancelModal(true)
                        }}
                        color={branding.calendarEntryModalPageContent.cancelMeetingTextColor}
                    >
                        {branding.calendarEntryModalPageContent.deleteMeeting}
                    </ExportMeeting>
                    <Buttons>
                        <ButtonLink
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.preventDefault()
                                closeModal()
                            }}
                            color={branding.calendarEntryModalPageContent.acceptCancelButtonTextColor}
                            borderColor={branding.calendarEntryModalPageContent.acceptCancelButtonBorderColor}
                            backgroundColor={branding.calendarEntryModalPageContent.acceptCancelButtonBackgroundColor}
                        >
                            {branding.calendarEntryModalPageContent.cancel}
                        </ButtonLink>
                        <ButtonLink
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.preventDefault()
                                createOrUpdateCalendarEntry!(selectedTimezone!)
                            }}
                            backgroundColor={branding.calendarEntryModalPageContent.joinSendInvitebuttonBackgroundColor}
                            borderColor={branding.calendarEntryModalPageContent.joinSendInvitebuttonBorderColor}
                            color={branding.calendarEntryModalPageContent.joinSendInvitebuttonTextColor}
                        >
                            {branding.calendarEntryModalPageContent.saveChanges}
                        </ButtonLink>
                    </Buttons>
                </SubmitButtonContainerLinks>
            )
    }
}

export function showValidationIconAndTextLimitField(
    viewMode: CalendarEntryModalViewMode | undefined,
    showTextLimit: boolean,
    title: string | undefined,
    showValidIcon: boolean,
    MAX_NOTES_LENGTH: number
) {
    switch (viewMode) {
        case CalendarEntryModalViewMode.VIEW:
            return (
                <ValidationDiv>
                    <IconValidInput
                        width="14px"
                        height="14px"
                        fill={branding.calendarEntryModalPageContent.validationIconColor}
                    />
                </ValidationDiv>
            )
        case CalendarEntryModalViewMode.CREATE:
            return (
                <>
                    {showTextLimit && (
                        <TextLimit
                            position="absolute"
                            bottom="7px"
                            right="5px"
                            textLength={title?.length || 0}
                            maxTextLength={MAX_NOTES_LENGTH}
                        />
                    )}
                    {showValidIcon && title && title?.length !== 0 && (
                        <ValidationDiv>
                            <IconValidInput
                                width="14px"
                                height="14px"
                                fill={branding.calendarEntryModalPageContent.validationIconColor}
                            />
                        </ValidationDiv>
                    )}
                </>
            )
        case CalendarEntryModalViewMode.EDIT:
            return (
                <>
                    {showTextLimit && title && title.length !== 0 && (
                        <TextLimit
                            position="absolute"
                            bottom="7px"
                            right="5px"
                            textLength={title?.length || 0}
                            maxTextLength={MAX_NOTES_LENGTH}
                        />
                    )}
                    {showValidIcon && title && title.length !== 0 && (
                        <ValidationDiv>
                            <IconValidInput
                                width="14px"
                                height="14px"
                                fill={branding.calendarEntryModalPageContent.validationIconColor}
                            />
                        </ValidationDiv>
                    )}
                </>
            )
    }
}

export function showToggleSwitcherUnderLocationInput(
    viewMode: CalendarEntryModalViewMode | undefined,
    isVirtualMeeting: boolean | undefined,
    setIsVirtualMeeting: Dispatch<SetStateAction<boolean>> | undefined
) {
    switch (viewMode) {
        case CalendarEntryModalViewMode.CREATE:
            return (
                <ToggleSwitcherWrapper>
                    <RadioLabel>
                        <ToggleName>{branding.calendarEntryModalPageContent.locationPickerVirtualMeetingLabel}</ToggleName>
                    </RadioLabel>

                    <ToggleSwitchBackground>
                        <ToggleSwitch
                            activatedToggleBorderColor={
                                branding.calendarEntryModalPageContent.locationPickerVirtualMeetingToggleColor
                            }
                            activatedSliderBorderColor={
                                branding.calendarEntryModalPageContent.locationPickerVirtualMeetingToggleColor
                            }
                            isEnabled={isVirtualMeeting}
                            onClick={() => setIsVirtualMeeting!(!isVirtualMeeting)}
                            scaleSize={0.5}
                        />
                    </ToggleSwitchBackground>
                </ToggleSwitcherWrapper>
            )
        case CalendarEntryModalViewMode.EDIT:
            return (
                <ToggleSwitcherWrapper>
                    <ToggleName>{branding.calendarEntryModalPageContent.locationPickerVirtualMeetingLabel}</ToggleName>
                    <ToggleSwitchBackground>
                        <ToggleSwitch
                            activatedToggleBorderColor={
                                branding.calendarEntryModalPageContent.locationPickerVirtualMeetingToggleColor
                            }
                            activatedSliderBorderColor={
                                branding.calendarEntryModalPageContent.locationPickerVirtualMeetingToggleColor
                            }
                            isEnabled={isVirtualMeeting}
                            onClick={() => setIsVirtualMeeting!(!isVirtualMeeting)}
                            scaleSize={0.5}
                        />
                    </ToggleSwitchBackground>
                </ToggleSwitcherWrapper>
            )
        case CalendarEntryModalViewMode.VIEW:
            return (
                <ToggleSwitcherWrapper>
                    {isVirtualMeeting && (
                        <>
                            <ToggleName
                                paddingRight="5px"
                                color={branding.calendarEntryModalPageContent.locationPickerVirtualMeetingToggleColor}
                            >
                                {branding.calendarEntryModalPageContent.locationPickerVirtualMeetingLabel}
                            </ToggleName>
                            <div style={{ paddingBottom: "2px" }}>
                                <IconValidInput
                                    width="11px"
                                    height="11px"
                                    fill={branding.calendarEntryModalPageContent.locationPickerVirtualMeetingToggleColor}
                                />
                            </div>
                        </>
                    )}
                </ToggleSwitcherWrapper>
            )
    }
}

export function determineInitialLocationMode(calendarEntry?: CalendarEntry): LocationPickerMode {
    if (!calendarEntry) return LocationPickerMode.NO_LOCATION_PICKED
    if (
        (calendarEntry.locationActionParam === undefined ||
            calendarEntry.locationActionParam === null ||
            calendarEntry.locationActionParam === "") &&
        (calendarEntry.locationActionType === undefined ||
            calendarEntry.locationActionType === null ||
            calendarEntry.locationActionType === "") &&
        (calendarEntry.locationName === undefined || calendarEntry.locationName === null || calendarEntry.locationName === "")
    )
        return LocationPickerMode.NO_LOCATION_PICKED
    else if (
        (calendarEntry.locationActionParam === undefined ||
            calendarEntry.locationActionParam === null ||
            calendarEntry.locationActionParam === "") &&
        (calendarEntry.locationActionType === undefined ||
            calendarEntry.locationActionType === null ||
            calendarEntry.locationActionType === "") &&
        calendarEntry.locationName !== undefined &&
        calendarEntry.locationName !== null &&
        calendarEntry.locationName !== ""
    )
        return LocationPickerMode.CUSTOM
    else if (
        calendarEntry.locationActionParam !== undefined &&
        calendarEntry.locationActionParam !== null &&
        calendarEntry.locationActionParam !== "" &&
        calendarEntry.locationActionType === LocationActionType.STANDLINK &&
        calendarEntry.locationName !== undefined &&
        calendarEntry.locationName !== null &&
        calendarEntry.locationName !== ""
    )
        return LocationPickerMode.STAND
    else return LocationPickerMode.NO_LOCATION_PICKED
}

export function showUsername(
    viewMode: CalendarEntryModalViewMode | undefined,
    lastName: string | undefined,
    firstName: string,
    name: string | undefined
) {
    if (viewMode === CalendarEntryModalViewMode.CREATE) {
        if (lastName !== undefined) {
            return firstName + " " + lastName
        } else if (firstName === undefined && lastName === undefined) {
            return name
        } else return firstName
    } else return firstName
}

export function showParticipantStatus(
    viewMode: CalendarEntryModalViewMode | undefined,
    statusForViewColor: string,
    statusForEditAndCreate: string,
    freeOrBusyStatus: string,
    calendarEntry: CalendarEntry | undefined,
    contact: InvitePerson,
    participantStatus: string | undefined
) {
    if (viewMode === CalendarEntryModalViewMode.CREATE) {
        return <ParticipantStatus color={statusForEditAndCreate}>{freeOrBusyStatus}</ParticipantStatus>
    } else if (viewMode === CalendarEntryModalViewMode.EDIT && contact?.id === calendarEntry?.userId) {
        return (
            <ParticipantStatus color={branding.calendarEntryModalPageContent.colorForOrganizerTitle}>
                {branding.calendarEntryModalPageContent.organizerTitle}
            </ParticipantStatus>
        )
    } else if (viewMode === CalendarEntryModalViewMode.EDIT && contact?.id !== calendarEntry?.userId) {
        return <ParticipantStatus color={statusForEditAndCreate}>{freeOrBusyStatus}</ParticipantStatus>
    } else if (viewMode === CalendarEntryModalViewMode.VIEW && contact?.id === calendarEntry?.userId) {
        return (
            <ParticipantStatus color={branding.calendarEntryModalPageContent.colorForOrganizerTitle}>
                {branding.calendarEntryModalPageContent.organizerTitle}
            </ParticipantStatus>
        )
    } else if (viewMode === CalendarEntryModalViewMode.VIEW && contact?.id !== calendarEntry?.userId) {
        return <ParticipantStatus color={statusForViewColor}>{participantStatus}</ParticipantStatus>
    }
}
