import React, { Dispatch, SetStateAction } from "react"
import { CalendarEntry } from "../../../backendServices/GraphQLServices"
import useComponentVisible from "../../../navigationArea/useComponentVisible"
import { findTimezoneName, getTimezones } from "../../../utils/DateUtils"
import { IconArrowDownTimezoneDropdown, IconArrowUpTimezoneDropdown } from "../../Icons"
import {
    Input,
    TimezoneDropdownList,
    TimezoneInput,
    TimezoneName,
    TimezoneWrapper,
    ToggleNameTimeZone,
    ToggleSwitchBackground,
    ToggleTimeZoneSwitcherWrapper
} from "./CalendarEntryModal.styled"
import { CalendarEntryModalViewMode } from "./ModalFunctions"
import { useAppState } from "../../../globalStates/AppState"
import ToggleSwitch from "../../../ui/ToggleSwitch/ToggleSwitch"
import { trackSelectContent } from "../../../utils/GTMTracking"
import branding from "../../../branding/branding"
import moment from "moment"

interface TimezonePickerProps {
    selectedTimezone?: string
    setSelectedTimezone?: Dispatch<SetStateAction<string>>
    calendarEntry?: CalendarEntry
    viewMode?: CalendarEntryModalViewMode
}

export const TimezonePicker = (props: TimezonePickerProps) => {
    const appState = useAppState()
    const timezones = getTimezones()
    const timezoneToggle = appState.detectTimezoneToggle
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    return (
        <TimezoneWrapper ref={ref}>
            {props.viewMode !== CalendarEntryModalViewMode.VIEW ? (
                <ToggleTimeZoneSwitcherWrapper>
                    <ToggleNameTimeZone>{branding.communicationArea.automatic}</ToggleNameTimeZone>
                    <ToggleSwitchBackground>
                        <ToggleSwitch
                            scaleSize={0.5}
                            isEnabled={timezoneToggle}
                            onClick={() => {
                                appState.setDetectTimezoneToggle(!timezoneToggle)
                                props.setSelectedTimezone!(moment.tz.guess())
                                appState.setTimeZone(moment.tz.guess())

                                trackSelectContent(
                                    `${!timezoneToggle ? "Turn on" : "Turn off"} Detect Time Zone`,
                                    "Switch",
                                    "DontDisturb"
                                )
                            }}
                        />
                    </ToggleSwitchBackground>
                </ToggleTimeZoneSwitcherWrapper>
            ) : null}

            {timezoneToggle ? (
                <>
                    <TimezoneInput>
                        <Input
                            style={{ color: "rgba(0,0,0, 0.25)", border: "1px solid rgba(0,0,0, 0.25)" }}
                            placeholder={props.selectedTimezone}
                            value={findTimezoneName(props.selectedTimezone!)}
                            disabled={true}
                        />
                        <IconArrowDownTimezoneDropdown fill="rgba(0,0,0, 0.25)" width="10px" height="10px" />
                    </TimezoneInput>
                </>
            ) : (
                <TimezoneInput onKeyPress={(e) => e.preventDefault()} onClick={() => setIsComponentVisible(!isComponentVisible)}>
                    <Input placeholder={props.selectedTimezone} value={findTimezoneName(props.selectedTimezone!)} />
                    {!timezoneToggle && props.viewMode !== CalendarEntryModalViewMode.VIEW ? (
                        isComponentVisible ? (
                            <IconArrowUpTimezoneDropdown width="10px" height="10px" />
                        ) : (
                            <IconArrowDownTimezoneDropdown width="10px" height="10px" />
                        )
                    ) : null}
                </TimezoneInput>
            )}

            {!timezoneToggle && isComponentVisible && props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                <TimezoneDropdownList>
                    {timezones?.map((timezone, index) => (
                        <TimezoneName
                            key={index}
                            onClick={() => {
                                props.setSelectedTimezone!(timezone.timezones[0])
                                appState.setTimeZone(timezone.timezones[0])
                                setIsComponentVisible(false)
                            }}
                        >
                            <p>{timezone.name}</p>
                        </TimezoneName>
                    ))}
                </TimezoneDropdownList>
            )}
        </TimezoneWrapper>
    )
}

export default TimezonePicker
