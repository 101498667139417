import { format } from "date-fns"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { Col, Row, Tooltip } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom"
import { trackEventDateDetailsPageVisit, trackOnDemandDetailsPageVisit } from "../../../backendServices/TrackingServices"
import {
    AccessProvider,
    Attachment,
    Category,
    Company,
    EventDate,
    EventDateConnectedOrganization,
    Person,
    ShareTargetType
} from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import Chat from "../../../chatRoomArea/Chat"
import { saveEvent } from "../../../communicationArea/ICal"
import { useAppState } from "../../../globalStates/AppState"
import { getNow } from "../../../globalStates/EventState"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { useUserRestrictedAreaAccess } from "../../../globalStates/UserRestrictedAreaAccess"
import Breadcrumb, { BreadcrumbItem } from "../../../navigationArea/Breadcrumb"
import TopBar from "../../../navigationArea/TopBar"
import { useNavigationSource } from "../../../tracking/NavigationSource"
import { calcBreadcrumbLocations } from "../../../tracking/RouteTracker"
import { AvatarWithDefault } from "../../../ui/AvatarWithDefault"
import { AvatarWithPresenceState } from "../../../ui/AvatarWithPresenceState"
import BookmarkWithToggle from "../../../ui/BookmarkWithToggle"
import CenteredLoader from "../../../ui/CenteredLoader"
import CompanyDetails from "../../../ui/CompanyDetails"
import EventLayout from "../../../ui/EventLayout"
import {
    IconDownload,
    IconDownloadEventDateDetail,
    IconFile,
    IconLiveStream,
    IconRoundTable,
    IconShare,
    IconThreeDotsMenu,
    IconTicket,
    IconLock,
    IconMasterclassTag,
    IconPrerecordedTag,
    IconRemoteProviderTag,
    IconRoundtableTag,
    IconTypeformTag,
    IconVideoOnDemandTag,
    IconLiveStageTag,
    IconUnLockedEvent
} from "../../../ui/Icons"
import { usePrivacyPolicyModal } from "../../../ui/modals/PrivacyPolicyModal"
import { useRecommendModal } from "../../../ui/modals/RecommendModal"
import { ContentScrollContainer } from "../../../ui/ScrollContainer"
import { Staff, StaffEntry, StaffType } from "../../../ui/StaffEntry"
import useWindowDimensions from "../../../ui/WindowDimensionsHook"
import { isEventDateLive } from "../../../utils/DateUtils"
import { MobileVersionContainer, DesktopVersionContainer } from "../../../utils/Device"
import { isLivePhase, isSoftOpeningPhase } from "../../../utils/EventPhaseChecker"
import { trackSelectContent } from "../../../utils/GTMTracking"
import TopBannerSharedState from "../../advertisingBanner/TopBannerSharedState"
import { SimpleOrganization } from "../../ExhibitorsPageContent"
import GuestUserBanner from "../../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../../guestUserBanner/GuestUserBannerSharedState"
import { SectionTopButton } from "../../myprofile/MobileMyProfilePageContent"
import { ComingUp, JoinBreakout } from "../../VideoPageContent"
import ActionButton, { ActionButtonTheme } from "../../videoPlayer/ActionButton"
import VideoPlayer from "../../videoPlayer/VideoPlayer"
import Categories from "../components/Categories"
import {
    filterCategories,
    flattenCategories,
    Links,
    TeamSessionsTemplate,
    TeamSessionTemplateRow
} from "../components/DetailPageSections"
import { filterMedia, Media } from "../components/Media"
import NonGalleryMedia from "../components/NonGalleryMedia"
import { HeaderTitle } from "../components/StyledComponents"
import { buildDetailLink, DetailNavLink } from "../DetailNavLink"
import { EventDateDetailSection } from "../DetailPageBranding"
import {
    BasicInfoDescription,
    BasicInfoDescriptionText,
    BasicInfoDescriptionTitle,
    EventLocation,
    EventLocationContainer,
    EventLocationRoot,
    SectionRoot,
    SpacingCol
} from "../eventDetail/EventDetailPageContent.styled"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import BadgeArea from "../../../ui/BadgeArea"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { loadExhibitorData } from "../../../backendServices/ExhibitorServices"
import { loadEventDateDetails } from "../../../backendServices/EventdateServices"
import { trackVODWatchTime } from "../../../backendServices/GraphQLServices"
import { createPrivacyUserQuestionId } from "../../../backendServices/PrivacyPolicyServices"
import { hallplanOverviewRoute } from "../../../navigationArea/RoutePaths"
import { EventMenuModal, getEventPersonsFunctionGroups } from "../eventDetail/EventDetailPageContent"
import { Dictionary } from "lodash"
import {
    ActionButtonCol,
    AttachmentExstensionDiv,
    AttachmentRow,
    AttachmentTitle,
    ChatAreaContainer,
    ComingUpContentRoot,
    CompanyDetailsHeader,
    CompanyDetailsRoot,
    DownloadIconDiv,
    EventDateDetailBodyRoot,
    EventDateFormattedDate,
    EventDateLocationContainer,
    EventDateLocationRoot,
    EventDateName,
    EventDateNameTeaserCol,
    EventDateTeaser,
    EventDateTeaserArea,
    EventDateTeaserTitle,
    ExstensionText,
    FormattedDateCol,
    HallStandSectionHeader,
    HalleStandSection,
    HalleStandSingle,
    JoinBreakoutCol,
    LinksSectionRow,
    LiveEventRoot,
    LiveTileHeader,
    LiveTileHeaderTitle,
    LiveTileRoot,
    LocationCol,
    MediaSectionCol,
    PersonAttachmentColIcons,
    PersonAttachmentImageCol,
    PersonAttachmentRow,
    PersonAttachmentTitle,
    PersonColInfo,
    PersonInfoDiv,
    PersonPosition,
    PersonsAttachmentsContentCol,
    PersonsAttachmentsContentRow,
    PersonsAttachmentsHeaderPositionRow,
    PersonsAttachmentsHeaderTitle,
    PrivateEventMarkerRoot,
    PrivateEventUnlockedDiv,
    PrivateEventUnlockedLabel,
    RoundTableAccessDiv,
    ShareBookmarkDiv,
    ShareIcon,
    TagIcon,
    TagText,
    TagWrapper,
    TagsWrapper,
    TypeformSectionButton,
    TypeformSectionDescriptionText,
    TypeformSectionDivider,
    VideoOnDemandRoot
} from "./EventDateDetailPageContent.styled"
import { EventDateTiming } from "./EventDateDetailPageContentEnums"
import { PointsBadge } from "../../program/eventDateEntry/EventDateEntry.styled"
import { DetailPageEntityRows, EntityRowData } from "../components/DetailPageEntityRows"

/* #region  Share and bookmark icons component */

interface ShareBookmarkIconsProps {
    eventdate: EventDate
}

const ShareBookmarkIcons: React.FunctionComponent<ShareBookmarkIconsProps> = React.memo((props) => {
    const { RecommendModal, showRecommendModal } = useRecommendModal()
    const targetId = props.eventdate!.id
    const link = buildDetailLink(targetId, props.eventdate.name, "eventdate")
    const favorites = useFavoriteState()
    const isBookmarked = favorites.is("eventdate", props.eventdate.id)

    return (
        <>
            <RecommendModal />
            {props.eventdate && (
                <CustomOverlayTrigger
                    trigger={["focus", "click", "hover"]}
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                            {branding.backofficeReporting.download}
                        </Tooltip>
                    }
                >
                    <div>
                        <ShareIcon
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                saveEvent(props.eventdate!)

                                trackSelectContent(
                                    branding.backofficeContent.guestsSectionTitle,
                                    "Download",
                                    "Eventdate",
                                    undefined,
                                    undefined,
                                    "ics",
                                    `${props.eventdate?.name!}.ics`
                                )
                            }}
                        >
                            {IconDownloadEventDateDetail({ fill: branding.sideIconBar.sideIconColorDark })}
                        </ShareIcon>
                    </div>
                </CustomOverlayTrigger>
            )}
            <CustomOverlayTrigger
                trigger={["focus", "click", "hover"]}
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {branding.communicationArea.chatToolRecommend}
                    </Tooltip>
                }
            >
                <div>
                    <ShareIcon
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            showRecommendModal(ShareTargetType.EVENTDATE, targetId, props.eventdate.name, link)
                        }}
                    >
                        {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                    </ShareIcon>
                </div>
            </CustomOverlayTrigger>
            <CustomOverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>
                        {isBookmarked
                            ? branding.organizationDetailPageContent.unBookmarkPerson
                            : branding.organizationDetailPageContent.bookmarkPerson}
                    </Tooltip>
                }
            >
                <div>
                    <BookmarkWithToggle
                        newBookmarkItem={true}
                        fontSize={"25px"}
                        color={branding.sideIconBar.sideIconColorDark}
                        favIconBasic={true}
                        type={"eventdate"}
                        id={targetId}
                        name={props.eventdate!.name}
                    />
                </div>
            </CustomOverlayTrigger>
        </>
    )
})
/* #endregion */

/* #region  Person and attachment data component */

interface PersonAttachmentDataProps {
    person?: Person
    attachment?: Attachment
    type: "person" | "attachment"
    modalToggle?(): void
    startDownload?: boolean
    setSelectedAttachment?(value: Attachment): void
    isLastEntry: boolean
    newsPage?: boolean
}

const PersonAttachmentData: React.FunctionComponent<PersonAttachmentDataProps> = (props) => {
    const windowSize = useWindowDimensions()

    const [staff, setStaff] = useState<Staff>()

    const handleClick = () => {
        if (props.setSelectedAttachment) props.setSelectedAttachment(props.attachment!)

        if (!props.startDownload && props.modalToggle) props.modalToggle()
    }

    useEffect(() => {
        setStaff({
            firstName: props.person?.firstName!,
            lastName: props.person?.lastName!,
            id: props.person?.id!,
            type: StaffType.PERSON,
            logoUrl: props.person?.userLogoUrl || props.person?.logoUrl!,
            position: props.person?.position!,
            company: props.person?.organization!,
            sotUserId: props.person?.userId!,
            visible: true,
            showroomStandby: false,
            title: "",
            categories: []
        })
    }, [props.person])

    if (props.type === "person" && props.person) {
        return (
            <>
                <DesktopVersionContainer>
                    <PersonAttachmentRow className={props.newsPage ? "newsPage" : ""}>
                        <PersonAttachmentImageCol sm={4} className="pr-0">
                            {props.person.userId && (
                                <DetailNavLink
                                    id={props.person.id}
                                    type={"person"}
                                    name={`/person/${props.person.firstName}_${props.person.lastName}`}
                                    onClick={() => {
                                        trackSelectContent("Person details", "Details", "User")
                                    }}
                                >
                                    <AvatarWithPresenceState
                                        badgeSize={20}
                                        showAvatarBadge={true}
                                        badgeRight={windowSize.width < 1850 ? -16 : -45}
                                        badgeTop={windowSize.width < 1850 ? 50 : 70}
                                        userId={props.person.userId}
                                        avatarSize={windowSize.width < 1850 ? 71 : 101}
                                        content={{
                                            pictureUrl: props.person.userLogoUrl || props.person.logoUrl,
                                            alt: [props.person.firstName, props.person.lastName].filter(Boolean).join(" ") ?? "#"
                                        }}
                                    />
                                </DetailNavLink>
                            )}
                            {!props.person.userId && (
                                <AvatarWithDefault
                                    size={windowSize.width < 1850 ? 65 : windowSize.width < 1350 ? 45 : 95}
                                    src={props.person.userLogoUrl ?? props.person.logoUrl ?? undefined}
                                    alt={[props.person.firstName, props.person.lastName].filter(Boolean).join(" ") ?? "#"}
                                    backgroundSize="cover"
                                />
                            )}
                        </PersonAttachmentImageCol>
                        {/* <PersonColInfo xs={8}> */}
                        <PersonInfoDiv
                            size={windowSize.width}
                            style={{
                                marginLeft:
                                    windowSize.width > 2450
                                        ? "-20px"
                                        : windowSize.width > 2060
                                        ? "5px"
                                        : windowSize.width > 1850
                                        ? "22px"
                                        : windowSize.width > 1680
                                        ? "5px"
                                        : windowSize.width > 1500
                                        ? "15px"
                                        : windowSize.width > 1300
                                        ? "25px"
                                        : "35px"
                            }}
                        >
                            {props.person.userId && (
                                <DetailNavLink
                                    id={props.person.id}
                                    type={"person"}
                                    name={`/person/${props.person.firstName}_${props.person.lastName}`}
                                    onClick={() => {
                                        trackSelectContent("Person details", "Details", "User")
                                    }}
                                >
                                    <PersonAttachmentTitle>
                                        {[props.person.title, props.person.firstName, props.person.lastName]
                                            .filter(Boolean)
                                            .join(" ")}
                                    </PersonAttachmentTitle>
                                    <PersonPosition>
                                        {[props.person.position, props.person.organization]
                                            .filter(Boolean)
                                            .join(" " + branding.communicationArea.personCompanyLink + " ")}
                                    </PersonPosition>
                                </DetailNavLink>
                            )}
                            {!props.person.userId && (
                                <DetailNavLink
                                    id={props.person.id}
                                    type={"person"}
                                    name={`/person/${props.person.firstName}_${props.person.lastName}`}
                                    onClick={() => {
                                        trackSelectContent("Person details", "Details", "User")
                                    }}
                                >
                                    <PersonAttachmentTitle>
                                        {[props.person.title, props.person.firstName, props.person.lastName]
                                            .filter(Boolean)
                                            .join(" ")}
                                    </PersonAttachmentTitle>
                                    <PersonPosition>
                                        {[props.person.position, props.person.organization]
                                            .filter(Boolean)
                                            .join(" " + branding.communicationArea.personCompanyLink + " ")}
                                    </PersonPosition>
                                </DetailNavLink>
                            )}
                        </PersonInfoDiv>
                    </PersonAttachmentRow>
                </DesktopVersionContainer>
                <MobileVersionContainer>
                    {staff && (
                        <StaffEntry
                            staff={staff}
                            organizationId={""}
                            backOffice={false}
                            speaker={true}
                            lastEntry={props.isLastEntry}
                        />
                    )}
                </MobileVersionContainer>
            </>
        )
    } else if (props.attachment) {
        let index = props.attachment.url.lastIndexOf(".")
        let exstension = props.attachment.url.substring(index + 1).toUpperCase()
        return (
            <AttachmentRow className="m-0">
                <PersonAttachmentImageCol
                    style={{ paddingRight: "0", flex: "0 0 20px", marginRight: "25px" }}
                    xs={1}
                    className="p-0"
                >
                    {IconFile({ fill: branding.sideIconBar.sideIconColorDark, width: "20px", height: "20px" })}
                </PersonAttachmentImageCol>
                <PersonColInfo xs={9} className="p-0">
                    <AttachmentTitle>{props.attachment.title ? props.attachment.title : "NO TITLE"}</AttachmentTitle>
                    <AttachmentExstensionDiv>
                        <ExstensionText>{exstension}</ExstensionText>
                    </AttachmentExstensionDiv>
                </PersonColInfo>
                <PersonAttachmentColIcons style={{ marginLeft: "calc(8.333333% - 55px)" }} className="d-flex justify-content-end">
                    <DownloadIconDiv onClick={handleClick}>
                        {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                    </DownloadIconDiv>
                </PersonAttachmentColIcons>
                <Col xs={2}></Col>
            </AttachmentRow>
        )
    } else {
        return <></>
    }
}
/* #endregion */

/* #region  All persons content */

//renamed to PersonsLegacy because this section is now only used in News detail page, and it will be fully removed with the redesign of that page
interface PersonsLegacyProps {
    persons: Array<Person>
    descriptionExists?: any
    newsPage?: boolean
}

export const PersonsLegacy: React.FunctionComponent<PersonsLegacyProps> = (props) => {
    let lastPosition: string | undefined = ""
    var personsWithFunctionNameSorted = props.persons.sort((a, b) => {
        if (a.personFunctionTargetName === undefined && b.personFunctionTargetName === undefined) {
            return 0
        } else if (a.personFunctionTargetName === undefined) {
            return 1
        } else if (b.personFunctionTargetName === undefined) {
            return -1
        } else {
            return a.personFunctionTargetName > b.personFunctionTargetName
                ? -1
                : a.personFunctionTargetName < b.personFunctionTargetName
                ? 1
                : 0
        }
    })

    const getPositionHeader = (positionName: string | undefined) => {
        let count = personsWithFunctionNameSorted.filter((person) => person.personFunctionTargetName === positionName).length
        if (positionName === undefined) {
            return branding.eventDateDetailPageContent.noSectionTitle + " (" + count + ")"
        } else {
            return positionName + " (" + count + ")"
        }
    }

    return (
        <PersonsAttachmentsContentRow className={props.newsPage ? "newsPage" : ""} descriptionExists={props.descriptionExists}>
            {props.newsPage ? (
                <>
                    <PersonsAttachmentsHeaderPositionRow type="person" className="newsPage">
                        <PersonsAttachmentsHeaderTitle>
                            {branding.newsDetailPageContent.authorsSectionTitle}
                        </PersonsAttachmentsHeaderTitle>
                    </PersonsAttachmentsHeaderPositionRow>
                    {props.persons.map((person, index) => {
                        return (
                            <PersonsAttachmentsContentCol xs={12} key={person.id}>
                                <PersonAttachmentData
                                    isLastEntry={personsWithFunctionNameSorted.length - index === 1}
                                    person={person}
                                    type="person"
                                    newsPage={props.newsPage}
                                />
                            </PersonsAttachmentsContentCol>
                        )
                    })}
                </>
            ) : (
                personsWithFunctionNameSorted.map((person, index) => {
                    if (person.personFunctionTargetName !== lastPosition) {
                        lastPosition = person.personFunctionTargetName
                        let positionHeader = getPositionHeader(person.personFunctionTargetName)
                        return (
                            <PersonsAttachmentsContentCol xs={12} key={person.id}>
                                <PersonsAttachmentsHeaderPositionRow type="person">
                                    <PersonsAttachmentsHeaderTitle>{positionHeader}</PersonsAttachmentsHeaderTitle>
                                </PersonsAttachmentsHeaderPositionRow>
                                <PersonAttachmentData
                                    isLastEntry={personsWithFunctionNameSorted.length - index === 1}
                                    person={person}
                                    type="person"
                                />
                            </PersonsAttachmentsContentCol>
                        )
                    } else {
                        return (
                            <PersonsAttachmentsContentCol xs={12} key={person.id}>
                                <PersonAttachmentData
                                    isLastEntry={personsWithFunctionNameSorted.length - index === 1}
                                    person={person}
                                    type="person"
                                />
                            </PersonsAttachmentsContentCol>
                        )
                    }
                })
            )}
        </PersonsAttachmentsContentRow>
    )
}
/* #endregion */

/* #region LiveEvent */

interface PrivateEventMarkerProps {
    accessProvider: AccessProvider | undefined
}
const PrivateEventMarker: React.FC<PrivateEventMarkerProps> = (props) => {
    const iconSize = "15"
    const displayTicketIcon = props.accessProvider === AccessProvider.TICKET

    return (
        <PrivateEventMarkerRoot>
            {displayTicketIcon ? (
                <IconTicket width={iconSize} height={iconSize} />
            ) : (
                <IconLock width={iconSize} height={iconSize} />
            )}
            <div className="private-event-marker-text">
                <p>{branding.programSchedule.privateEventMarkerText}</p>
            </div>
        </PrivateEventMarkerRoot>
    )
}

interface LiveEventDateProps {
    streamingUrl: string
    isLive: boolean
    organizationId: string | undefined
    pageTitle: string
    eventDate: EventDate
    isChannelPage?: boolean
    useMobileDesign: boolean
}
const LiveEventSection: React.FunctionComponent<LiveEventDateProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()

    const userLink = useLoggedInState()
    const questionId = createPrivacyUserQuestionId(props.organizationId ?? "")
    const alreadyAccepted = userLink.getPrivacyUserAnswer(questionId)?.accepted
    const [disablePlaying, setDisablePlaying] = useState(
        !alreadyAccepted && branding.configuration.showPrivacyModelForEventDates === true
    ) //play the video if user already accepted the policy
    const [modalShown, setModalShown] = useState(false)
    const [vodClicked, setVodClicked] = useState(false)
    let endTime = moment(props.eventDate.enddatetime)
    const now = getNow()
    const appState = useAppState()

    const title = props.eventDate?.channel?.name ?? ""
    let content: JSX.Element | null = null

    let contentColWidth = props.useMobileDesign ? 12 : 8

    // 1. if user is on channel page, we are displaying VideoPlayer - real or dummy
    // 2. if event is not live and videoOnDemandUrl is presented, then we can display VideoPlayer(real or dummy) with videoOnDemand
    // 3. if neither condition are not fulfilled, we can display eventdate title and image preview

    const totalWatchTime = useRef<number>(0)
    const [intervalId, setIntervalId] = useState<any>(null)

    useEffect(() => {
        // this tacks the Watch time, even if the user closes the window or tab of the browser
        const handleTabClose = (event: any) => {
            event.preventDefault()
            let watchTimeString: string | null = localStorage.getItem("VODWatchTime")
            trackVODWatchTime(userLink.user()?.profileId!, props.organizationId || "", props.eventDate.id!, watchTimeString)
            // remove watch time from local stoarge when leaving the page

            if (props.eventDate.videoOnDemandUrl) {
                localStorage.removeItem("VODWatchTime")
            }
        }
        window.addEventListener("beforeunload", handleTabClose)
        return () => {
            window.removeEventListener("beforeunload", handleTabClose)
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        // track visit on page load
        setTimeout(() => {
            trackOnDemandDetailsPageVisit(userLink.user()?.profileId!, props.organizationId || "", props.eventDate.id!)
        }, 500)
        return () => {
            if (props.eventDate.videoOnDemandUrl && userAccessState.isUnlocked(props.eventDate)) {
                setTimeout(() => {
                    // turn on VOD-PiP-Player
                    appState.setVodEventDate(props.eventDate)
                }, 500)

                // track the VOD watch time when the user leave the page
                let watchTimeString: string | null = moment
                    .utc(totalWatchTime.current * 1000) //eslint-disable-line
                    .format("HH:mm:ss")
                    .toString()

                if (watchTimeString === "00:00:00") watchTimeString = null
                trackVODWatchTime(userLink.user()?.profileId!, props.organizationId || "", props.eventDate.id!, watchTimeString)
                // remove watch time from local stoarge when leaving the page
                localStorage.removeItem("VODWatchTime")
            }
        }

        //eslint-disable-next-line
    }, [])

    const startTimeTracking = () => {
        setIntervalId(
            setInterval(() => {
                totalWatchTime.current++
            }, 1000)
        )
    }

    const stopTimeTracking = () => {
        clearInterval(intervalId)
    }

    if (props.isChannelPage) {
        // 1. condition LIVE STREAM
        contentColWidth = 12
        content = <VideoPlayer streamUrl={props.streamingUrl} eventDate={props.eventDate} live={true} />
    } else {
        const liveTileHeader = (
            <LiveTileHeader>
                <LiveTileHeaderTitle>
                    <div>{IconLiveStream({ fill: "currentColor" })}</div>
                    <h2>{title}</h2>
                </LiveTileHeaderTitle>
                <div>
                    <ActionButton restrictedArea={props.eventDate} theme={ActionButtonTheme.PRIVATE_EVENT_LIGHT_PLAYER} />
                    {userAccessState.isUnlocked(props.eventDate) &&
                        props.eventDate.accessProvider !== AccessProvider.PUBLIC &&
                        props.eventDate.type !== "roundtable" &&
                        props.eventDate.videoOnDemandUrl === null && (
                            <PrivateEventUnlockedDiv>
                                {now.isBefore(endTime) && (
                                    <>
                                        <div style={{ marginRight: "8px" }}>
                                            {IconUnLockedEvent({ fill: "#fff", width: "15", height: "13" })}
                                        </div>
                                        <PrivateEventUnlockedLabel>
                                            {branding.eventDateDetailPageContent.eventDateUnlockedLabelText}
                                        </PrivateEventUnlockedLabel>
                                    </>
                                )}
                                {now.isAfter(endTime) && (
                                    <>
                                        <PrivateEventUnlockedLabel>
                                            {branding.eventDateDetailPageContent.eventDateUnlockedFinished}
                                        </PrivateEventUnlockedLabel>
                                    </>
                                )}
                            </PrivateEventUnlockedDiv>
                        )}
                </div>
            </LiveTileHeader>
        )

        if (!props.isLive && props.eventDate.videoOnDemandUrl) {
            // 2. condition VOD
            content = (
                <VideoOnDemandRoot
                    onClick={(e) => {
                        if (props.organizationId && !modalShown && branding.configuration.showPrivacyModelForEventDates)
                            showPrivacyPolicyModal({ id: props.organizationId, ...props.eventDate.organizationConsent }, () => {
                                setDisablePlaying(false)
                                setModalShown(true)
                            })
                        else {
                            setDisablePlaying(false)
                        }

                        // this should be triggered only once
                        if (props.organizationId && !disablePlaying && !vodClicked) {
                            setVodClicked(true)
                        }
                    }}
                >
                    {userAccessState.isUnlocked(props.eventDate) && liveTileHeader}
                    <VideoPlayer
                        onPlay={startTimeTracking}
                        onPause={stopTimeTracking}
                        streamUrl={props.eventDate.videoOnDemandUrl}
                        eventDate={props.eventDate}
                        live={false}
                        disablePlaying={disablePlaying}
                        sourceIsStatic={true}
                        isVod={true}
                        title={props.eventDate.name}
                    />
                </VideoOnDemandRoot>
            )
        } else {
            // 3. condition EVENT DATE PAGE
            content = (
                <LiveTileRoot className="live-preview-img">
                    {liveTileHeader}
                    <img
                        src={
                            props.eventDate?.backgroundImageEventGuideFileID ??
                            props.eventDate?.liveStreamingPreviewUrl ??
                            props.eventDate?.logoUrl ??
                            "/branding/applicationMedia/no-event-default-image.png"
                        }
                        alt=""
                    />

                    {props.eventDate.accessProvider !== AccessProvider.PUBLIC && (
                        <PrivateEventMarker accessProvider={props.eventDate.accessProvider} />
                    )}
                </LiveTileRoot>
            )
        }
    }

    return (
        <>
            <LiveEventRoot>
                <Col xs={contentColWidth} className="p-0">
                    {content}
                </Col>
            </LiveEventRoot>
            <PrivacyModal />
        </>
    )
}
/* #endregion LiveEvent */

/* #region BasicInfo */

//returns date-time in format: Wed., 12 Sept., 2020, 17:00 - 18:30
export const getFormattedDate = (eventDate: EventDate, lang: string) => {
    return (
        format(moment(eventDate.date).toDate(), branding.eventTiming.eventDaysFormatPatternEvtDetailPage, {
            locale: lang === "de" ? de : en
        }) +
        ", " +
        eventDate.start +
        " - " +
        eventDate.end +
        (lang === "de" ? " Uhr" : "")
    )
}

interface TileTagProps {
    icon: any
    tagText: string
}

const TileTag = (props: TileTagProps) => {
    return (
        <TagWrapper>
            <TagIcon>{props.icon}</TagIcon>
            <TagText>{props.tagText}</TagText>
        </TagWrapper>
    )
}

interface TileTagsWrapperProps {
    videoOnDemandUrl: string
    typeformUrl: string
    eventDateType: string
}

const TileTagsWrapper = (props: TileTagsWrapperProps) => {
    return (
        <TagsWrapper>
            {props.eventDateType === "onsite" && props.videoOnDemandUrl === null && (
                <TileTag icon={IconLiveStageTag({})} tagText={branding.programSchedule.liveStageTagText} />
            )}

            {props.eventDateType === "prerecorded" && props.videoOnDemandUrl === null && (
                <TileTag icon={IconPrerecordedTag({})} tagText={branding.programSchedule.prerecordedTagText} />
            )}

            {props.eventDateType === "masterclass" && props.videoOnDemandUrl === null && (
                <TileTag icon={IconMasterclassTag({})} tagText={branding.programSchedule.masterclassTagText} />
            )}

            {props.eventDateType === "remoteprovider" && props.videoOnDemandUrl === null && (
                <TileTag icon={IconRemoteProviderTag({})} tagText={branding.programSchedule.remoteProviderTagText} />
            )}

            {props.eventDateType === "roundtable" && props.videoOnDemandUrl === null && (
                <TileTag icon={IconRoundtableTag({})} tagText={branding.programSchedule.roundtableTagText} />
            )}

            {props.videoOnDemandUrl && (
                <TileTag icon={IconVideoOnDemandTag({})} tagText={branding.programSchedule.videoOnDemandTagText} />
            )}

            {((branding.programSchedule.videoOnDemandNeededToDisplayTypeformTag && props.videoOnDemandUrl) ||
                !branding.programSchedule.videoOnDemandNeededToDisplayTypeformTag) &&
                props.typeformUrl && <TileTag icon={IconTypeformTag({})} tagText={branding.programSchedule.typeformTagText} />}
        </TagsWrapper>
    )
}

interface BasicInfoSectionProps {
    eventDate: EventDate
    pointsBadgeData: any
    isChannelPage?: boolean
    userAccessState: any
    joinBreakoutDisabled?: boolean
    breakoutRoomParticipants?: number
    showRecommendModal: (shareType: ShareTargetType, entityId: string, entityName: string, link: string) => void
    useMobileDesign: boolean
}

const BasicInfoSection: React.FunctionComponent<BasicInfoSectionProps> = (props: BasicInfoSectionProps) => {
    const lang = useLanguageState().getLanguage()
    const [showEventMenuModal, setShowEventMenuModal] = useState<boolean>(false)

    return (
        <SectionRoot marginTop="10px">
            <MobileVersionContainer>
                <div style={{ marginLeft: "12px", paddingTop: "10px" }}>
                    <div style={{ color: branding.primaryColor, height: "20px", display: "inline-block" }}>
                        {IconLiveStream({ fill: branding.sideIconBar.sideIconColorDark })}
                    </div>
                    <EventLocationRoot style={{ display: "inline-block" }}>
                        <EventLocationContainer>{props.eventDate.location}</EventLocationContainer>
                    </EventLocationRoot>
                    <SectionTopButton style={{ right: "1.5rem", marginTop: "-30px" }} onClick={() => setShowEventMenuModal(true)}>
                        {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
                    </SectionTopButton>
                </div>
            </MobileVersionContainer>

            <Row className="m-0">
                <SpacingCol xs={2} className="p-0" />

                <LocationCol xs={props.useMobileDesign ? 12 : 4}>
                    <DesktopVersionContainer>
                        <div style={{ display: "inline-flex" }}>
                            <div style={{ color: branding.primaryColor, height: "20px" }}>
                                {IconLiveStream({ fill: branding.sideIconBar.sideIconColorDark })}
                            </div>
                            <EventDateLocationRoot>
                                <EventDateLocationContainer style={{ top: "5px" }}>
                                    {props.eventDate.location}
                                </EventDateLocationContainer>
                            </EventDateLocationRoot>
                        </div>
                    </DesktopVersionContainer>

                    <MobileVersionContainer>
                        <div style={{ marginLeft: "15px" }}>
                            <EventDateFormattedDate>{getFormattedDate(props.eventDate, lang)}</EventDateFormattedDate>
                            <EventLocation>{props.eventDate.name}</EventLocation>
                        </div>
                    </MobileVersionContainer>
                </LocationCol>

                <JoinBreakoutCol xs={4}>
                    <DesktopVersionContainer>
                        {
                            // join breakout room - visible only on channel page
                            props.isChannelPage && (
                                <div className="w-100">
                                    {/* join breakout room section */}
                                    {branding.configuration.breakoutEnabled &&
                                        props.eventDate.breakoutAccess &&
                                        props.userAccessState.isUnlocked(props.eventDate) && (
                                            <JoinBreakout
                                                eventDate={props.eventDate}
                                                disabled={props.joinBreakoutDisabled}
                                                participants={props.breakoutRoomParticipants!}
                                            />
                                        )}
                                </div>
                            )
                        }
                    </DesktopVersionContainer>
                </JoinBreakoutCol>
            </Row>

            <DesktopVersionContainer>
                <Row className="m-0">
                    <Col xs={2} />
                    <FormattedDateCol xs={8}>
                        <EventDateFormattedDate>{getFormattedDate(props.eventDate, lang)} </EventDateFormattedDate>
                    </FormattedDateCol>
                    <Col xs={2} />
                </Row>

                <Row className="m-0">
                    <Col xs={2} />
                    <FormattedDateCol xs={6}>
                        <EventDateNameTeaserCol xs={12}>
                            <EventDateName>{props.eventDate.name}</EventDateName>

                            {props.pointsBadgeData && (
                                <PointsBadge
                                    className={"mt-3"}
                                    textColor={props.pointsBadgeData.colorText}
                                    fillColor={props.pointsBadgeData.colorFill}
                                    borderColor={props.pointsBadgeData.colorBorder}
                                >
                                    {branding.programSchedule.pointsBadgeText}
                                </PointsBadge>
                            )}

                            {branding.programSchedule.showTileTags && (
                                <TileTagsWrapper
                                    videoOnDemandUrl={props.eventDate.videoOnDemandUrl}
                                    typeformUrl={props.eventDate.typeformUrl}
                                    eventDateType={props.eventDate.type || ""}
                                />
                            )}
                        </EventDateNameTeaserCol>
                    </FormattedDateCol>
                    <Col xs={2} className="p-0">
                        <ShareBookmarkDiv>
                            <ShareBookmarkIcons eventdate={props.eventDate} />
                        </ShareBookmarkDiv>
                    </Col>
                </Row>
            </DesktopVersionContainer>

            <MobileVersionContainer>
                {props.eventDate.text && props.eventDate.text?.length > 0 && (
                    <Row className="m-0">
                        <BasicInfoDescription>
                            <BasicInfoDescriptionTitle>
                                {branding.eventDateDetailPageContent.descriptionTextForEventDate}
                            </BasicInfoDescriptionTitle>
                            <BasicInfoDescriptionText>
                                {props.eventDate.text?.split("\n").map((item, i) => (
                                    <span key={i}>
                                        {item}
                                        <br />
                                    </span>
                                ))}
                            </BasicInfoDescriptionText>
                        </BasicInfoDescription>
                    </Row>
                )}
            </MobileVersionContainer>

            {showEventMenuModal && (
                <EventMenuModal
                    event={props.eventDate}
                    handleClose={() => setShowEventMenuModal(false)}
                    showRecommendModal={props.showRecommendModal}
                    type={ShareTargetType.EVENTDATE}
                    marginTop="320px"
                />
            )}
        </SectionRoot>
    )
}
/* #endregion BasicInfo */

/* #region Typeform */

const TypeformDescription: React.FunctionComponent<{ typeformButtonDisabled: boolean; time: any }> = (props) => {
    const replaceTextPlaceholder = (text: string) => {
        return text.replace("${time}", props.time)
    }

    return (
        <TypeformSectionDescriptionText>
            {props.typeformButtonDisabled && branding.eventDateDetailPageContent.typeformSectionCountdownVisible
                ? replaceTextPlaceholder(branding.eventDateDetailPageContent.typeformSectionWaitingDescriptionText)
                : branding.eventDateDetailPageContent.typeformSectionDescriptionText}
        </TypeformSectionDescriptionText>
    )
}

const TypeformButton: React.FunctionComponent<{ text: string; onClick: () => void; typeformButtonDisabled: boolean }> = (
    props
) => {
    return (
        <TypeformSectionButton disabled={props.typeformButtonDisabled} onClick={() => props.onClick()}>
            {props.text}
        </TypeformSectionButton>
    )
}

interface TypeformSectionProps {
    eventDate: EventDate
    typeformButtonDisabled: boolean
    time: any
}

const TypeformSection: React.FunctionComponent<TypeformSectionProps> = (props: TypeformSectionProps) => {
    const userState = useLoggedInState()

    const onTypeformSectionButtonClick = () => {
        if (props.eventDate.typeformUrl && userState.user() && userState.user()?.remoteId) {
            let adjustedUrl = props.eventDate.typeformUrl?.replace(
                "${remoteUserId}",
                userState.user()?.remoteId ?? userState.user()?.email!
            )
            window.open(`${adjustedUrl}`, "_blank")
        }
    }
    return (
        <>
            {branding.eventDateDetailPageContent.typeformSectionEnabled &&
                props.eventDate.typeformUrl &&
                props.eventDate.videoOnDemandUrl && (
                    <SectionRoot>
                        <DesktopVersionContainer>
                            <Row className="m-0">
                                <Col xs={2} />
                                <Col xs={8} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <TypeformSectionDivider />
                                </Col>
                                <Col xs={2} />
                            </Row>
                        </DesktopVersionContainer>

                        <DesktopVersionContainer>
                            <Row className="m-0" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                                <SpacingCol xs={2} />
                                <FormattedDateCol xs={6}>
                                    <EventDateNameTeaserCol xs={10}>
                                        <TypeformDescription
                                            typeformButtonDisabled={props.typeformButtonDisabled}
                                            time={props.time}
                                        />
                                    </EventDateNameTeaserCol>
                                </FormattedDateCol>
                                <Col xs={2} className="p-0">
                                    <ShareBookmarkDiv>
                                        <TypeformButton
                                            typeformButtonDisabled={props.typeformButtonDisabled}
                                            text={branding.eventDateDetailPageContent.typeformSectionButtonText}
                                            onClick={() => onTypeformSectionButtonClick()}
                                        />
                                    </ShareBookmarkDiv>
                                </Col>
                            </Row>
                        </DesktopVersionContainer>

                        <MobileVersionContainer>
                            <Row
                                className="m-0"
                                style={{ paddingTop: "5px", paddingBottom: "5px", justifyContent: "space-between" }}
                            >
                                <TypeformDescription typeformButtonDisabled={props.typeformButtonDisabled} time={props.time} />
                                <TypeformButton
                                    typeformButtonDisabled={props.typeformButtonDisabled}
                                    text={branding.eventDateDetailPageContent.typeformSectionButtonText}
                                    onClick={() => onTypeformSectionButtonClick()}
                                />
                            </Row>
                        </MobileVersionContainer>

                        <MobileVersionContainer></MobileVersionContainer>

                        <DesktopVersionContainer>
                            {!props.eventDate.organizationId && (
                                <Row className="m-0">
                                    <Col xs={2} />
                                    <Col xs={8} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                        <TypeformSectionDivider />
                                    </Col>
                                    <Col xs={2} />
                                </Row>
                            )}
                        </DesktopVersionContainer>
                    </SectionRoot>
                )}
        </>
    )
}

/* #endregion Typeform */

/* #region CompanyDetails */

interface CompanyDetailsProps {
    organizationId?: string
    company?: Company
    useMobileDesign: boolean
}

const CompanyDetailsSection: React.FunctionComponent<CompanyDetailsProps> = (props: CompanyDetailsProps) => {
    return (
        <>
            {props.organizationId && props.company && (
                <SectionRoot>
                    <Row className="m-0">
                        <SpacingCol xs={2} />
                        <Col xs={props.useMobileDesign ? 12 : 8} className="p-0">
                            {props.organizationId && props.company && (
                                <CompanyDetailsRoot>
                                    <CompanyDetailsHeader>
                                        {branding.eventDateDetailPageContent.companyDetailsHeader}
                                    </CompanyDetailsHeader>
                                    <CompanyDetails
                                        organization={props.company}
                                        logoClass="evtdDetail"
                                        customMarginLeft={true}
                                        customMarginLeftValue={"15px"}
                                        source="EVENTDATE"
                                    />
                                </CompanyDetailsRoot>
                            )}
                        </Col>
                        <SpacingCol xs={2} />
                    </Row>
                </SectionRoot>
            )}
        </>
    )
}

/* #endregion CompanyDetails */

/* #region Description */

interface DescriptionProps {
    eventDate: EventDate
}

const DescriptionSection: React.FunctionComponent<DescriptionProps> = (props: DescriptionProps) => {
    const descriptionExists = props.eventDate.text && props.eventDate.text?.length! > 0

    return (
        <>
            <DesktopVersionContainer>
                <Row className="m-0">
                    <Col xs={2} />
                    {descriptionExists && (
                        <Col xs={8} className="p-0">
                            <EventDateTeaserArea>
                                <EventDateTeaserTitle>
                                    {branding.eventDateDetailPageContent.descriptionTextForEventDate}
                                </EventDateTeaserTitle>
                                <EventDateTeaser>
                                    {props.eventDate.text?.split("\n").map((item, i) => (
                                        <span key={i}>
                                            {item}
                                            <br />
                                        </span>
                                    ))}
                                </EventDateTeaser>
                            </EventDateTeaserArea>
                            {/* } */}
                        </Col>
                    )}
                </Row>
                <Row className="m-0">
                    <Col xs={2} />
                    <Col xs={8} style={{ paddingLeft: "5px" }}>
                        <BadgeArea
                            categories={flattenCategories(
                                props.eventDate.categories || [],
                                branding.eventDateDetailPageContent.showCategoryHierarchy
                            )}
                        />
                    </Col>
                </Row>
            </DesktopVersionContainer>
        </>
    )
}

/* #endregion Description */

/* #region Persons */

interface PersonsProps {
    eventDate: EventDate
    useMobileDesign: boolean
}

const PersonsSection: React.FunctionComponent<PersonsProps> = (props: PersonsProps) => {
    const groups: Dictionary<Person[]> = getEventPersonsFunctionGroups(props.eventDate.persons)

    return (
        <SectionRoot>
            <Row style={{ marginTop: "30px" }}>
                <SpacingCol xs={2} />
                <Col xs={props.useMobileDesign ? 12 : 8}>
                    {props.useMobileDesign ? (
                        <>
                            {Object.getOwnPropertyNames(groups).map(function (key) {
                                const currentGroup: Person[] = groups[key]
                                return (
                                    <SectionRoot>
                                        <TeamSessionTemplateRow key={key}>
                                            <TeamSessionsTemplate
                                                pageType="eventdate"
                                                type="team"
                                                team={currentGroup}
                                                organizationName={key}
                                                newDesign={!props.useMobileDesign}
                                            />
                                        </TeamSessionTemplateRow>
                                    </SectionRoot>
                                )
                            })}
                        </>
                    ) : (
                        <TeamSessionsTemplate
                            pageType="eventdate"
                            type="team"
                            team={props.eventDate.persons}
                            organizationName={branding.jobofferDetailPageContent.personListTitle}
                            newDesign={!props.useMobileDesign}
                            group
                        />
                    )}
                </Col>
            </Row>
        </SectionRoot>
    )
}
/* #endregion Persons */

/* #region AdditionalOrgas */

interface AdditionalOrgasProps {
    eventDate: EventDate
    useMobileDesign: Boolean
}

const AdditionalOrgasSection = (props: AdditionalOrgasProps) => {
    if (!props.eventDate.organizations?.length) return null
    return (
        <SectionRoot>
            <Row>
                <Col xs={2} />
                <Col xs={props.useMobileDesign ? 12 : 8}>
                    <DetailPageEntityRows
                        type="organization"
                        newDesign={true}
                        headerTitle={branding.eventDateDetailPageContent.additionalOrgasSectionTitle}
                        rowsData={props.eventDate?.organizations?.map((organization: EventDateConnectedOrganization) => {
                            return {
                                name: organization?.name,
                                id: organization?.id,
                                initials: organization.name?.charAt(0),
                                pictureURL: organization?.logoUrl,
                                description: { teaser: organization.teaser }
                            } as unknown as EntityRowData
                        })}
                    />
                </Col>
                <Col xs={2} />
            </Row>
        </SectionRoot>
    )
}

/* #endregion AdditionalOrgas */
/* #region EventLayout */

interface EventLayoutProps {
    eventDate: EventDate
    useMobileDesign: boolean
}

const EventLayoutSection: React.FunctionComponent<EventLayoutProps> = (props: EventLayoutProps) => {
    return (
        <>
            {branding.eventDateDetailPageContent.eventLayoutVisibleOnEventDateDetailPage && (
                <SectionRoot>
                    <Row style={{ marginTop: "15px" }}>
                        <SpacingCol xs={2} />
                        <Col xs={props.useMobileDesign ? 12 : 8}>
                            <EventLayout event={props.eventDate.event} />
                        </Col>
                    </Row>
                </SectionRoot>
            )}
        </>
    )
}

/* #endregion EventLayout */

/* #region PrivateEventAccessView */

interface PrivateEventAccessViewProps {
    eventDate: EventDate
    isChannelPage?: boolean
}

const PrivateEventAccessView: React.FC<PrivateEventAccessViewProps> = (props) => {
    const lang = useLanguageState().getLanguage()
    const userEventAccess = useUserRestrictedAreaAccess()
    const isRoundTable = props.eventDate.type === "roundtable"
    const [eventDateTiming, setEventDateTiming] = useState<EventDateTiming>()
    let startTime = moment(props.eventDate.startdatetime)
    let endTime = moment(props.eventDate.enddatetime)

    if (isRoundTable) {
        startTime = startTime.subtract(branding.configuration.roundTableOpenBeforeMinutes ?? 5, "minutes")
        endTime = endTime.add(branding.configuration.roundTableOpenAfterMinutes ?? 5, "minutes")
    }

    // event date timing phases
    useEffect(() => {
        const now = getNow()
        if (now.isBefore(startTime)) {
            setEventDateTiming(EventDateTiming.BEFORE)
            const timeToLive = startTime.diff(now)
            if (timeToLive < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => {
                    setEventDateTiming(EventDateTiming.LIVE)
                }, timeToLive)
                return () => clearTimeout(handle)
            }
        } else if (now.isBefore(endTime)) {
            setEventDateTiming(EventDateTiming.LIVE)
            const timeToEnd = endTime.diff(now)
            if (timeToEnd < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => {
                    setEventDateTiming(EventDateTiming.AFTER)
                }, timeToEnd)
                return () => clearTimeout(handle)
            }
        } else {
            setEventDateTiming(EventDateTiming.AFTER)
        }
    }, [props.eventDate.id]) // eslint-disable-line

    const getDescriptionText = () => {
        let privateEventText = ""
        if (isRoundTable) {
            if (props.eventDate.accessProvider === AccessProvider.TICKET) {
                privateEventText = branding.eventDateDetailPageContent.privateEventDateTicketNoAccessText
            } else if (props.eventDate.accessProvider === AccessProvider.BACKOFFICE) {
                privateEventText = branding.eventDateDetailPageContent.eventDateRoundTableNoAccessText
            }
        } else {
            if (props.eventDate.accessProvider === AccessProvider.TICKET) {
                privateEventText = branding.eventDateDetailPageContent.privateEventDateTicketNoAccessText
            } else if (props.eventDate.accessProvider === AccessProvider.BACKOFFICE) {
                privateEventText = branding.eventDateDetailPageContent.privateEventDateNoAccessText
            }
        }

        if (eventDateTiming === EventDateTiming.AFTER) {
            privateEventText = branding.eventDateDetailPageContent.eventDateRoundTablePostEventText
        } else if (userEventAccess.isUnlocked(props.eventDate)) {
            const startDateTime = moment(props.eventDate.date + " " + props.eventDate.start)
            const tempText = isRoundTable
                ? branding.eventDateDetailPageContent.eventDateRoundTableGrantedAccessText
                : branding.eventDateDetailPageContent.privateEventDateGrantedAccessText
            privateEventText = tempText.split("{$startTime}").join(
                format(moment(startDateTime).toDate(), branding.eventTiming.eventDaysFormatPatternEvtDetailPage, {
                    locale: lang === "de" ? de : en
                }) +
                    (lang === "de" ? " um " : " at ") +
                    props.eventDate.start
            )
        }
        return privateEventText
    }

    if (
        props.eventDate.type !== "roundtable" &&
        (eventDateTiming === EventDateTiming.AFTER || userEventAccess.isUnlocked(props.eventDate))
    ) {
        return null
    }

    return (
        <>
            <RoundTableAccessDiv>
                <Col xs={1}>{IconRoundTable({ width: "30", height: "30", fill: branding.mainInfoColor })}</Col>
                <Col xs={9}>{getDescriptionText()}</Col>
                <ActionButtonCol xs={2}>
                    <ActionButton restrictedArea={props.eventDate} theme={ActionButtonTheme.PRIVATE_EVENT_DARK_BOX} />
                </ActionButtonCol>
            </RoundTableAccessDiv>
        </>
    )
}

const PrivateEventAccessViewSection: React.FunctionComponent<PrivateEventAccessViewProps> = (
    props: PrivateEventAccessViewProps
) => {
    return (
        <>
            <DesktopVersionContainer>
                <Row className="m-0">
                    <Col xs={2} />
                    <Col xs={8} className="p-0">
                        {!props.isChannelPage && <PrivateEventAccessView eventDate={props.eventDate} />}
                    </Col>
                </Row>
            </DesktopVersionContainer>
        </>
    )
}

/* #endregion PrivateEventAccessView */

/* #region Links */

interface LinksProps {
    organizationId?: string
    company?: Company
    eventDate: EventDate
    useMobileDesign: boolean
}

const LinksSection: React.FunctionComponent<LinksProps> = (props: LinksProps) => {
    let links = props.eventDate?.links ?? []

    links = links.filter((link) => {
        return link.text && link.text?.length > 0
    })

    if (links.length === 0) return null

    return (
        <>
            {branding.eventDateDetailPageContent.linksVisible && links.length > 0 && (
                <SectionRoot className="links-section-root">
                    <LinksSectionRow className="m-0">
                        <SpacingCol xs={2} />
                        <Col xs={props.useMobileDesign ? 12 : 8} className="p-0" style={{ marginTop: "15px" }}>
                            <Links
                                organizationId={props.eventDate.organizationId}
                                organization={props.company}
                                links={links}
                                tooltipMessage={branding.organizationDetailPageContent.copyToClipboardText}
                                colXs={12}
                                colPadding={"0px"}
                                hideHeaderTitle={branding.eventDateDetailPageContent.hideLinksHeaderTitle}
                            ></Links>
                        </Col>
                    </LinksSectionRow>
                </SectionRoot>
            )}
        </>
    )
}

/* #endregion Links */

/* #region Media */

interface MediaProps {
    organizationId?: string
    company?: Company
    eventDate: EventDate
    useMobileDesign: boolean
}

const MediaSection: React.FunctionComponent<MediaProps> = (props: MediaProps) => {
    return (
        <>
            {branding.eventDateDetailPageContent.mediaVisibleOnEventDateDetailPage && props.eventDate?.attachments && (
                <SectionRoot>
                    <Row className="m-0">
                        <SpacingCol xs={2} />
                        <MediaSectionCol xs={props.useMobileDesign ? 11 : 8} className="p-0">
                            {props.eventDate.attachments?.length > 0 && (
                                <Media
                                    media={props.eventDate.attachments}
                                    organizationId={props.organizationId}
                                    organization={props.company}
                                    entityType="Eventdate"
                                    entityName={props.eventDate.name}
                                />
                            )}
                        </MediaSectionCol>
                    </Row>
                </SectionRoot>
            )}
        </>
    )
}

/* #endregion Media */

/* #region NonGalleryMedia */

interface NonGalleryMediaProps {
    eventDateMedia?: Attachment[]
}

const NonGalleryMediaSection: React.FunctionComponent<NonGalleryMediaProps> = (props: NonGalleryMediaProps) => {
    return (
        <>
            {branding.eventDateDetailPageContent.downloadsVisibleOnEventDateDetailPage &&
                !branding.eventDateDetailPageContent.mediaVisibleOnEventDateDetailPage &&
                props.eventDateMedia &&
                props.eventDateMedia.length && (
                    <SectionRoot>
                        <Row className="m-0">
                            <Col xs={2} />
                            <Col xs={8} className="p-0" style={{ marginTop: "15px" }}>
                                <DesktopVersionContainer>
                                    <HeaderTitle>
                                        {props.eventDateMedia.length > 1
                                            ? branding.trademarkDetailPageContent.downloadsTitle +
                                              " (" +
                                              props.eventDateMedia.length +
                                              ")"
                                            : branding.trademarkDetailPageContent.downloadTitle + " (" + 1 + ")"}
                                    </HeaderTitle>
                                </DesktopVersionContainer>
                                <div style={{ marginRight: "12px", marginLeft: "12px" }}>
                                    <NonGalleryMedia
                                        media={props.eventDateMedia}
                                        marginTop="10px"
                                        entityType="eventDate"
                                        entityName=""
                                    />
                                </div>
                            </Col>
                        </Row>
                    </SectionRoot>
                )}
        </>
    )
}

/* #endregion NonGalleryMedia */

/* #region Categories */

interface CategoriesProps {
    eventDate: EventDate
    useMobileDesign: boolean
}

const CategoriesSection: React.FunctionComponent<CategoriesProps> = (props: CategoriesProps) => {
    if (!branding.eventDateDetailPageContent.showCategories || !props.eventDate.categories) return null
    const categories = filterCategories(props.eventDate.categories)
    return (
        <>
            {categories.length > 0 && (
                <SectionRoot>
                    <Row className="m-0">
                        <SpacingCol xs={2} />
                        <Col
                            xs={props.useMobileDesign ? 12 : 8}
                            style={{
                                borderTop: branding.eventDateDetailPageContent.eventLayoutVisibleOnEventDateDetailPage
                                    ? "0px solid #transparent"
                                    : "1px solid #d9d9d9",
                                marginTop: "15px"
                            }}
                        >
                            <Categories
                                categories={categories}
                                categoryType={"eventdate"}
                                className="evtdDetail"
                                showCategoryHierarchy={branding.eventDateDetailPageContent.showCategoryHierarchy}
                                showLargeHeading
                            />
                        </Col>
                    </Row>
                </SectionRoot>
            )}
        </>
    )
}

/* #endregion Categories */

/* #region ComingUp */

interface ComingUpProps {
    isChannelPage?: boolean
    comingUpSectionVisible?: boolean
    nextEventDate?: EventDate
    calculatedChange?: number
    useMobileDesign: boolean
}

const ComingUpSection: React.FunctionComponent<ComingUpProps> = (props: ComingUpProps) => {
    return (
        <>
            {props.isChannelPage && props.nextEventDate && props.comingUpSectionVisible && (
                <SectionRoot>
                    <Row style={{ margin: "15px 0px 0px 0px" }}>
                        <SpacingCol xs={2} className="pr-0" />
                        <Col
                            xs={props.useMobileDesign ? 12 : 8}
                            className="p-0"
                            style={{
                                borderTop: branding.eventDateDetailPageContent.eventLayoutVisibleOnEventDateDetailPage
                                    ? "0px solid #transparent"
                                    : "1px solid #d9d9d9"
                            }}
                        >
                            {/* comingUp section  - visible only on channel page */}
                            <div className="w-100">
                                <ComingUpContentRoot>
                                    <ComingUp nextEventDate={props.nextEventDate} calculatedChange={props.calculatedChange!} />
                                </ComingUpContentRoot>
                            </div>
                        </Col>
                    </Row>
                </SectionRoot>
            )}
        </>
    )
}
/* #endregion ComingUp */

/* #region HallStandDetails */

interface HallStandDetailsProps {
    useMobileDesign: boolean
    eventDate: EventDate
}

const HallStandDetails: React.FunctionComponent<HallStandDetailsProps> = (props: HallStandDetailsProps) => {
    const history = useHistory()
    return (
        <>
            <SectionRoot>
                <Row style={{ margin: "15px 0px 0px 0px" }}>
                    <SpacingCol xs={2} className="pr-0" />
                    <Col xs={props.useMobileDesign ? 12 : 8} className="p-0">
                        <div className="w-100">
                            {props.eventDate?.stand && (
                                <HalleStandSection isMobile={props.useMobileDesign}>
                                    <HallStandSectionHeader className="mt-0">
                                        {branding.eventDateDetailPageContent.hallStandSectionTitle}
                                    </HallStandSectionHeader>
                                    <HalleStandSingle
                                        onClick={() => {
                                            if (!branding.organizationDetailPageContent.halleStandLinkEnabled) return

                                            history.push(
                                                hallplanOverviewRoute +
                                                    "/" +
                                                    props.eventDate?.stand.id.split("__")[0] +
                                                    "--" +
                                                    props.eventDate?.stand.id
                                            )

                                            trackSelectContent("Person details", "Details", "User")
                                        }}
                                    >
                                        {props.eventDate?.stand.standName || props.eventDate?.stand.displayName}
                                    </HalleStandSingle>
                                </HalleStandSection>
                            )}
                        </div>
                    </Col>
                </Row>
            </SectionRoot>
        </>
    )
}

/* #endregion HallStandDetails */

interface EventDateDetailBodyProps {
    eventDate: EventDate
    adjustHeight: number
    pageTitle?: string
    // only for channel page
    isChannelPage?: boolean
    streamingUrl?: string
    joinBreakoutDisabled?: boolean
    breakoutRoomParticipants?: number
    comingUpSectionVisible?: boolean
    nextEventDate?: EventDate
    event?: Event
    calculatedChange?: number
    //
}

export const EventDateDetailBody: React.FunctionComponent<EventDateDetailBodyProps> = (props) => {
    // global states
    const userAccessState = useUserRestrictedAreaAccess()
    const [eventDateMedia, setEventDateMedia] = useState<Attachment[]>()
    // component states
    // const [startDownload, setStartDownload] = useState(false)
    // const [selectedAttachment, setSelectedAttachment] = useState<Attachment>()
    const [company, setCompany] = useState<Company>()

    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const [millisecondsLeft, setMillisecondsLeft] = useState<number>(
        branding.eventDateDetailPageContent.minutesBeforeEnablingTypeformSection * 60 * 1000
    )

    const { useMobileDesign } = useWindowDimensions()

    let sectionsOrder: EventDateDetailSection[] = branding.eventDateDetailPageContent.sections

    const [time, setTime] = useState(
        moment(branding.eventDateDetailPageContent.minutesBeforeEnablingTypeformSection * 60 * 1000).format("mm:ss")
    )

    const [typeformButtonDisabled, setTypeformButtonDisabled] = useState<boolean>(false)

    // other
    const { channelId }: any = useParams()
    // const containerRef = useRef<HTMLDivElement>(null)

    /* #region  useEffects */

    useEffect(() => {
        if (
            branding.eventDateDetailPageContent.typeformSectionEnabled &&
            props.eventDate.typeformUrl &&
            props.eventDate.videoOnDemandUrl
        ) {
            const interval = setInterval(() => {
                if (typeformButtonDisabled) {
                    setTime(
                        moment(millisecondsLeft - 1000)
                            .utc()
                            .format("mm:ss")
                    )
                    setMillisecondsLeft(millisecondsLeft - 1000)
                }

                if (millisecondsLeft <= 1500) {
                    setTypeformButtonDisabled(false)
                }
            }, 1000)

            return () => clearInterval(interval)
        }
        // eslint-disable-next-line
    })

    useEffect(() => {
        if (!userAccessState.isLoaded(props.eventDate)) userAccessState.fetchUserAccessForSingleRestrictedArea(props.eventDate)
        setTypeformButtonDisabled(true)
        // eslint-disable-next-line
    }, [props.eventDate]) //eslint-disable-line

    useEffect(() => {
        if (
            branding.eventDateDetailPageContent.downloadsVisibleOnEventDateDetailPage &&
            props.eventDate &&
            props.eventDate.attachments &&
            props.eventDate.attachments.length > 0
        ) {
            const nonSliderMedia = filterMedia(props.eventDate.attachments, false)
            if (nonSliderMedia && nonSliderMedia.length) setEventDateMedia(nonSliderMedia)
        }
        // eslint-disable-next-line
    }, [props.eventDate])

    useEffect(() => {
        if (props.eventDate.organizationId) {
            loadExhibitorData({
                organizationid: props.eventDate.organizationId,
                hideNewsdata: false
            })
                .then((resp) => {
                    setCompany(resp.content)
                })
                .catch((e: { message: React.SetStateAction<string> }) => {
                    // Error logged in Backend Services
                })
        }
    }, [props.eventDate.organizationId])

    // redirecting user to the channel page when session starts (without reloading the page)
    useEffect(() => {
        if (
            !props.eventDate ||
            !props.eventDate.channel ||
            props.eventDate.type === "roundtable" ||
            props.isChannelPage ||
            !userAccessState.isUnlocked(props.eventDate)
        )
            return

        const now = getNow()
        const eventdateStart = moment(props.eventDate.startdatetime)
        const eventdateEnd = moment(props.eventDate.enddatetime)

        if (!isSoftOpeningPhase && !isLivePhase) return

        if (now.isAfter(eventdateEnd)) return

        if (now.isAfter(eventdateStart) && now.isBefore(eventdateEnd))
            window.open(`/channel/${props.eventDate.channel?.id || props.eventDate.id}`, "_self")

        if (now.isBefore(eventdateStart)) {
            const timeToLive = eventdateStart.diff(now)
            if (timeToLive < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => {
                    window.open(`/channel/${props.eventDate.channel?.id || props.eventDate.id}`, "_self")
                }, timeToLive)
                return () => clearTimeout(handle)
            }
        }
    }, [userAccessState.isUnlocked(props.eventDate)]) // eslint-disable-line

    /* #endregion */

    const pointsBadgeData = (props.eventDate.categories as unknown as Category[])?.filter(
        (x) => x.id === branding.programSchedule.pointsBadgeCategoryAlias
    )[0]
    const organizationId = props.eventDate && props.eventDate.organizationId

    return (
        <EventDateDetailBodyRoot adjustHeight={props.adjustHeight}>
            <RecommendModal />
            <ContentScrollContainer containerWidth={"75%"} adjustForHeaderWith={"0"}>
                <LiveEventSection
                    isChannelPage={props.isChannelPage}
                    eventDate={props.eventDate}
                    streamingUrl={
                        props.isChannelPage && props.streamingUrl
                            ? props.streamingUrl
                            : props.eventDate.channel?.id || props.eventDate.id
                    }
                    isLive={props.eventDate ? props.eventDate.type !== "roundtable" && isEventDateLive(props.eventDate) : false}
                    organizationId={organizationId}
                    pageTitle={props.pageTitle || ""}
                    useMobileDesign={useMobileDesign}
                />
                <div className="container-root" style={{ position: "relative", color: branding.mainInfoColor }}>
                    {sectionsOrder.map((item: EventDateDetailSection, index: number) => {
                        switch (item) {
                            case EventDateDetailSection.BASIC_INFO: {
                                return (
                                    <BasicInfoSection
                                        eventDate={props.eventDate}
                                        pointsBadgeData={pointsBadgeData}
                                        isChannelPage={props.isChannelPage}
                                        userAccessState={userAccessState}
                                        joinBreakoutDisabled={props.joinBreakoutDisabled}
                                        breakoutRoomParticipants={props.breakoutRoomParticipants}
                                        showRecommendModal={showRecommendModal}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.TYPEFORM: {
                                return (
                                    <TypeformSection
                                        eventDate={props.eventDate}
                                        typeformButtonDisabled={typeformButtonDisabled}
                                        time={time}
                                    />
                                )
                            }

                            case EventDateDetailSection.COMPANY_DETAILS: {
                                return (
                                    <CompanyDetailsSection
                                        organizationId={props.eventDate?.organizationId}
                                        company={company}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.ADDITIONAL_ORGAS: {
                                return <AdditionalOrgasSection useMobileDesign={useMobileDesign} eventDate={props.eventDate} />
                            }

                            case EventDateDetailSection.DESCRIPTION: {
                                return <DescriptionSection eventDate={props.eventDate} />
                            }

                            case EventDateDetailSection.EVENT_LAYOUT: {
                                return <EventLayoutSection eventDate={props.eventDate} useMobileDesign={useMobileDesign} />
                            }

                            case EventDateDetailSection.PERSONS: {
                                return <PersonsSection eventDate={props.eventDate} useMobileDesign={useMobileDesign} />
                            }

                            case EventDateDetailSection.PRIVATE_EVENT_ACCESS_VIEW: {
                                return (
                                    <PrivateEventAccessViewSection
                                        isChannelPage={props.isChannelPage}
                                        eventDate={props.eventDate}
                                    />
                                )
                            }

                            case EventDateDetailSection.LINKS: {
                                return (
                                    <LinksSection
                                        organizationId={props.eventDate.organizationId}
                                        company={company}
                                        eventDate={props.eventDate}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.MEDIA: {
                                return (
                                    <MediaSection
                                        organizationId={props.eventDate.organizationId}
                                        company={company}
                                        eventDate={props.eventDate}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.NON_GALLERY_MEDIA: {
                                return <NonGalleryMediaSection eventDateMedia={eventDateMedia} />
                            }

                            case EventDateDetailSection.CATEGORIES: {
                                return <CategoriesSection eventDate={props.eventDate} useMobileDesign={useMobileDesign} />
                            }

                            case EventDateDetailSection.COMING_UP: {
                                return (
                                    <ComingUpSection
                                        isChannelPage={props.isChannelPage}
                                        comingUpSectionVisible={props.comingUpSectionVisible}
                                        nextEventDate={props.nextEventDate}
                                        calculatedChange={props.calculatedChange}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.HALLSTANDDETAILS: {
                                return <HallStandDetails useMobileDesign={useMobileDesign} eventDate={props.eventDate} />
                            }

                            default:
                                return <div />
                        }
                    })}

                    <MobileVersionContainer>
                        <div style={{ marginBottom: "120px" }} />
                    </MobileVersionContainer>
                </div>
            </ContentScrollContainer>
            <ChatAreaContainer multiSwitchItemsVisible={branding.eventDateDetailPageContent.multiSwitchItemVisible}>
                <Chat
                    eventDate={props.eventDate}
                    channelAlias={channelId}
                    hideQnA={!props.isChannelPage}
                    type={props.isChannelPage ? "channel" : "eventDate"}
                />
            </ChatAreaContainer>
        </EventDateDetailBodyRoot>
    )
}
/* #endregion */

const EventDateDetailPageContent: React.FunctionComponent = (props) => {
    let { eventDateId }: any = useParams()
    const [eventDate, setEventDate] = useState<EventDate>()
    const [isLoaded, setIsLoaded] = useState(false)
    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const navi = useNavigationSource()
    const profileId = useLoggedInState().user()?.profileId
    const appState = useAppState()

    //Recommend modal
    const [organization, setOrganization] = useState<SimpleOrganization>()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    //add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef } = TopBannerSharedState()
    const adjustHeight =
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0) +
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) +
        65

    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(branding.programPageContent.scheduleTabTitle)
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        let params = {
            id: eventDateId,
            rootCategories: branding.eventDateDetailPageContent.rootCategories as any,
            showCategoryHierarchy: branding.eventDateDetailPageContent.showCategoryHierarchy || (false as any)
        }
        if (!branding.eventDateDetailPageContent.rootCategories?.length) delete params.rootCategories

        loadEventDateDetails(params)
            .then((resp) => {
                if (resp.content.organizationId && profileId) {
                    trackEventDateDetailsPageVisit(profileId!, resp.content.organizationId, navi.get(), resp.content.id)
                }
                navi.set("UNKNOWN")
                setIsLoaded(true)
                setEventDate(resp.content)
                if (resp?.content?.videoOnDemandUrl) {
                    // different video is loaded
                    if (resp?.content?.videoOnDemandUrl !== appState.vodEventDate?.videoOnDemandUrl) {
                        appState.setVideoPlayerStatus({
                            timeOffsetVOD: 0,
                            isPaused: true
                        })
                    }
                    appState.setVodEventDate(null)
                    appState.setLiveStreamChannel(null)
                }
                let organization = {
                    id: resp.content.organizationId ?? "",
                    name: resp.content.organizationName ?? "",
                    consentText: resp.content.organizationConsent.consentText,
                    consentTitle: resp.content.organizationConsent.title
                }
                setOrganization(organization)
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsLoaded(true)
            })
        // eslint-disable-next-line
    }, [eventDateId, profileId, lang])

    useEffect(() => {
        if (organization !== undefined && branding.configuration.showPrivacyModelForEventDates)
            showPrivacyPolicyModal(organization, () => {})

        // setShowPrivacyPolicyModal(organizationId !== undefined && branding.configuration.showPrivacyModelForEventDates === true)
    }, [organization]) //eslint-disable-line

    const breadcrumb: BreadcrumbItem[] = []
    if (eventDate) {
        const locations = calcBreadcrumbLocations()
        breadcrumb.push(
            { to: locations[0] + "#schedule", name: branding.programPageContent.scheduleTabTitle },
            { to: locations[1], name: eventDate.name }
        )
    }

    let content: JSX.Element | null = null

    if (isLoaded && eventDate) {
        content = <EventDateDetailBody eventDate={eventDate} pageTitle={eventDate.name} adjustHeight={adjustHeight} />
    } else {
        content = <CenteredLoader />
    }

    return (
        <>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar setRef={setTopBarRef} />
            <div style={{ borderBottom: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9" }}>
                <Breadcrumb breadcrumb={breadcrumb} classification="Details" contentType="Eventdate" />
            </div>
            {content}
            <PrivacyModal />
        </>
    )
}

export default EventDateDetailPageContent
