import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import moment from "moment"
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import * as React from "react"
import { Button, Dropdown, DropdownButton, Form, Modal, NavLink, Tooltip } from "react-bootstrap"
import DatePicker, { registerLocale } from "react-datepicker"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { CalendarEntryParticipationStatus, ConversationType } from "../../API"
import { trackVisit } from "../../backendServices/TrackingServices"
import { EventDate, InvitePerson } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { ChatConversationParam } from "../../communicationArea/ChatPage"
import { saveMeetings } from "../../communicationArea/ICal"
import SearchPersonInput from "../../communicationArea/SearchPersonInput"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { buildDetailLink } from "../../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../../globalStates/AppState"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import useComponentVisible from "../../navigationArea/useComponentVisible"
import {
    findTimezoneName,
    getIndexOfInitialDayToShow,
    getTimezones,
    isBeforeToday,
    isToday,
    momentWithoutTimezoneFromTimezonedMoment,
    Timezone
} from "../../utils/DateUtils"
import { isExplorationOrPostEventPhase } from "../../utils/EventPhaseChecker"
import { AvatarWithDefault } from "../AvatarWithDefault"
import CenteredLoader from "../CenteredLoader"
import DataUpdateAlert from "../DataUpdateAlert"
import DeleteCalendarEntryModal from "./DeleteCalendarEntryModal"
import {
    IconArrowDownTimezoneDropdown,
    IconArrowLeft,
    IconArrowUpTimezoneDropdown,
    IconCalendarEntry,
    IconCheckmarkGreen,
    IconCheckmarkSuccessfullMini,
    IconChevronLeftSolid,
    IconChevronRightSolid,
    IconClose,
    IconCloseRed,
    IconIndicatorErrorBlack,
    IconLocation
} from "../Icons"
import PersonChip from "../PersonChip"
import { MeetingSlotsDay, TimeSlotType } from "../MeetingSlots"
import { isSlotDisabled } from "../../helper/MeetingSlotsHelper"
import TextLimit from "../TextLimit"
import { DesktopVersionContainer, device, MobileVersionContainer } from "../../utils/Device"
import { CustomOverlayTrigger } from "../CustomOverlayTrigger"
import {
    CalendarEntry,
    createNewCalendarEntry,
    createNewCalendarEntryParticipation,
    deleteCalendarEntryForUser,
    deleteCalendarEntryParticipationById,
    getUserBusyTimes,
    updateCalendarEntryById,
    updateCalendarEntryParticipationStatus,
    UserBusyTimes
} from "../../backendServices/GraphQLServices"
import Select, {
    ActionMeta,
    InputActionMeta,
    OptionProps,
    OptionTypeBase,
    ValueType,
    GroupTypeBase,
    createFilter,
    GroupProps,
    components
} from "react-select"
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch"
import { Config } from "react-select/src/filters"
import { loadSuggestions } from "../../backendServices/SuggestionServices"
import { debounce } from "lodash"
import { useHistory } from "react-router-dom"
import { ToggleSwitchRoot } from "../ToggleSwitch/ToggleSwitch.styled"

registerLocale("en-GB", en)

export enum CalendarEntryModalViewMode {
    CREATE,
    EDIT,
    VIEW
}

const ModalRootDiv = styled.div`
    width: 600px;

    @media ${device.mobile} {
        width: 100%;
    }
`

const WhenTimeContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    a:focus {
        opacity: 0.5;
    }

    .rs-picker-date {
        border: none;
        border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
        border-radius: 0px;
    }

    .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn {
        border: none;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        border: none;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-left: 0px;
    }

    .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
    .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
        color: ${branding.calendarEntryModalPageContent.datePickerMenuHeaderColor ?? "#1675e0"};
    }
`
const Title = styled.div`
    /* text-overflow: ellipsis;
    font-weight: bold;
    overflow: hidden;
    margin-top: 9px;
    font-size: 26px;
    font-family: ${branding.font1}; */
    width: auto;
    height: 20px;
    top: 35px;
    left: 30px;
    font-size: 18px;
    line-height: 20px;
    font-style: normal;
    font-family: ${branding.font1};
    font-weight: bold;
`
const SubmitButtonContainerLinks = styled.div`
    /* display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 12px;
    font-family: ${branding.font1}; */
    padding: 15px 0;
    /* margin-left: -35px;
    margin-right: -35px; */
    display: flex;
    position: relative;
    color: ${branding.primaryColor};
    font-size: 0.75rem;
    font-family: ${branding.font1};
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    width: 100%;
    height: 40px;
`

const ChatWithParticipantsDiv = styled.div`
    display: flex;
    margin-left: -14px;
    margin-right: -14px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: ${branding.font1};
    color: "#727272";
`

const SubmitButtonContainer = styled.div`
    /* display: flex;
    justify-content: space-between;
    margin-left: -35px;
    margin-right: -35px;
    margin-top: 30px;
    margin-bottom: 32px; */
    /* flex: 1; */
    width: 100%;
    justify-content: flex-end;
    display: inline-flex;
    margin-bottom: 20px;
    margin-top: 30px;

    /* .submitButtonInput-root {
        display: flex;
        align-items: center;
    } */
`

const CancelMeetingContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
`

const JoinMeetingContainer = styled.div`
    display: flex;
    width: 50%;
    justify-content: flex-end;
`

const ExportMeetingContainer = styled.div`
    display: flex;
    width: 50%;
    justify-content: flex-start;
`

const OtherButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @media ${device.mobile} {
        justify-content: space-between;
        margin-left: 5px;
        margin-right: 5px;
    }
`
const SelectDateRoot = styled.div`
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-flow: row;
    position: relative;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    cursor: pointer;
    font-size: 16px;
    line-height: 17px;
    height: 30px;

    .dayInput-root {
        position: absolute;
        right: 30px;
    }

    .react-datepicker__input-container::placeholder {
        color: #727272;
    }

    .react-datepicker__current-month {
        font-family: ${branding.font1};
        font-size: 12px !important;
        line-height: 24px !important;
        color: #727272 !important;
    }

    .react-datepicker__day-name {
        font-family: ${branding.font1};
        font-size: 10px;
        line-height: 16px;
        align-items: center;
        color: #727272 !important;
    }

    .meetingDate {
        color: ${branding.calendarEntryModalPageContent.calendarTheme};
        border-radius: 2px !important;
        border-color: ${branding.calendarEntryModalPageContent.calendarTheme} !important;
        background: none !important;
        border: 0;
        outline: none;
    }

    .nonMeetingDate {
        border-radius: 50% !important;
        border-color: #000 !important;
        background: none !important;
        border: 0;
        outline: none;
    }

    .react-datepicker__day--today {
        font-weight: normal;
    }

    .past-dates-disabled {
        color: #c9c9c9;
        cursor: default;
    }

    .react-datepicker__day--selected {
        border: 1px solid !important;
        border: 1px solid !important;
        background-color: ${branding.crsTabs.defaultActionItemActiveStateColor} !important;
        color: #fff !important;
        border-radius: 3px !important;
        cursor: pointer;
    }

    .react-datepicker {
        border: 1px solid #727272;
        border-radius: 5px;
    }

    .react-datepicker__day-names {
        font-weight: bold;
    }

    .react-datepicker-wrapper {
        display: flex;
        flex: 1;
    }

    .react-datepicker__header {
        border: 0 !important;
        background-color: #fff;
        padding-top: 5px;
    }

    .react-datepicker__navigation-icon--previous {
        display: none !important;
    }

    .react-datepicker__navigation-icon--next {
        display: none !important;
    }

    .react-datepicker__navigation--previous {
        left: 10px;
        top: 10px;
        background: url(../branding/applicationMedia/icons/chevron-left-solid-bg.svg) no-repeat;
        border: none;
        height: 15px;
    }

    .react-datepicker__navigation--next {
        right: -10px;
        top: 10px;
        background: url(../branding/applicationMedia/icons/chevron-right-solid-bg.svg) no-repeat;
        border: none;
        height: 15px;
    }

    .btn {
        text-align: left;
        width: 600px;
        box-shadow: none;
    }

    span {
        position: absolute;
        right: 5px;
    }

    .btn-primary {
        border: none;
        border-bottom: 1px solid ${branding.mainInfoColor};
        border-radius: 0px;
        padding-left: 0px;
        font-size: inherit;
        cursor: default;
        color: #8e8e93;
        background-color: #fff;
        box-shadow: none;
    }

    .btn-primary:hover {
        background-color: #fff;
    }

    .btn-primary a:hover {
        background: ${branding.mainInfoColor};
    }

    .btn-primary:active,
    .btn-primary.active {
        outline: unset;
        border: none;
        background-color: #fff;
    }

    .btn .btn-primary:focus {
        outline: unset !important;
        box-shadow: none;
        border: none;
    }
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
        color: ${branding.mainInfoColor};
        background-color: #fff;
        border-color: #fff;
        border: none;
    }
    .dropdown-item {
        width: 600px;
        font-size: 14px;
    }
    .dropdown-item:focus {
        background-color: #fff;
        color: ${branding.mainInfoColor};
    }

    .dropdown-toggle::after {
        content: none;
    }
`
const PickersGroupRoot = styled.div`
    margin-bottom: 0px;
    position: relative;

    .nameInput-root {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }

    .form-control {
        border: none;
        border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
        border-radius: 0px;
        padding-left: 0px;
        color: #000;
        font-size: 16px;
        line-height: 17px;
        cursor: default;
        padding: 0;
        height: 30px;
    }

    .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .rs-picker-date {
        border-bottom: 1px solid ${branding.mainInfoColor};
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-left: 0px;
    }

    .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn {
        border: none;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        border: none;
    }
`

const StyledTitleInput = styled(Form.Control)`
    width: 100%;
    border: none;
    border-bottom: ${branding.mainBorder ?? "1px solid #d9d9d9"};
    border-radius: 0px;
    padding-left: 0px;
    color: ${branding.primaryColor};
    font-size: 16px;
    line-height: 17px;
    cursor: default;
    padding: 0 25px 0 0;
    height: 30px;

    &:focus {
        outline: none;
    }
`

const StyledNotesInput = styled(Form.Control)`
    border: ${branding.mainBorder ?? "1px solid #d9d9d9"};
    border-radius: 5px;
    resize: none;
    width: 100%;
    cursor: default;
    padding: 15px;
    overflow-y: scroll;
    font-size: 12px;
    line-height: 20px;
    color: ${branding.primaryColor};
    margin-bottom: -10px;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #727272 !important;
        /* padding: 5px; */
    }
`

const PeopleDisplayArea = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
`
// const SubmitButtonDanger = styled.div`
//     width: 50%;
//     height: 30px;
//     cursor: pointer;
//     font-size: 12px;
//     font-family: ${branding.font1};
//     color: ${branding.primaryColor};

//     &:hover {
//         text-decoration: none;
//     }
// `
const CancelButton = styled(Button)<{ viewMode: CalendarEntryModalViewMode; customColor?: boolean }>`
    display: block;
    // eslint-disable-next-line
    width: ${(props) => (props.viewMode === CalendarEntryModalViewMode.CREATE ? "22%" : "40%")};
    height: 35px;
    /* margin-top: 20px; */
    color: ${(props) => (props.customColor ? "red" : branding.recommendModal.cancelBtnPrimaryTextColor)};
    background-color: transparent;
    border: 1px solid ${(props) => (props.customColor ? "red" : branding.recommendModal.cancelBtnPrimaryBorderColor)}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;

    &:hover {
        text-decoration: none;
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
        border: none;
    }

    :active {
        outline: 0 !important;
        border: none !important;
    }
    :focus {
        outline: 0 !important;
        border: none !important;
    }

    @media ${device.mobile} {
        width: 50%;
    }
`
const SubmitButton = styled(Button)<{ viewMode: CalendarEntryModalViewMode; disabled?: boolean }>`
    display: block;
    width: ${(props) => (props.viewMode === CalendarEntryModalViewMode.CREATE ? "22%" : "40%")};
    height: 35px;
    /* margin-top: 20px; */
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
    // border-radius: 20px;
    border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};

    &:hover {
        background-color: ${(props) =>
            props.disabled ? "none" : branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${(props) =>
            props.disabled
                ? branding.recommendModal.submitBtnPrimaryTextColor
                : branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
        border: 1px solid
            ${(props) =>
                props.disabled
                    ? branding.recommendModal.submitBtnPrimaryBorderColor
                    : branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    }

    &:active {
        outline: 0 !important;
        border: none !important;
    }

    &:focus {
        outline: 0 !important;
        border: none !important;
    }

    @media ${device.mobile} {
        width: 50%;
    }
`

// const SubmitButton = styled(Button) <{ backgroundColor: string }>`
//     display: block;
//     width: 25%;
//     /* margin: auto; */
//     border-radius: 20px;
//     font-size: 12px;
//     background-color: ${props => props.backgroundColor};
//     color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
//     border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};

//     :hover {
//       background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor ?? "#e8e8e8"} !important;
//       color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor ?? "#000"} !important;}
//       border: 2px solid ${props => props.backgroundColor};
// `

// const SubmitButton = styled(Button)`
//     display: block;
//     width: 30%;
//     /* margin-top: 20px; */
//     color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
//     background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
//     border: 2px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
//     border-radius: 20px;
//     font-size: 12px;
//     font-family: ${branding.font1};

//     :hover {
//         background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
//         color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
//     }
// `

const StyledSearchPersonInput = styled(SearchPersonInput)`
    margin-bottom: 24px;
    .searchPersonResultItem > div:nth-child(1) {
        padding-right: 25px !important;
    }
`

const CalendarEntryMainModal = styled(Modal)<{ showAvailable: boolean }>`
    color: ${branding.mainInfoColor};
    font-family: ${branding.font1};
    .modal-dialog {
        width: 650px !important;
        max-width: 650px !important;
        min-width: 650px !important;

        @media ${device.mobile} {
            width: auto !important;
            min-width: auto !important;
        }
    }

    .modal-body {
        padding: 1rem 1rem 1rem 0rem;
    }

    .modal-footer {
        padding: 0rem;
        justify-content: center;
        margin-right: -30px;
        margin-left: -30px;
    }

    .modal-content {
        padding-left: 30px;
        padding-right: ${(props) => (props.showAvailable ? "0" : "0px")};
        width: ${(props) => (props.showAvailable ? "1100px" : "")};
        padding-top: 30px;
        padding-bottom: 0px;
        overflow: hidden;
        /* border-radius: 0px; */
        display: flex;
        flex-flow: row;
        border: 0.5px solid rgba(0, 0, 0, 0.2);
        background: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        @media ${device.mobile} {
            padding-left: 18px;
        }
    }

    .modal-header {
        padding: 35px 0 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;

        @media ${device.mobile} {
            padding-top: 4px !important;
        }
    }

    .modal-header .modal-title {
        width: 100%;
        overflow: visible;
        white-space: normal;
        color: ${branding.mainInfoColor};
    }

    .btn .btn-primary:focus {
        outline: 0 !important;
    }
`

const CalendarEntryModalDatePicker = styled(DatePicker)`
    border: none;
    width: 100%;
    outline: none;
    color: #000;
    background-color: #fff;
    padding-left: 0;
    cursor: pointer;

    ::-webkit-input-placeholder {
        font-weight: 100;
    }

    ::-moz-placeholder {
        font-weight: 100;
    }

    :-moz-placeholder {
        font-weight: 100;
    }

    :-ms-input-placeholder {
        font-weight: 100;
    }
`

const TimePickerRoot = styled.div`
    display: flex;
    flex-flow: column;
    flex: 0.3;
    position: relative;

    .startTimeInput-root,
    .endTimeInput-root {
        position: absolute;
        right: 0;
        bottom: 6px;
    }

    .react-datepicker__triangle,
    .react-datepicker__header {
        display: none;
    }

    .react-datepicker__time,
    .react-datepicker__time-box,
    .react-datepicker__time-container {
        border-radius: 0;
        width: 70px !important;
    }

    .react-datepicker {
        border-radius: 0;
        border-color: black;
    }

    li {
        text-align: start;
        outline: none;
    }

    .react-datepicker__time-list-item--selected {
        background: ${branding.calendarEntryModalPageContent.calendarTheme} !important;
        color: black !important;
    }

    @media ${device.mobile} {
        flex: 0.45;
    }
`

const CalendarEntryTimePicker = styled(DatePicker)`
    border: none;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    outline: none;
    width: 100%;
    height: 35px;

    :focus {
        background-color: #d9d9d9;
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
    }
`
const CurrentTimeZoneTitle = styled.div`
    display: flex;
    flex-flow: row;
    padding-left: 12px;
    font-size: 12px;
    line-height: 17px;
    font-weight: bold;
    /* background-color: #2e2a2a; */
    align-items: center;
    height: 14px;
`

const TimezoneModalButton = styled.button<{ backgroundColor: string }>`
    display: block;
    width: 20%;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-weight: bold;
    background-color: ${(props) => props.backgroundColor};
    color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
    /* border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"}; */
    border: 0;
    height: 25px;
    margin-top: 15px;
    margin-left: 250px;
`

const CloseButton = styled.div`
    /* margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 0px;
    padding-right: 1rem; */
    position: absolute;
    right: 30px;
    top: 30px;

    cursor: pointer;
    svg {
        color: ${branding.mainInfoColor};
    }

    @media ${device.mobile} {
        right: 15px;
        top: 25px;
    }
`
// const DisagreeButton = styled.button`
//     width: 25%;
//     height: 35px;
//     cursor: pointer;
//     font-size: 12px;
//     font-family: ${branding.font1};
//     display: flex;
//     flex-flow: row;
//     align-items: center;
//     justify-content: center;
//     margin-right: 15px;
//     border-radius: 20px;
//     background-color: #fff;
//     color: #000;
//     border: 2px solid  #000;

//     :hover {
//       background-color: "#000";
//       color: "#fff"}
//       border: 2px solid #000;
// `
const ButtonLinks = styled(NavLink)`
    /* text-decoration: underline; */
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0 10px;
    /* padding: 0px; */
    /* margin-left: 15px;
    margin-right: 15px; */
    /* border-bottom: 1px solid ${branding.mainInfoColor}; */
    color: ${branding.primaryColor};

    &:hover {
        text-decoration: none;
        color: ${branding.mainInfoColor};
    }

    &::after {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #d9d9d9;
    }
`

const ChatWithParticipantsButtonLink = styled(NavLink)`
    /* text-decoration: underline; */
    padding: 0px;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid #727272;
    color: #727272;
    &:hover {
        text-decoration: none;
        color: ${branding.mainInfoColor};
    }
`
const ExportMeetingsLink = styled(NavLink)`
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 17px;
    color: #727272;
    padding: 10px 0px;

    &:hover {
        text-decoration: none;
        color: #727272;
    }
`
const Label = styled.div`
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    height: 13px;
    font-family: ${branding.font1};
    white-space: nowrap;
`

const NotesContainer = styled.div`
    margin-top: 30px;
`

const MAX_NOTES_LENGTH = 200

const MAX_TITLE_LENGTH = 200

enum CalendarEntryParticipationStatusColor {
    ACCEPTED = "#00B300",
    DECLINED = "#FF0000",
    REQUESTED = "#000000"
}

function isDateInValid(day: Date | null, startDate: Date | null, endDate: Date | null): Boolean {
    if (day == null || startDate == null || endDate == null) {
        return true
    }
    // cannot create meeting in the past
    if (isBeforeToday(moment(day))) return true
    return startDate > endDate || endDate < startDate
}
interface TimezonePickerProps {
    viewMode: CalendarEntryModalViewMode
    setSelectedTimezone: (timezone: string) => void
    selectedTimezone: string
    timezonePickerOpen: boolean
    setTimezonePickerOpen: (value: boolean) => void
}

const CalendarEntryTimezonePicker: React.FC<TimezonePickerProps> = (props) => {
    const timezones = getTimezones()
    const rootRef = useRef<HTMLDivElement>(null)
    const appState = useAppState()
    const [selectedTimezone, setSelectedTimezone] = useState<string>(appState.timezone)
    const [timeZoneDropdown, setTimeZoneDropdown] = useState<boolean>(false)

    function onTimezoneSelect(item: Timezone) {
        setSelectedTimezone(item.timezones[0])
    }

    return (
        <TimezoneDropdownRoot ref={rootRef}>
            <Label>{branding.calendarEntryModalPageContent.timeZone}</Label>
            <TimezoneDropdownButton
                onClick={() => props.setTimezonePickerOpen(!props.timezonePickerOpen)}
                disabled={props.viewMode === CalendarEntryModalViewMode.VIEW}
            >
                <p>
                    {props.selectedTimezone
                        ? findTimezoneName(props.selectedTimezone)
                        : branding.communicationArea.timezonePickerText}
                </p>
                {props.timezonePickerOpen
                    ? IconArrowUpTimezoneDropdown({ fill: branding.sideIconBar.sideIconColorDark, width: "12", height: "12" })
                    : IconArrowDownTimezoneDropdown({ fill: branding.sideIconBar.sideIconColorDark, width: "12", height: "12" })}
            </TimezoneDropdownButton>
            {props.timezonePickerOpen && (
                <TimezonePickerModalDialog mobileWidth={rootRef?.current?.clientWidth || 350}>
                    <TimezoneModalHeader>
                        <TimezoneModalTitle>{branding.calendarEntryModalPageContent.timeZoneModalTitle}</TimezoneModalTitle>
                        <CloseIcon
                            style={{ marginTop: "-5px" }}
                            onClick={() => {
                                props.setTimezonePickerOpen(false)
                                setTimeZoneDropdown(false)
                            }}
                        >
                            {IconClose({ fill: "#000", width: "10", height: "10" })}
                        </CloseIcon>
                    </TimezoneModalHeader>
                    <TimezoneModalBody onClick={() => setTimeZoneDropdown(!timeZoneDropdown)}>
                        <CurrentTimeZoneTitle>
                            {branding.calendarEntryModalPageContent.timeZoneDropdownCurrentTimezoneTitle}
                        </CurrentTimeZoneTitle>
                        <ArrowIcon>
                            {timeZoneDropdown
                                ? IconArrowUpTimezoneDropdown({
                                      fill: "#000",
                                      width: "12",
                                      height: "12"
                                  })
                                : IconArrowDownTimezoneDropdown({
                                      fill: "#000",
                                      width: "12",
                                      height: "12"
                                  })}
                        </ArrowIcon>
                        <TimezoneDropdown
                            show={timeZoneDropdown}
                            title={
                                selectedTimezone
                                    ? findTimezoneName(selectedTimezone)
                                    : branding.communicationArea.timezonePickerText
                            }
                        >
                            {timezones.map((item, key) => {
                                return (
                                    <TimezoneDropdownItem key={key} onClick={() => onTimezoneSelect(item)}>
                                        {item.name}
                                    </TimezoneDropdownItem>
                                )
                            })}
                        </TimezoneDropdown>
                    </TimezoneModalBody>
                    <TimezoneModalFooter>
                        <TimezoneModalButton
                            onClick={() => {
                                props.setSelectedTimezone(selectedTimezone)
                                props.setTimezonePickerOpen(false)
                                setTimeZoneDropdown(false)
                            }}
                            backgroundColor={branding.sayHelloModal.submitBtnPrimaryBgColor}
                        >
                            {branding.sayHelloModal.meetingTimeZoneSubmitBtnText}
                        </TimezoneModalButton>
                    </TimezoneModalFooter>
                </TimezonePickerModalDialog>
            )}
        </TimezoneDropdownRoot>
    )
}

const TimezoneDropdownRoot = styled.div`
    position: relative;
    /* border-bottom: 1px solid #e8e8e8; */
    display: flex;
    flex-flow: column;
    width: 60%;

    @media ${device.mobile} {
        width: 100%;
        margin-top: 30px;
    }
`

const TimezoneDropdownButton = styled(Button)`
    width: 100%;
    height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #727272 !important;
    background-color: #fff !important;
    border-color: #e8e8e8 !important;
    border-radius: 5px;
    border: 1px solid #c9c9c9 !important;
    font-size: 12px;
    line-height: 17px;
    outline: none !important;
    box-shadow: none !important;
    text-align: start;
    display: flex;
    flex-flow: row;

    p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0px;
        margin-top: 2px;
    }

    svg {
        margin-top: 2px;
    }

    span {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
`
const TimezoneDropdown = styled(DropdownButton)`
    /* background-color: #e8e8e8; */
    .dropdown-toggle {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        padding-top: 0;
    }
    .dropdown-menu {
        overflow: scroll;
        width: 100%;
        height: 200px;
        border-color: #727272;
        /* border-radius: 5px;
         */
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 12px;
        line-height: 17px;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .btn,
    .btn-primary {
        background-color: #d9d9d9 !important;
        /* border-color: #e8e8e8 !important; */
        border-radius: 0;
        /* color: black !important;
        border: 1px solid; */
        border: none;
        color: #202428 !important;
        outline: none !important;
        text-align: start;
        box-shadow: none !important;
        font-size: 12px;
        line-height: 17px;
        height: 16px;
    }
`
const TimezoneDropdownItem = styled(Dropdown.Item)`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    :active {
        background-color: #d9d9d9;
    }
`

const TimezonePickerModalDialog = styled(Modal.Dialog)<{ mobileWidth: number }>`
    position: absolute;
    z-index: 6;
    left: 0px;
    top: 58px;
    margin-top: 11px;

    .modal-content {
        width: 350px;
        overflow: visible;
        display: flex;
        flex-flow: column;
        /* padding-left: 10px;
        padding-right: 10px; */
        border-color: #727272;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        /* padding-top: 5px; */
        padding: 15px;

        @media ${device.mobile} {
            width: ${(props) => props.mobileWidth.toString() + "px"};
            margin-left: -8px;
        }

        @media (max-width: 350px) {
            width: 330px;
            margin-left: 2px;
        }
    }

    @media (max-width: 350px) {
        margin-left: -21px;
    }
`
const TimezoneModalHeader = styled(Modal.Header)`
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0 !important;
    padding-top: 5px !important;
`

const TimezoneModalTitle = styled(Modal.Title)`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
`
const TimezoneModalBody = styled(Modal.Body)`
    position: relative;
    cursor: pointer;
    /* height: 50px; */
    background-color: #d9d9d9;
    border-radius: 5px;
    padding: 0;
    margin-top: 15px;
`

const TimezoneModalFooter = styled(Modal.Footer)`
    border: 0;
    padding: 0px;
`

const CloseIcon = styled.div`
    cursor: pointer;
`
const ArrowIcon = styled.div`
    position: absolute;
    top: 20px;
    right: 22px;
    z-index: 1;
`

enum LocationActionType {
    STANDLINK = "standlink",
    CUSTOM = "",
    NONE = "" // redundant for better code readability
}

interface Location {
    standId?: string // locationActionParam: stand_alias
    locationName?: string // stand_name | user location description
    locationActionType?: LocationActionType
    isVirtual: boolean
}

interface LocationOption extends OptionTypeBase {
    organizationName: string
    hallName: string
    standName: string
    standAlias: string
    label: string
    value: string
}

enum LocationPickerMode {
    STAND = "stand",
    CUSTOM = "custom",
    NO_LOCATION_PICKED = "no_location_picked"
}

interface LocationPickerProps {
    location: Location
    setLocation: Dispatch<SetStateAction<Location>>
    viewMode?: CalendarEntryModalViewMode
    calendarEntry?: CalendarEntry
    organizationName?: string
}

// Location Picker has three valid states in regards to its location picker mode, which are a bit weird, so they can comply with the mobile guide
// 1: stand as location (link clickable in mobile guide)
//      -> location object looks like this {locationActionParam: <stand_alias>, locationActionType: "standlink", locationName: <organization> <hall> | <stand>}
// 2: custom location (link not clickable in Mobile Guide)
//      -> location object looks like this {locationActionParam: "", locationActionType: "", locationName: <user input>}
// 3: no location picked
//      -> location object looks like this {locationActionParam: "", locationActionType: "", locationName: ""}
// DynamoDB table CalendarEntry will display empty strings as <empty>, not setting anything or undefined is in these cases also acceptable

const LocationPicker: React.FC<LocationPickerProps> = (props) => {
    const [optionGroups, setOptionGroups] = useState<Array<GroupTypeBase<LocationOption>>>([])
    const [virtualMeetingToggleIsEnabled, setVirtualMeetingToggleIsEnabled] = useState<boolean>(
        props.calendarEntry?.isVirtual === undefined ? false : props.calendarEntry.isVirtual
    )
    const [currentValue, setCurrentValue] = useState<string>("")
    const [currentStandId, setCurrentStandId] = useState<string>("")
    const initialLocationMode = determineInitialLocationMode(props.calendarEntry)
    const [standRadioButtonChecked, setStandRadioButtonChecked] = useState<boolean>(
        initialLocationMode !== LocationPickerMode.CUSTOM
    )
    const [locationOptionsLoaded, setLocationOptionsLoaded] = useState<boolean>(true)
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
    const searchConfig = branding.calendarEntryModalPageContent.locationPickerSearchConfig
    const canEdit = props.viewMode === CalendarEntryModalViewMode.CREATE || props.viewMode === CalendarEntryModalViewMode.EDIT

    function determineInitialLocationMode(calendarEntry?: CalendarEntry): LocationPickerMode {
        if (!calendarEntry) return LocationPickerMode.NO_LOCATION_PICKED
        if (
            (calendarEntry.locationActionParam === undefined ||
                calendarEntry.locationActionParam === null ||
                calendarEntry.locationActionParam === "") &&
            (calendarEntry.locationActionType === undefined ||
                calendarEntry.locationActionType === null ||
                calendarEntry.locationActionType === "") &&
            (calendarEntry.locationName === undefined || calendarEntry.locationName === null || calendarEntry.locationName === "")
        )
            return LocationPickerMode.NO_LOCATION_PICKED
        else if (
            (calendarEntry.locationActionParam === undefined ||
                calendarEntry.locationActionParam === null ||
                calendarEntry.locationActionParam === "") &&
            (calendarEntry.locationActionType === undefined ||
                calendarEntry.locationActionType === null ||
                calendarEntry.locationActionType === "") &&
            calendarEntry.locationName !== undefined &&
            calendarEntry.locationName !== null &&
            calendarEntry.locationName !== ""
        )
            return LocationPickerMode.CUSTOM
        else if (
            calendarEntry.locationActionParam !== undefined &&
            calendarEntry.locationActionParam !== null &&
            calendarEntry.locationActionParam !== "" &&
            calendarEntry.locationActionType === LocationActionType.STANDLINK &&
            calendarEntry.locationName !== undefined &&
            calendarEntry.locationName !== null &&
            calendarEntry.locationName !== ""
        )
            return LocationPickerMode.STAND
        else return LocationPickerMode.NO_LOCATION_PICKED
    }

    function updateDataWithStandLabel(data: LocationOption): LocationOption {
        let updatedData: LocationOption = data

        if (shouldUseHallName(updatedData)) {
            updatedData.label = updatedData.organizationName + " " + updatedData.hallName + " | " + updatedData.standName
            updatedData.value =
                updatedData.organizationName +
                "_" +
                updatedData.hallName +
                "_" +
                updatedData.standAlias +
                "_" +
                updatedData.standName
        } else {
            updatedData.label = updatedData.organizationName + " " + updatedData.standName
            updatedData.value = updatedData.organizationName + "_" + updatedData.standAlias + "_" + updatedData.standName
        }

        return updatedData
    }

    function generateDefaultStandForOrganization() {
        if (!props.organizationName) return

        let tmpOptionGroups: Array<GroupTypeBase<LocationOption>> = []
        setLocationOptionsLoaded(false)
        loadSuggestions(props.organizationName, [searchConfig])
            .then((res) => {
                if (res.suggestGroups.length === 0) return
                res.suggestGroups[0].suggestions.forEach((suggestion) => {
                    const data = updateDataWithStandLabel(suggestion.additionalData as LocationOption)

                    const optionArray: LocationOption[] = [data]
                    let previousGroup: GroupTypeBase<LocationOption> | undefined = tmpOptionGroups.find(
                        (optionGroup: GroupTypeBase<LocationOption>) => optionGroup.label === data.organizationName
                    )
                    if (!previousGroup)
                        tmpOptionGroups.push({
                            label: data.organizationName,
                            options: optionArray
                        })
                    else {
                        previousGroup.options = [...previousGroup.options, ...optionArray]
                    }
                })

                const option = tmpOptionGroups[0]?.options[0]

                if (option) {
                    setCurrentStandId(option.standAlias)
                    props.setLocation({
                        isVirtual: virtualMeetingToggleIsEnabled,
                        standId: option.standAlias,
                        locationName: option.label,
                        locationActionType: LocationActionType.STANDLINK
                    })
                    setCurrentValue(option.label)
                }
            })
            .catch((err) => {
                console.log("Error loading data for location picker in Calendarentrymodal")
            })
            .finally(() => {
                setLocationOptionsLoaded(true)
            })
    }

    function shouldUseHallName(data: LocationOption): boolean {
        if (data.hallName === null || data.hallName === "" || data.hallName === undefined) {
            return false
        }
        if (data.standName.includes(data.hallName)) {
            return false
        }
        if (data.standName.includes("|")) {
            return false
        }
        return true
    }

    useEffect(() => {
        if (currentValue.length > 0) {
            setMenuIsOpen(true)
            props.setLocation({ ...props.location, locationName: currentValue })
        } else {
            // no location is picked
            setMenuIsOpen(false)
            props.setLocation({ ...props.location, locationName: "", locationActionType: LocationActionType.NONE, standId: "" })
        }
        if (!standRadioButtonChecked) return
        if (currentValue.length < 1 || currentValue.includes("|")) {
            setLocationOptionsLoaded(true)
            setOptionGroups([])
        }
        handleSearchDebounced(currentValue)
        // eslint-disable-next-line
    }, [currentValue])

    useEffect(() => {
        if (!props.calendarEntry) {
            generateDefaultStandForOrganization()
        } else {
            // load existing location
            let tmpLocation: Location = {
                isVirtual: props.calendarEntry?.isVirtual === undefined ? false : props.calendarEntry.isVirtual,
                locationActionType:
                    determineInitialLocationMode(props.calendarEntry) === LocationPickerMode.STAND
                        ? LocationActionType.STANDLINK
                        : LocationActionType.CUSTOM
            }
            if (props.calendarEntry.locationName) {
                setCurrentValue(props.calendarEntry.locationName)
                tmpLocation.locationName = props.calendarEntry.locationName
            }
            if (props.calendarEntry.locationActionParam) tmpLocation.standId = props.calendarEntry.locationActionParam
            props.setLocation(tmpLocation)
        }

        // eslint-disable-next-line
    }, [])

    const debouncedSearch = React.useMemo(
        () =>
            debounce((searchStr: string) => {
                if (searchStr.length < 1 || searchStr.includes("|")) {
                    setLocationOptionsLoaded(true)
                    setOptionGroups([])
                    return
                }
                let tmpOptionGroups: Array<GroupTypeBase<LocationOption>> = []
                setLocationOptionsLoaded(false)
                loadSuggestions(searchStr, [searchConfig])
                    .then((res) => {
                        if (res.suggestGroups.length === 0) return
                        res.suggestGroups[0].suggestions.forEach((suggestion) => {
                            // process standregistration-suggestions and push to optiongroups
                            const data = updateDataWithStandLabel(suggestion.additionalData as LocationOption)

                            const optionArray: LocationOption[] = [data]
                            let previousGroup: GroupTypeBase<LocationOption> | undefined = tmpOptionGroups.find(
                                (optionGroup: GroupTypeBase<LocationOption>) => optionGroup.label === data.organizationName
                            )
                            if (!previousGroup)
                                tmpOptionGroups.push({
                                    label: data.organizationName,
                                    options: optionArray
                                })
                            else {
                                previousGroup.options = [...previousGroup.options, ...optionArray]
                            }
                        })
                        setOptionGroups(tmpOptionGroups)
                    })
                    .catch((err) => {
                        console.log("Error loading data for location picker in Calendarentrymodal")
                    })
                    .finally(() => {
                        setLocationOptionsLoaded(true)
                    })
            }, 500),
        // eslint-disable-next-line
        [searchConfig]
    )

    const handleSearchDebounced = useCallback(
        (value: string) => {
            debouncedSearch(value)
        },
        [debouncedSearch]
    )

    function switchToStandMode() {
        if (standRadioButtonChecked) return
        if (currentValue.length > 0) {
            props.setLocation({ ...props.location, locationActionType: LocationActionType.STANDLINK, standId: currentStandId })
        } else {
            // no location picked
            props.setLocation({ ...props.location, locationActionType: LocationActionType.NONE, standId: "", locationName: "" })
        }
        setCurrentValue("")
        setStandRadioButtonChecked(true)
    }

    function switchToCustomMode() {
        if (!standRadioButtonChecked) return
        props.setLocation({ ...props.location, locationActionType: LocationActionType.CUSTOM, standId: "" })
        setCurrentValue("")
        setStandRadioButtonChecked(false)
    }

    const Item = ({ innerRef, innerProps, ...itemProps }: OptionProps<any, false>) => {
        const data = itemProps.data as LocationOption
        const label = shouldUseHallName(data) ? data.hallName + " | " + data.standName : data.standName
        return (
            <>
                <StyledMenuItem isFocused={itemProps.isFocused}>
                    <components.Option innerRef={innerRef} innerProps={innerProps} {...itemProps}>
                        {label}
                    </components.Option>
                </StyledMenuItem>
            </>
        )
    }
    const ItemGroup = (props: GroupProps<LocationOption, false>) => {
        return (
            <>
                <StyledGroup>
                    <StyledLocationIcon>
                        {IconLocation({ fill: branding.sideIconBar.sideIconColorDark, width: "14", height: "20" })}
                    </StyledLocationIcon>
                    <components.Group {...props} />
                </StyledGroup>
            </>
        )
    }

    const CrossIcon = ({ innerRef, innerProps, ...itemProps }: any) => {
        return (
            <>
                {!locationOptionsLoaded && standRadioButtonChecked && (
                    <CenteredLoader loaderWrapperStyle={{ width: "40px" }} size="xs"></CenteredLoader>
                )}
                {currentValue.length > 0 && canEdit && (
                    <StyledCrossIcon
                        ref={innerRef}
                        {...innerProps}
                        onClick={() => {
                            setCurrentValue("")
                            setOptionGroups([])
                        }}
                    >
                        {IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "10", height: "10" })}
                    </StyledCrossIcon>
                )}
            </>
        )
    }

    const filterConfig: Config = {
        stringify: (option: any): string => {
            const data: LocationOption = option.data as LocationOption
            return shouldUseHallName(data)
                ? data.organizationName + " " + data.hallName + " " + data.standName
                : data.organizationName + " " + data.standName
        }
    }

    // implement this function to create a custom filter for displaying option items in the dropdown menu
    // const filterOption = (candidate: { label: string; value: string; data: any }, input: string) => {
    //     return true
    // }
    const locationVisible = canEdit || (initialLocationMode !== LocationPickerMode.NO_LOCATION_PICKED && !canEdit)

    if (!canEdit && !virtualMeetingToggleIsEnabled && !locationVisible) return <></>

    return (
        <>
            <LabelAndRadioButtonsContainer>
                {locationVisible && <Label>{branding.calendarEntryModalPageContent.locationPickerTitle}</Label>}
                {/* radio button: Stand */}
                {canEdit && <StyledRadioButton checked={standRadioButtonChecked} onClick={switchToStandMode}></StyledRadioButton>}
                {canEdit && (
                    <RadioButtonLabel checked={standRadioButtonChecked} onClick={switchToStandMode}>
                        Stand
                    </RadioButtonLabel>
                )}
                {/* radio button: Custom */}
                {canEdit && (
                    <StyledRadioButton checked={!standRadioButtonChecked} onClick={switchToCustomMode}></StyledRadioButton>
                )}
                {canEdit && (
                    <RadioButtonLabel checked={!standRadioButtonChecked} onClick={switchToCustomMode}>
                        {branding.calendarEntryModalPageContent.locationPickerCustomRadioButtonLabel}
                    </RadioButtonLabel>
                )}
            </LabelAndRadioButtonsContainer>
            {/* if Stand-Mode: Stand, Hall or Exhibitor dropdown search */}
            {/* if Custom-Mode: Location Description Input */}
            {locationVisible && (
                <Select
                    // components should not be declared inline: https://react-select.com/components#defining-components
                    components={{
                        DropdownIndicator: () => null, // hide drop down button
                        IndicatorSeparator: () => null,
                        Group: ItemGroup,
                        Option: Item,
                        IndicatorsContainer: CrossIcon
                    }}
                    inputValue={currentValue}
                    value={{
                        label: standRadioButtonChecked
                            ? branding.calendarEntryModalPageContent.locationPickerPlaceholderStand
                            : branding.calendarEntryModalPageContent.locationPickerPlaceholderCustom
                    }} // we use single value as placeholder, see below *
                    isClearable={true}
                    isDisabled={!canEdit}
                    // openMenuOnClick={standRadioButtonChecked}
                    menuIsOpen={standRadioButtonChecked && menuIsOpen}
                    noOptionsMessage={(obj) => {
                        return null
                    }}
                    options={optionGroups}
                    filterOption={createFilter(filterConfig)}
                    // filterOption={filterOption}
                    onFocus={(event) => {
                        setMenuIsOpen(true)
                    }}
                    onBlur={(event) => {
                        setMenuIsOpen(false)
                    }}
                    // type in stuff
                    onInputChange={(inputValue: string, { action }: InputActionMeta) => {
                        if (action === "input-change") {
                            setCurrentValue(inputValue)
                            setLocationOptionsLoaded(false)
                        }
                    }}
                    // select an option from dropdown
                    onChange={(value: ValueType<LocationOption, boolean>, { action }: ActionMeta<OptionTypeBase>) => {
                        if (value !== null && action === "select-option") {
                            setMenuIsOpen(false)
                            const option = value as LocationOption
                            setCurrentStandId(option.standAlias)
                            props.setLocation({
                                isVirtual: virtualMeetingToggleIsEnabled,
                                standId: option.standAlias,
                                locationName: option.label,
                                locationActionType: LocationActionType.STANDLINK
                            })
                            setCurrentValue(option.label)
                        }
                    }}
                    styles={{
                        control: () => ({
                            display: "flex",
                            height: "27px",
                            fontFamily: branding.font1,
                            width: "100%",
                            borderBottom: "1px solid #D9D9D9",
                            outline: "none"
                        }),
                        valueContainer: () => ({
                            paddingLeft: "0",
                            paddingRight: locationOptionsLoaded ? "10px" : "",
                            width: "100%"
                        }),
                        input: () => ({
                            marginLeft: "0",
                            width: "100% !important",
                            div: {
                                width: "100% !important"
                            },
                            input: {
                                opacity: "1 !important", // workaround for bug that makes input disappear
                                width: "100% !important"
                            }
                        }),
                        menu: (provided) => ({
                            ...provided,
                            border: `1px solid ${branding.sideIconBar.sideIconColorDark}`,
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                            fontFamily: branding.font1,
                            borderRadius: "5px",
                            marginTop: "0px",
                            marginBottom: "0px"
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            overflowX: "hidden"
                        }),
                        singleValue: (provided) => ({
                            // we use single value as placeholder, see above
                            ...provided,
                            fontFamily: branding.font1,
                            fontSize: "16px",
                            marginLeft: "0",
                            color: "#727272",
                            paddingBottom: "3px"
                        }),
                        groupHeading: () => ({
                            paddingLeft: "4px",
                            fontWeight: "bold",
                            fontSize: "12px",
                            lineHeight: "17px",
                            height: "13px",
                            fontFamily: branding.font1,
                            whiteSpace: "nowrap"
                        })
                    }}
                ></Select>
            )}
            {/* Virtual Meeting Toggle */}
            {!canEdit && virtualMeetingToggleIsEnabled && (
                <VirtualMeetingLabelViewMode>
                    {branding.calendarEntryModalPageContent.locationPickerVirtualMeetingLabel}
                </VirtualMeetingLabelViewMode>
            )}
            {canEdit && (
                <ToggleSwitchVirtualMeetingRoot>
                    <VirtualMeetingLabelCreateOrEditMode>
                        {branding.calendarEntryModalPageContent.locationPickerVirtualMeetingLabel}
                    </VirtualMeetingLabelCreateOrEditMode>
                    <ToggleSwitch
                        isEnabled={virtualMeetingToggleIsEnabled}
                        onClick={() => {
                            props.setLocation({ ...props.location, isVirtual: !virtualMeetingToggleIsEnabled })
                            setVirtualMeetingToggleIsEnabled(!virtualMeetingToggleIsEnabled)
                        }}
                    />
                </ToggleSwitchVirtualMeetingRoot>
            )}
        </>
    )
}

const StyledGroup = styled.div`
    display: flex;
    flex-direction: row;
    font-family: ${branding.font1};
    margin: "4px 10px 25px 10px";
`

const StyledMenuItem = styled.div<{ isFocused: boolean }>`
    font-size: 12px;
    line-height: 17px;
    height: 13px;
    font-family: ${branding.font1};
    white-space: nowrap;

    & > div {
        padding: 0px 0 0px 4px !important;
        margin: 5px 10px 5px 0px;
        background-color: ${(props) => (props.isFocused ? "#D9D9D9" : "white")};
        width: 20000px;
        cursor: pointer;
    }
`

const StyledLocationIcon = styled.div`
    margin-right: 18px;
    margin-left: 17px;
    padding-top: 6px;
    z-index: 1;
`

const StyledCrossIcon = styled.div`
    cursor: pointer;
    stroke: #727272;
    stroke-width: 2px;
`

// custom radio button just to change color (-_-)
const StyledRadioButton = styled.div<{ checked: boolean }>`
    margin-top: 2px;
    margin-left: 32px;
    margin-right: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: ${branding.calendarEntryModalPageContent.locationPickerRadioButtonColor};
    ${(props) =>
        props.checked
            ? "background: radial-gradient(" +
              branding.calendarEntryModalPageContent.locationPickerRadioButtonColor +
              " 0%, " +
              branding.calendarEntryModalPageContent.locationPickerRadioButtonColor +
              " 40%, transparent 50%, transparent);"
            : ""};
    ${(props) => (props.checked ? "" : "cursor: pointer;")};
    border-color: ${(props) =>
        props.checked ? branding.calendarEntryModalPageContent.locationPickerRadioButtonColor : "#727272"};
`

const LabelAndRadioButtonsContainer = styled.div`
    margin-top: 35px;
    display: flex;
    margin-bottom: 8px;
`

const RadioButtonLabel = styled.span<{ checked: boolean }>`
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 17px;
    height: 13px;
    font-family: ${branding.font1};
    white-space: nowrap;
    color: #727272;
    ${(props) => (props.checked ? "" : "cursor: pointer;")};
`

const ToggleSwitchVirtualMeetingRoot = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;

    & .switch {
        transform: scale(0.65);
    }

    & ${ToggleSwitchRoot} {
        width: 36px;
    }
`
const VirtualMeetingLabelCreateOrEditMode = styled.div`
    margin-right: 1px;
`

const VirtualMeetingLabelViewMode = styled.div`
    margin-top: 6px;
    color: ${branding.calendarEntryModalPageContent.locationPickerLabelColor};
`
// end Location Picker

const AvailabilityButton = styled.button`
    display: flex;
    flex-flow: row;
    width: 20%;
    height: 36px;
    border-radius: 20px;
    font-weight: bold;
    margin: 5px;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid ${branding.calendarEntryModalPageContent.calendarTheme};
    font-size: 12px;
    background-color: ${branding.calendarEntryModalPageContent.calendarTheme};
    color: #fff;

    @media ${device.mobile} {
        display: none;
    }
`

export interface Obligations {
    userId: string
    userBusyTimes: UserBusyTimes
    eventDates: EventDate[]
}
interface AvailableUsersProps {
    contactsInvited: InvitePerson[]
    timezone: string
    selectedDate?: Date
    startTime?: Date
    endTime?: Date
    editMode?: boolean
    close: () => void
}

export function dateWithUTCIncrasedByTimezone(timeZone: string, date?: Date, dateYMD?: Date) {
    if (date && timeZone) {
        const dateMoment = moment(date).tz(timeZone)
        dateMoment.set("year", dateYMD ? dateYMD.getFullYear() : date.getFullYear())
        dateMoment.set("month", dateYMD ? dateYMD.getMonth() : date.getMonth())
        dateMoment.set("date", dateYMD ? dateYMD.getDate() : date.getDate())
        dateMoment.set("hour", date.getHours())
        dateMoment.set("minute", date.getMinutes())
        return dateMoment.toDate()
    }
}

const AvailableUsers: React.FC<AvailableUsersProps> = React.memo((props) => {
    const [meetings, setMeetings] = useState<Obligations[]>([])
    const [loaded, setIsLoaded] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const wrapperRefIcons = useRef<HTMLDivElement>(null)
    const scheduleContainerRef = useRef<HTMLDivElement>(null)
    const currentTimeAreaRef = useRef<HTMLDivElement>(null)
    const appTimezone = useAppState().timezone
    const startTimeOfDay = dateModification(props.selectedDate!)
    const endTimeOfDay = new Date(startTimeOfDay!.getTime() + 24 * 60 * 60 * 1000)

    const iconSize = 30
    const iconMarginLeft = 10
    const scheduleTimes = totalTimes()
    const pxPerMinute = 45 / 60
    const pageSize = 7

    function dateModification(selectedDate: Date) {
        selectedDate.setHours(0)
        selectedDate.setMinutes(0)
        selectedDate.setSeconds(0)
        const modificatedDate = dateWithUTCIncrasedByTimezone(props.timezone, selectedDate)
        return modificatedDate
    }

    function hasNext() {
        if ((page + 1) * pageSize < props.contactsInvited.length) {
            return true
        } else return false
    }

    function onLeftArrowClick() {
        if (page > 0) setPage(page - 1)
    }

    function onRightArrowClick() {
        if (hasNext()) setPage(page + 1)
    }

    useEffect(() => {
        async function loadObligations() {
            if (isDateInValid(props.selectedDate ?? null, props.startTime ?? null, props.endTime ?? null)) {
                setIsLoaded(true)
                return
            }
            const meetings: Obligations[] = []
            let nextToken
            for await (let contact of props.contactsInvited) {
                const resp = await getUserBusyTimes(contact.id, startTimeOfDay!, endTimeOfDay, nextToken)
                const eventDatesTimezoneTransformed =
                    contact.eventDates?.map((ed) => {
                        ed.dateTimeStart = moment.tz(moment(ed.dateTimeStart).format(), appTimezone).format() //give timezone of app to start and time which is recieved from api call that has app timezone then parsed to calendar timezone
                        ed.dateTimeEnd = moment.tz(moment(ed.dateTimeEnd).format(), appTimezone).format()
                        return ed
                    }) ?? []
                meetings.push({
                    userId: contact.id,
                    userBusyTimes: (resp as UserBusyTimes) || [],
                    eventDates: eventDatesTimezoneTransformed
                })
            }
            setMeetings(meetings)
            setIsLoaded(true)
        }

        if (wrapperRefIcons && scheduleContainerRef && wrapperRefIcons.current && scheduleContainerRef.current) {
            wrapperRefIcons.current.scrollLeft = 0
            scheduleContainerRef.current.scrollLeft = 0
        }
        loadObligations()
        setPage(0)
    }, [props.contactsInvited, props.selectedDate, props.timezone]) //eslint-disable-line

    console.log("load obligations", meetings)

    useEffect(() => {
        if (currentTimeAreaRef && currentTimeAreaRef.current)
            currentTimeAreaRef.current.scrollIntoView({ block: "start", behavior: "smooth" })
    }, [props.startTime, props.endTime, loaded])

    function totalTimes(startHour?: number) {
        var times = []
        for (var i = startHour ?? 0; i < 25; i++) {
            const date = new Date()
            date.setHours(i)
            date.setMinutes(0)
            times.push(date)
        }
        return times
    }

    function calculateTop(startDate: Date, endDate: Date) {
        if (startDate < startTimeOfDay! && endDate > startTimeOfDay!) {
            // start in previous day,ends in current. So start from bottom
            return 0
        } else {
            var top = ((startDate.getTime() - startTimeOfDay!.getTime()) / (1000 * 60)) * pxPerMinute
            return top
        }
    }

    function calculateTopCurrentTimeAreaAndScheduleTime(startDate: Date) {
        return (startDate.getHours() * 60 + startDate.getMinutes()) * pxPerMinute // add +12 because of border width and margin top of schedule container
    }

    function calculateHeightCurrentTimeArea(dateStart: Date, dateEnd: Date) {
        return (
            (dateEnd.getHours() * 60 + dateEnd.getMinutes() - (dateStart.getHours() * 60 + dateStart.getMinutes())) * pxPerMinute
        )
    }

    function calculateHeight(dateStart: Date, dateEnd: Date) {
        if (dateStart < startTimeOfDay! && dateEnd > startTimeOfDay!) {
            return ((dateEnd.getTime() - startTimeOfDay!.getTime()) / (1000 * 60)) * pxPerMinute
        } else if (dateEnd > endTimeOfDay && dateStart <= endTimeOfDay) {
            return 1440 * pxPerMinute - calculateTop(dateStart, dateEnd)
        } else {
            var height = ((dateEnd.getTime() - dateStart.getTime()) / (1000 * 60)) * pxPerMinute
            if (height + calculateTop(dateStart, dateEnd) > 1440 * pxPerMinute) {
                height = 1440 * pxPerMinute - calculateTop(dateStart, dateEnd) //if height overlap
            }
            return height
        }
    }

    if (!loaded) {
        return <CenteredLoader loaderWrapperStyle={{ top: "400px" }}></CenteredLoader>
    }

    return (
        <AvailableUsersRoot>
            <CloseArrowIcon onClick={() => props.close()}>{IconArrowLeft({ fill: branding.mainInfoColor })}</CloseArrowIcon>
            <ParticipantsText>
                <TitleText>{branding.calendarEntryModalPageContent.availabilityTitle}</TitleText>
                <TimezoneText>{findTimezoneName(props.timezone)}</TimezoneText>
            </ParticipantsText>
            <ParticipantsNavigation>
                <ArrowIconLeft page={page} onClick={() => onLeftArrowClick()}>
                    {IconChevronLeftSolid({
                        width: "15",
                        height: "15",
                        fill: branding.calendarEntryModalPageContent.calendarTheme
                    })}
                </ArrowIconLeft>
                <ParticipantsIconsScroller ref={wrapperRefIcons}>
                    <ParticipantsIcons width={`${props.contactsInvited.length * (iconSize + iconMarginLeft)}px`}>
                        {props.contactsInvited.slice(page * pageSize, (page + 1) * pageSize).map((contact) => {
                            return (
                                <ParticipantIcon
                                    marginLeft={iconMarginLeft}
                                    size={30}
                                    alt={contact.firstName + " " + contact?.lastName}
                                    src={contact?.logoUrl}
                                    backgroundSize="cover"
                                ></ParticipantIcon>
                            )
                        })}
                    </ParticipantsIcons>
                </ParticipantsIconsScroller>
                <ArrowIconRight hasNext={hasNext()} onClick={() => onRightArrowClick()}>
                    {IconChevronRightSolid({
                        width: "15",
                        height: "15",
                        fill: branding.calendarEntryModalPageContent.calendarTheme
                    })}
                </ArrowIconRight>
            </ParticipantsNavigation>
            <ScheduleContainer ref={scheduleContainerRef}>
                <CurrentTimeArea
                    ref={currentTimeAreaRef}
                    top={calculateTopCurrentTimeAreaAndScheduleTime(moment(props.startTime).toDate())}
                    height={calculateHeightCurrentTimeArea(moment(props.startTime).toDate(), moment(props.endTime).toDate())}
                />
                <ScheduleTimeColumnWrapper width={props.contactsInvited.length * (iconSize + iconMarginLeft) + 38}>
                    <ScheduleTimeColumn>
                        {scheduleTimes.map((time) => {
                            return (
                                <ScheduleTime top={calculateTopCurrentTimeAreaAndScheduleTime(time)}>
                                    {moment(time).format("HH:mm")}
                                </ScheduleTime>
                            )
                        })}
                    </ScheduleTimeColumn>

                    {meetings.slice(page * pageSize, (page + 1) * pageSize).map((meeting) => {
                        return (
                            <ParticipantTimeColumn width={iconSize} marginLeft={iconMarginLeft}>
                                {meeting.userBusyTimes.items &&
                                    meeting.userBusyTimes.items.map((item, index) => {
                                        return (
                                            <ParticipantMeetingTime
                                                key={`m-${index}`}
                                                top={calculateTop(moment(item.start).toDate(), moment(item.end).toDate())}
                                                height={calculateHeight(moment(item.start).toDate(), moment(item.end).toDate())}
                                            ></ParticipantMeetingTime>
                                        )
                                    })}
                                {branding.calendarEntryModalPageContent.showEventDatesAvailability &&
                                    meeting.eventDates &&
                                    meeting.eventDates.map((item, index) => {
                                        return (
                                            <ParticipantMeetingTime
                                                key={index}
                                                top={calculateTop(
                                                    momentWithoutTimezoneFromTimezonedMoment(
                                                        moment(item.dateTimeStart),
                                                        props.timezone
                                                    ).toDate(),
                                                    momentWithoutTimezoneFromTimezonedMoment(
                                                        moment(item.dateTimeEnd),
                                                        props.timezone
                                                    ).toDate()
                                                )}
                                                height={calculateHeight(
                                                    momentWithoutTimezoneFromTimezonedMoment(
                                                        moment(item.dateTimeStart),
                                                        props.timezone
                                                    ).toDate(),
                                                    momentWithoutTimezoneFromTimezonedMoment(
                                                        moment(item.dateTimeEnd),
                                                        props.timezone
                                                    ).toDate()
                                                )}
                                            ></ParticipantMeetingTime>
                                        )
                                    })}
                            </ParticipantTimeColumn>
                        )
                    })}
                </ScheduleTimeColumnWrapper>
            </ScheduleContainer>
            <LegendContainer>
                <BusyLegend />
                <BusyLegendText>{branding.calendarEntryModalPageContent.busyLegendText}</BusyLegendText>
            </LegendContainer>
        </AvailableUsersRoot>
    )
})

const ParticipantTimeColumn = styled.div<{ width: number; marginLeft: number }>`
    position: relative;
    width: ${(props) => `${props.width}px`};
    margin-top: 12.5px;
    margin-left: ${(props) => `${props.marginLeft}px`};
    border: 3px solid black;
`

const ScheduleContainer = styled.div`
    height: 450px;
    width: 395px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    position: relative;
    flex-flow: row;
    padding: 0 10px;
    margin-top: 20px;
`

const ScheduleTimeColumnWrapper = styled.div<{ width: number }>`
    width: ${(props) => `${props.width}px`};
    display: flex;
`

const ScheduleTimeColumn = styled.div`
    position: relative;
    width: 38px;
`

const ScheduleTime = styled.div<{ top: number }>`
    position: absolute;
    top: ${(props) => `${props.top}px`};
`
const ParticipantMeetingTime = styled.div<{ top: number; height: number }>`
    position: absolute;
    background-color: #d1d1d1;
    height: ${(props) => `${props.height}px`};
    width: 100%;
    top: ${(props) => `${props.top}px`};

    border: 2px solid red;
`

const AvailableUsersRoot = styled.div`
    flex: 1;
    display: flex;
    flex-flow: column;
    border-left: 1px solid black;
    padding: 8px 18px 0;
`
const CloseArrowIcon = styled.div`
    display: flex;
    flex-flow: row;
`
const ArrowIconLeft = styled.div<{ page: number }>`
    display: flex;
    flex-flow: row;
    width: 58px;
    align-items: center;
    opacity: ${(props) => (props.page > 0 ? 1 : 0.5)};
`
const ArrowIconRight = styled.div<{ hasNext: boolean }>`
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 58px;
    align-items: center;
    opacity: ${(props) => (props.hasNext ? 1 : 0.5)};
`

const ParticipantsText = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 17px;
`
const TitleText = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    font-family: ${branding.font1};
    font-style: normal;
`

const TimezoneText = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 7px;
    width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const ParticipantsNavigation = styled.div`
    display: flex;
    flex-flow: row;
    margin-top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    font-family: ${branding.font1};
`

const ParticipantsIconsScroller = styled.div`
    overflow-x: auto;
    width: 280px;
`
const ParticipantsIcons = styled.div<{ width: string }>`
    display: flex;
    width: ${(props) => props.width ?? "400px"};
`
const ParticipantIcon = styled(AvatarWithDefault)<{ marginLeft?: number }>`
    margin-left: ${(props) => `${props.marginLeft}px`};
    :first-child {
        margin-left: 0;
    }
`
const LegendContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    margin-top: 25px;
`

const BusyLegend = styled.div`
    background-color: #d1d1d1;
    border-left: 1px solid black;
    width: 35px;
    height: 15px;
`

const BusyLegendText = styled.div`
    display: flex;
    flex-flow: row;
    margin-left: 18px;
    line-height: 17px;
    font-size: 10px;
    font-weight: bold;
`

const CurrentTimeArea = styled.div<{ top: number; height: number }>`
    position: absolute;
    width: 100%;
    z-index: 3;
    height: ${(props) => `${props.height}px`};
    top: ${(props) => `${props.top + 12}px`};
    background-color: transparent;
    border: 2px solid ${branding.calendarEntryModalPageContent.currentTimeAreaTheme};
    left: 0;
`

const ValidationIconRoot = styled.div`
    display: block;
    width: 15px;
    z-index: 8;

    @media ${device.mobile} {
        z-index: 0;
    }
`
const CustomTooltip = styled(Tooltip)<{ inset?: string }>`
    inset: ${(props) => (props.inset ? `${props.inset} !important` : "")};
`

const ValidationIcon: React.FC<{
    id: string
    tooltipText: string
    valid: boolean
    focused?: boolean
    inset?: string
    alwaysShow?: boolean
}> = (props) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    return (
        <ValidationIconRoot
            className={`${props.id}-root`}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {
                <CustomOverlayTrigger
                    show={showTooltip && !props.valid}
                    overlay={
                        <CustomTooltip inset={props.inset} id={props.id} style={{ fontFamily: branding.font1 }}>
                            {props.tooltipText}
                        </CustomTooltip>
                    }
                >
                    <>
                        {(props.focused || props.alwaysShow) && (
                            <div>
                                {props.valid
                                    ? IconCheckmarkGreen({ width: "14", height: "14" })
                                    : IconCloseRed({ width: "12", height: "12" })}
                            </div>
                        )}
                    </>
                </CustomOverlayTrigger>
            }
        </ValidationIconRoot>
    )
}

interface Closable {
    close: () => void
}
export interface CalendarEntryModalProps {
    sotUser?: InvitePerson[]
    organizationId?: string
    organizationName?: string
    calendarEntry?: CalendarEntry
    viewMode?: CalendarEntryModalViewMode
    predefinedTitle?: string
    predefinedStartTime?: Date
    availableTimeSlots?: Date[]
    meetingSlotsTable?: MeetingSlotsDay[]
    timeSlotInterval?: number
    onUpdated?: (calendarEntry: CalendarEntry) => void
    onRemovedFromList?: () => void
}
const CalendarEntryModal = (props: CalendarEntryModalProps & Closable) => {
    const TIME_INTERVAL = props.timeSlotInterval ?? 15
    const participantLimit = branding.configuration.calendarEntryParticipantLimit
    const userlink = useLoggedInState()
    const languageState = useLanguageState()
    const language = languageState.getLanguage()
    const appState = useAppState()
    const [joinMeetingDisabled, setJoinMeetingDisabled] = useState(false)
    const [title, setTitle] = useState(props.predefinedTitle ?? "")
    const [active, setActive] = useState(false)
    // const [title, setTitle] = useState("")
    const currentDateEnd = new Date()
    const currentDate = new Date()
    currentDate.setMinutes(currentDate.getMinutes() + 30)
    const [start, setStart] = useState<Date | null>(props.predefinedStartTime ? moment(props.predefinedStartTime).toDate() : null)
    currentDateEnd.setMinutes(currentDateEnd.getMinutes() + 60)
    const [end, setEnd] = useState<Date | null>(start ? moment(props.predefinedStartTime).add(TIME_INTERVAL, "m").toDate() : null)
    const [day, setDay] = useState<Date | null>(props.predefinedStartTime ? moment(props.predefinedStartTime).toDate() : null)
    const [availableStartTimes, setAvailableStartTimes] = useState<TimeSlotType[]>([])
    const [availableEndTimes, setAvailableEndTimes] = useState<TimeSlotType[]>([])
    const [initialDatePickerDay, setInitialDatePickerDay] = useState<Date | null>(null)
    const [notes, setNotes] = useState("")
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showDeclineModal, setShowDeclineModal] = useState(false)
    const chimeContext = useChimeContext()
    const [closeModal, setCloseModal] = useState<boolean>(false)
    const [selectedTimezone, setSelectedTimezone] = useState(appState.timezone)
    const [openDatepicker, setOpenDatepicker] = useState(false)
    const [openTimezonePicker, setOpenTimezonePicker] = useState<boolean>(false)
    const [showAvailable, setShowAvailable] = useState(false)
    const [titleFocused, setTitleFocused] = useState<boolean>(false)
    const [dayFocused, setDayFocused] = useState<boolean>(false)
    const [startTimeFocused, setStartTimeFocused] = useState<boolean>(false)
    const [endTimeFocused, setEndTimeFocused] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalMessage, setErrorModalMessage] = useState<string>(branding.calendarEntryModalPageContent.fieldError)
    const meetingDates = branding.eventTiming.meetingDays
    const [location, setLocation] = useState<Location>({ isVirtual: false })
    // eslint-disable-next-line
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const history = useHistory()

    const canEdit = props.viewMode === CalendarEntryModalViewMode.CREATE || props.viewMode === CalendarEntryModalViewMode.EDIT
    const user = userlink.user()
    const profileId = user?.profileId
    const organizer: InvitePerson = {
        id: profileId ?? "",
        firstName: user?.firstName ?? "",
        lastName: user?.lastName,
        logoUrl: user?.logoUrl
    }

    const org = props.calendarEntry ? props.calendarEntry.organizationId : props.organizationId

    const myParticipation = props.calendarEntry?.participants.items.find((participation) => profileId === participation.userId)

    const myParticipationStatus = props.calendarEntry?.participants.items.find(
        (participation) => profileId === participation.userId
    )?.status

    const invited = props.sotUser || []
    if (!invited.find((item) => item.id === organizer.id)) {
        invited.unshift(organizer)
    }

    useEffect(() => {
        if (props.organizationId && props.organizationName) {
            let generatedTitle = branding.organizationDetailPageContent.meetingRequestModalTitlePlaceholder
            let participantName = `${user?.firstName} ${user?.lastName}`
            let organizationName = props?.organizationName

            setTitle(
                generatedTitle.replace("{$organizationName}", organizationName).replace("{$participantName}", participantName)
            )
        }
        // eslint-disable-next-line
    }, [])

    const setAvailableEndTimesHelper = () => {
        if (props.meetingSlotsTable && props.meetingSlotsTable.length > 0) {
            const currentDaySlots = props.meetingSlotsTable?.filter((d) => moment(d.date).isSame(day, "date"))[0]?.slots
            if (start && currentDaySlots?.length) {
                for (let i = 0; i < currentDaySlots.length - 1; i++) {
                    const firstSlot = currentDaySlots[i].value
                    const secondSlot = currentDaySlots[i + 1].value
                    const currentTime = moment(firstSlot)
                        .set("hours", start?.getHours())
                        .set("minutes", start?.getMinutes())
                    if (isSlotDisabled(currentTime, firstSlot, secondSlot)) {
                        const indexJ = currentDaySlots.findIndex((slot, current) => {
                            if (current > i) return slot.disabled
                            return false
                        })
                        const j = indexJ !== -1 ? indexJ + 1 : currentDaySlots.length
                        setAvailableEndTimes(currentDaySlots.slice(i, j))
                        return
                    }
                }
            }
        }
    }

    useEffect(() => {
        setAvailableEndTimesHelper()
    }, [start]) // eslint-disable-line

    useEffect(() => {
        if (props.meetingSlotsTable) {
            const availableTimes =
                props.meetingSlotsTable
                    ?.filter((d) => moment(d.date).isSame(day, "date"))[0]
                    ?.slots.filter((slot) => !slot.disabled) ?? []
            setAvailableStartTimes(availableTimes)
            setAvailableEndTimesHelper()
        }
    }, [props.meetingSlotsTable, day]) // eslint-disable-line

    const [contactsInvited, setContactsInvited] = useState<InvitePerson[]>(invited)
    useEffect(() => {
        if (closeModal) {
            onCloseModalView()
        }
        // eslint-disable-next-line
    }, [closeModal])

    useEffect(() => {
        const days = meetingDates.map((date) => moment(date))
        const indexOfDayToDisplay = getIndexOfInitialDayToShow(days)
        setInitialDatePickerDay(days[indexOfDayToDisplay].toDate())
    }, [meetingDates])

    useEffect(() => {
        const calendarEntry = props.calendarEntry
        if (calendarEntry && props.viewMode !== CalendarEntryModalViewMode.CREATE) {
            setStart(momentWithoutTimezoneFromTimezonedMoment(moment(props.calendarEntry!.start), appState.timezone).toDate())
            setEnd(momentWithoutTimezoneFromTimezonedMoment(moment(props.calendarEntry!.end), appState.timezone).toDate())
            setDay(momentWithoutTimezoneFromTimezonedMoment(moment(props.calendarEntry!.start), appState.timezone).toDate())

            setStart(moment(calendarEntry.start).toDate())
            setEnd(moment(calendarEntry.end).toDate())
            setDay(moment(calendarEntry.start).toDate())
            setTitle(calendarEntry.title)
            const participations = calendarEntry.participants.items
            // Shift organizer participation to first index
            participations.unshift(
                participations.splice(
                    participations.findIndex((participation) => participation.userId === calendarEntry.userId),
                    1
                )[0]
            )
            setContactsInvited(
                participations.map((participation) => {
                    return {
                        logoUrl: participation.user.pictureUrl,
                        id: participation.user.id,
                        firstName: participation.user.name,
                        status: participation.status
                    }
                })
            )
            if (calendarEntry.description) {
                setNotes(calendarEntry.description)
            }
        }

        // eslint-disable-next-line
    }, [props.calendarEntry, props.viewMode])

    function onCloseModalView() {
        if (profileId === undefined) {
            return
        }
        if (props.viewMode === CalendarEntryModalViewMode.CREATE) {
            setContactsInvited(props.sotUser ? [organizer].concat(props.sotUser) : [organizer])
            setNotes("")
            setTitle("")
            if (props.calendarEntry) {
                setStart(moment(props.calendarEntry.start).toDate())
                setEnd(moment(props.calendarEntry.end).toDate())
                setDay(moment(props.calendarEntry.start).toDate())
            } else {
                resetTimes()
                setDay(new Date())
            }
        }
        props.close()
    }

    useEffect(() => {
        if (!isValidDay(day!)) {
            setJoinMeetingDisabled(true)
        } else {
            setJoinMeetingDisabled(false)
        }
    }, [day])

    function focusAllValidationIcons() {
        setTitleFocused(true)
        setEndTimeFocused(true)
        setStartTimeFocused(true)
        setDayFocused(true)
    }

    function findMyParticipationStatus() {
        if (props.calendarEntry && props.calendarEntry.participants && props.calendarEntry.participants.items) {
            return props.calendarEntry.participants.items.find((participant) => participant.userId === userlink.user()!.profileId)
                ?.status
        }
    }

    function chatWithParticipants() {
        onChatWithParticipants()
    }

    function onChatWithParticipants() {
        appState.setChatsSubtab(
            ChatConversationParam.conversationByConversationId(ConversationType.CALENDARENTRY, props.calendarEntry!.id)
        )
        props.close()
    }

    function joinMeeting() {
        onCallParticipants()
    }

    function exportMeeting() {
        if (props.calendarEntry) saveMeetings([props.calendarEntry])
    }

    async function onCallParticipants() {
        if (props.calendarEntry) {
            setCloseModal(true)
            if (branding.configuration.useConferenceRoomV2) {
                history.push(`/meetingV2/ce_${props.calendarEntry.id}`)
            } else {
                chimeContext.createOrJoinMeeting(props.calendarEntry.id, "calenderEntry")
            }
        }
    }

    async function createOrUpdateCalendarEntry(timezone: string) {
        if (isDateInValid(day, start, end) || title.length === 0 || !isTimeValid(start) || !isTimeValid(end, true)) {
            setShowErrorModal(true)
            setErrorModalMessage(branding.calendarEntryModalPageContent.fieldError)
            focusAllValidationIcons()
            return
        }
        if (profileId != null && start != null && end != null && day != null) {
            const startDateMoment = moment(day).tz(timezone)
            startDateMoment.set("year", day.getFullYear())
            startDateMoment.set("month", day.getMonth())
            startDateMoment.set("date", day.getDate())
            startDateMoment.set("hour", start.getHours())
            startDateMoment.set("minute", start.getMinutes())

            const endDateMoment = moment(day).tz(timezone)
            endDateMoment.set("year", day.getFullYear())
            endDateMoment.set("month", day.getMonth())
            endDateMoment.set("date", day.getDate())
            endDateMoment.set("hour", end.getHours())
            endDateMoment.set("minute", end.getMinutes())

            const startDate = startDateMoment.toDate()
            const endDate = endDateMoment.toDate()

            var result: CalendarEntry | null = null
            const calendarEntry = props.calendarEntry
            const participantsId = contactsInvited.map((contact) => {
                return contact.id
            })
            if (props.viewMode === CalendarEntryModalViewMode.CREATE && contactsInvited != null) {
                result = await createNewCalendarEntry(
                    profileId,
                    participantsId,
                    title,
                    startDate,
                    endDate,
                    location.isVirtual,
                    notes,
                    org,
                    location.locationName,
                    location.locationActionType ? location.locationActionType.toString() : "",
                    location.standId
                )
                // result = await createNewCalendarEntry(profileId, participantsId, title, startDate, endDate, notes, org)
                // TODO create groupchat conversation
            } else if (props.viewMode === CalendarEntryModalViewMode.EDIT && calendarEntry != null) {
                await Promise.all(
                    calendarEntry.participants.items.map(async (oldParticipation) => {
                        // Delete removed participations
                        if (!participantsId.includes(oldParticipation.userId)) {
                            await deleteCalendarEntryParticipationById(oldParticipation.id)
                        } else {
                            // Update existing participation when startDate, endDate or title was changed
                            if (
                                calendarEntry.start !== startDate.toISOString() ||
                                calendarEntry.end !== endDate.toISOString() ||
                                calendarEntry.title !== title
                            ) {
                                await updateCalendarEntryParticipationStatus(
                                    oldParticipation.id,
                                    oldParticipation.userId,
                                    startDate
                                )
                            }
                        }
                    })
                )

                const oldParticipantIds = calendarEntry.participants.items.map((participation) => {
                    return participation.userId
                })
                await Promise.all(
                    participantsId.map(async (participantId) => {
                        // Create new participations
                        if (!oldParticipantIds.includes(participantId)) {
                            await createNewCalendarEntryParticipation(
                                calendarEntry.id,
                                participantId,
                                CalendarEntryParticipationStatus.REQUESTED,
                                startDate
                            )
                        }
                    })
                )

                result = await updateCalendarEntryById(
                    calendarEntry,
                    title,
                    startDate,
                    endDate,
                    location.isVirtual,
                    notes,
                    location.locationName,
                    location.locationActionType ? location.locationActionType.toString() : "",
                    location.standId
                )
            }

            if (result != null) {
                if (props.viewMode === CalendarEntryModalViewMode.CREATE && props.organizationId) {
                    trackVisit(profileId, props.organizationId, "CALENDARENTRY#SENT")
                }
                onCloseModalView()
                if (props.onUpdated) {
                    props.onUpdated(result)
                }
            } else {
                // TODO ERROR WHAT TO SHOW HERE?
            }
        }
    }

    async function deleteCalendarEntry() {
        setShowCancelModal(false)
        if (profileId != null && props.calendarEntry?.id != null) {
            const result = await deleteCalendarEntryForUser(profileId, props.calendarEntry.id)

            if (result != null) {
                onCloseModalView()
                if (props.onRemovedFromList) {
                    props.onRemovedFromList()
                }
            } else {
                // TODO ERROR WHAT TO SHOW HERE?
            }
        }
    }

    async function declineMeeting() {
        setShowDeclineModal(false)
        if (profileId != null && props.calendarEntry?.id != null) {
            if (myParticipation) {
                const result = await updateCalendarEntryParticipationStatus(
                    myParticipation.id,
                    profileId,
                    undefined,
                    CalendarEntryParticipationStatus.DECLINED
                )

                if (result != null) {
                    onCloseModalView()
                    if (props.onRemovedFromList) {
                        props.onRemovedFromList()
                    }
                } else {
                    // TODO ERROR WHAT TO SHOW HERE?
                }
            }
        }

        props.close()
    }

    function onStartSelected(time: Date) {
        if (time) {
            const startTemp = moment(day ?? undefined)
                .set("hours", time.getHours())
                .set("minutes", time.getMinutes())
            setStart(startTemp.toDate())
            setEnd(startTemp.add(TIME_INTERVAL, "m").toDate())
        } else {
            setStart(null)
        }
    }

    function onEndSelected(time: Date) {
        if (time) {
            const endTemp = moment(day ?? undefined)
                .set("hours", time.getHours())
                .set("minutes", time.getMinutes())
                .toDate()
            setEnd(endTemp)
        }
    }

    const dayFormat = branding.eventTiming.eventDaysFormatPatternShort

    const isTimeSlotAvailable = (time: Date | null, isEndTime?: boolean) => {
        // end time is valid if it's the same or before last available end time of the current user
        if (isEndTime) {
            return moment(time).isSameOrBefore(availableEndTimes[availableEndTimes.length - 1]?.value)
        }

        // start time is valid if it's value is inside available user meeting slots
        const currentDaySlots = props.meetingSlotsTable?.filter((d) => moment(d.date).isSame(day, "date"))[0]?.slots
        if (currentDaySlots && time) {
            for (let i = 0; i < currentDaySlots.length - 1; i++) {
                const firstSlot = currentDaySlots[i]
                const secondSlot = currentDaySlots[i + 1]
                let currentTime = firstSlot.value
                    .clone()
                    .set("hours", time?.getHours())
                    .set("minutes", time?.getMinutes())
                if (currentTime.isSame(firstSlot.value, "hours") && currentTime.isSame(firstSlot.value, "minutes")) {
                    return !firstSlot.disabled
                } else if (currentTime.isSame(secondSlot.value, "hours") && currentTime.isSame(secondSlot.value, "minutes")) {
                    return !secondSlot.disabled
                } else if (currentTime.isBetween(firstSlot.value, secondSlot.value)) {
                    return !firstSlot.disabled || !secondSlot.disabled
                }
            }
        }
        return false
    }

    const isTimeValid = (time: Date | null, isEndTime?: boolean) => {
        const isTodayTemp = isToday(moment(day))
        // this check is used only when available meeting slots table of the user is passed to calendar entry component
        if (props.meetingSlotsTable && props.meetingSlotsTable.length > 0 && time) return isTimeSlotAvailable(time, isEndTime)
        return (
            time !== null &&
            ((isTodayTemp && moment(time).isAfter(momentWithoutTimezoneFromTimezonedMoment(moment(), appState.timezone))) ||
                !isTodayTemp)
        )
    }

    const resetTimes = () => {
        const resetStartDate = new Date()
        const resetEndDate = new Date()
        resetEndDate.setMinutes(resetStartDate.getMinutes() + 10)
        setStart(resetStartDate)
        setEnd(resetEndDate)
    }

    function onDaySelected(date: Date) {
        if (isBeforeToday(moment(date))) {
            setShowErrorModal(true)
            setErrorModalMessage(branding.calendarEntryModalPageContent.pastDateErrorMessage)
            return
        }
        setDay(date)
    }

    function onTitleChanged(event: ChangeEvent<HTMLInputElement>) {
        let value = event.target.value ?? ""
        if (value.length > MAX_TITLE_LENGTH) {
            setTitle(value.slice(0, MAX_TITLE_LENGTH))
            return
        }
        setTitle(event.target.value)
    }

    function onNotesChanged(event: ChangeEvent<HTMLTextAreaElement>) {
        let value = event.target.value ?? ""
        if (value.length > MAX_NOTES_LENGTH) {
            setNotes(value.slice(0, MAX_NOTES_LENGTH))
            return
        }
        setNotes(event.target.value)
    }

    function onParticipantInvited(item: any) {
        if (contactsInvited.length >= participantLimit) {
            return
        }
        setContactsInvited(contactsInvited.concat(item))
    }

    function removePerson(item: any) {
        setContactsInvited(contactsInvited.filter((it) => it.id !== item.id))
    }

    function isValidDay(day: Date) {
        if (day === null) {
            return false
        }
        return day.getFullYear() === moment().year() && moment(day).dayOfYear() >= moment().dayOfYear()
    }

    function isMeetingDay(date: Date) {
        let formattedDate = moment(date).format("YYYY-MM-DD")

        return meetingDates.find((ed) => moment(ed).format("YYYY-MM-DD") === formattedDate) !== undefined
    }

    function openCalendar() {
        if (props.viewMode === CalendarEntryModalViewMode.VIEW) return
        setOpenDatepicker(!openDatepicker)
        setOpenTimezonePicker(false)
        setActive(true)
    }

    function openDetailPage(contact: InvitePerson) {
        if (contact.id !== userlink.user()?.profileId) {
            let link =
                window.location.hostname === "localhost"
                    ? `http://localhost:3000${buildDetailLink(contact.id, contact.firstName + contact.lastName ?? "", "user")}`
                    : `https://${window.location.hostname}${buildDetailLink(
                          contact.id,
                          contact.firstName + contact.lastName ?? "",
                          "user"
                      )}`
            window.open(link, "_blank", "noopener,noreferrer")
        }
    }

    /*function numberOfAcceptedParticipants(): number {
        if (props.calendarEntry && props.calendarEntry?.participants.items.length > 0)
            return props.calendarEntry.participants.items.filter(x => x.status === CalendarEntryParticipationStatus.ACCEPTED).length

        return 0
    }*/
    const minStartTime = momentWithoutTimezoneFromTimezonedMoment(moment(), appState.timezone)
    const minEndTime =
        start != null
            ? momentWithoutTimezoneFromTimezonedMoment(moment(start).add(10, "minutes"), appState.timezone)
            : isToday(moment(day))
            ? momentWithoutTimezoneFromTimezonedMoment(moment(minStartTime).add(10, "minutes"), appState.timezone)
            : undefined
    const maxTime = moment({ hours: 23, minutes: 45 })

    const startTimePicker = (
        <TimePickerRoot>
            <Label>
                {branding.calendarEntryModalPageContent.start} <span style={{ color: branding.dangerButtonColor }}>*</span>
            </Label>
            <CalendarEntryTimePicker
                onInputClick={() => setStartTimeFocused(true)}
                selected={start}
                disabled={props.viewMode === CalendarEntryModalViewMode.VIEW}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={TIME_INTERVAL}
                timeCaption=""
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                popperPlacement="bottom"
                showPopperArrow={false}
                includeTimes={
                    props.meetingSlotsTable && props.meetingSlotsTable.length > 0
                        ? availableStartTimes.map((slot) => slot.value.toDate())
                        : undefined
                }
                minTime={isToday(moment(day)) ? minStartTime.toDate() : undefined}
                maxTime={isToday(moment(day)) ? maxTime.toDate() : undefined}
                onChange={(time) => {
                    onStartSelected(time! as Date)
                }}
            />
            <ValidationIcon
                inset="auto auto 0 -3px"
                id="startTimeInput"
                tooltipText={branding.calendarEntryModalPageContent.oneRequiredFieldEmptyWarning}
                valid={isTimeValid(start)}
                focused={startTimeFocused}
            />
        </TimePickerRoot>
    )

    const endTimePicker = (
        <TimePickerRoot>
            <Label>
                {branding.calendarEntryModalPageContent.end} <span style={{ color: branding.dangerButtonColor }}>*</span>
            </Label>
            <CalendarEntryTimePicker
                onInputClick={() => setEndTimeFocused(true)}
                selected={end}
                disabled={props.viewMode === CalendarEntryModalViewMode.VIEW}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={TIME_INTERVAL}
                timeCaption=""
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                popperPlacement="bottom"
                showPopperArrow={false}
                includeTimes={
                    props.meetingSlotsTable && props.meetingSlotsTable.length > 0
                        ? availableEndTimes.map((slot) => slot.value.toDate())
                        : undefined
                }
                minTime={minEndTime?.toDate()}
                maxTime={minEndTime ? maxTime.toDate() : undefined}
                onChange={(time) => onEndSelected(time! as Date)}
            />
            <ValidationIcon
                inset="auto auto 0 -3px"
                id="endTimeInput"
                tooltipText={branding.calendarEntryModalPageContent.oneRequiredFieldEmptyWarning}
                valid={isTimeValid(end, true) && start != null && moment(end).isAfter(start)}
                focused={endTimeFocused}
            />
        </TimePickerRoot>
    )

    function createPersonLabel(contact: InvitePerson): string {
        let label = ""
        if (!contact.firstName && !contact.lastName) label = contact.name ?? ""
        else label = [contact.firstName, contact.lastName].filter(Boolean).join(" ")
        return label
    }

    return (
        <>
            <CalendarEntryMainModal backdrop="static" animation={false} showAvailable={showAvailable} show>
                <ModalRootDiv onClick={(e) => e.stopPropagation()}>
                    <Modal.Header style={{ paddingRight: 0, paddingTop: 25 }}>
                        <Modal.Title style={{ display: "inline-flex" }}>
                            <Title>
                                {props.viewMode === CalendarEntryModalViewMode.EDIT
                                    ? branding.calendarEntryModalPageContent.editTitle
                                    : props.viewMode === CalendarEntryModalViewMode.CREATE
                                    ? branding.calendarEntryModalPageContent.createTitle
                                    : title}
                            </Title>
                        </Modal.Title>
                        <CloseButton
                            onClick={(event) => {
                                event.stopPropagation()
                                onCloseModalView()
                            }}
                        >
                            {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                        </CloseButton>
                    </Modal.Header>
                    <Modal.Body>
                        {props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                            <div style={{ marginBottom: "24px" }}>
                                <PickersGroupRoot onFocus={() => setTitleFocused(true)}>
                                    <Label>
                                        {branding.calendarEntryModalPageContent.name}{" "}
                                        <span style={{ color: branding.dangerButtonColor }}>*</span>
                                    </Label>
                                    <StyledTitleInput
                                        as="input"
                                        type="text"
                                        value={title}
                                        disabled={!canEdit}
                                        onChange={onTitleChanged}
                                        placeholder={branding.calendarEntryModalPageContent.namePlaceholder}
                                    ></StyledTitleInput>
                                    <ValidationIcon
                                        id="nameInput"
                                        tooltipText={branding.calendarEntryModalPageContent.oneRequiredFieldEmptyWarning}
                                        valid={title.length > 0}
                                        focused={titleFocused}
                                    />
                                </PickersGroupRoot>
                                <TextLimit textLength={title?.length || 0} maxTextLength={MAX_TITLE_LENGTH} />
                            </div>
                        )}
                        <Label>
                            {branding.calendarEntryModalPageContent.day}{" "}
                            <span style={{ color: branding.dangerButtonColor }}>*</span>
                        </Label>
                        <SelectDateRoot>
                            <CalendarEntryModalDatePicker
                                selected={day}
                                readOnly={true}
                                locale={language === "de" ? de : en}
                                onCalendarClose={() => {
                                    setOpenDatepicker(false)
                                    setIsComponentVisible(true)
                                    setActive(false)
                                }}
                                shouldCloseOnSelect={true}
                                useWeekdaysShort={true}
                                open={openDatepicker}
                                onInputClick={() => {
                                    openCalendar()
                                    setDayFocused(true)
                                }}
                                onClickOutside={() => {
                                    setOpenDatepicker(false)
                                    setIsComponentVisible(true)
                                    setActive(false)
                                }}
                                includeDates={meetingDates.map((ed) =>
                                    momentWithoutTimezoneFromTimezonedMoment(moment(ed), selectedTimezone).toDate()
                                )}
                                placeholderText={branding.calendarEntryModalPageContent.dateSelectionPlaceholder}
                                openToDate={
                                    initialDatePickerDay ??
                                    momentWithoutTimezoneFromTimezonedMoment(moment(meetingDates[0]), selectedTimezone).toDate()
                                }
                                dayClassName={(date) => {
                                    const disabledClassName = isBeforeToday(moment(date)) ? " past-dates-disabled" : ""
                                    return (isMeetingDay(date) ? "meetingDate" : "nonMeetingDate") + disabledClassName
                                }}
                                popperPlacement="bottom-end"
                                showPopperArrow={false}
                                dateFormat={dayFormat}
                                onChange={(date) => onDaySelected(date as Date)}
                            />
                            <ValidationIcon
                                inset="auto auto 0 -9px"
                                id="dayInput"
                                tooltipText={branding.calendarEntryModalPageContent.oneRequiredFieldEmptyWarning}
                                valid={day !== null && !isBeforeToday(moment(day))}
                                focused={dayFocused}
                            />
                            <CloseIcon onClick={() => openCalendar()}>
                                {IconCalendarEntry({
                                    width: "20",
                                    height: "20",
                                    fill: active
                                        ? branding.calendarEntryModalPageContent.calendarIconActiveColor
                                        : branding.sideIconBar.sideIconColorDark
                                })}
                            </CloseIcon>
                        </SelectDateRoot>
                        <DesktopVersionContainer>
                            <WhenTimeContent>
                                {startTimePicker}
                                {endTimePicker}
                                <CalendarEntryTimezonePicker
                                    viewMode={props.viewMode ?? CalendarEntryModalViewMode.CREATE}
                                    selectedTimezone={selectedTimezone}
                                    setSelectedTimezone={setSelectedTimezone}
                                    timezonePickerOpen={openTimezonePicker}
                                    setTimezonePickerOpen={setOpenTimezonePicker}
                                />
                            </WhenTimeContent>
                        </DesktopVersionContainer>
                        <MobileVersionContainer>
                            <WhenTimeContent>
                                {startTimePicker}
                                {endTimePicker}
                            </WhenTimeContent>
                            <CalendarEntryTimezonePicker
                                viewMode={props.viewMode ?? CalendarEntryModalViewMode.CREATE}
                                selectedTimezone={selectedTimezone}
                                setSelectedTimezone={setSelectedTimezone}
                                timezonePickerOpen={openTimezonePicker}
                                setTimezonePickerOpen={setOpenTimezonePicker}
                            />
                        </MobileVersionContainer>
                        {branding.calendarEntryModalPageContent.locationPickerActive && (
                            <LocationPicker
                                location={location}
                                setLocation={setLocation}
                                calendarEntry={props.calendarEntry}
                                viewMode={props.viewMode}
                                organizationName={props.organizationName}
                            />
                        )}
                        <Label style={{ marginTop: "30px", marginBottom: "auto" }}>
                            {branding.calendarEntryModalPageContent.searchPerson +
                                "(" +
                                contactsInvited.length +
                                "/" +
                                participantLimit +
                                ")"}
                        </Label>
                        <PeopleDisplayArea>
                            {contactsInvited.map((contact, index) => {
                                const statusColor =
                                    (contact as any).status === CalendarEntryParticipationStatus.ACCEPTED
                                        ? CalendarEntryParticipationStatusColor.ACCEPTED
                                        : (contact as any).status === CalendarEntryParticipationStatus.DECLINED
                                        ? CalendarEntryParticipationStatusColor.DECLINED
                                        : CalendarEntryParticipationStatusColor.REQUESTED
                                // const statusLabel =
                                //     (contact as any).status === CalendarEntryParticipationStatus.ACCEPTED
                                //         ? branding.calendarEntryModalPageContent.calendarEntryParticipationStatusAccepted
                                //         : (contact as any).status === CalendarEntryParticipationStatus.DECLINED
                                //         ? branding.calendarEntryModalPageContent.calendarEntryParticipationStatusDeclined
                                //         : (contact as any).status === CalendarEntryParticipationStatus.REQUESTED
                                //         ? branding.calendarEntryModalPageContent.calendarEntryParticipationStatusRequested
                                //         : undefined

                                return (
                                    <PersonChip
                                        key={index}
                                        userId={contact.id}
                                        label={createPersonLabel(contact)}
                                        // sublabel={statusLabel}
                                        logoUrl={contact.logoUrl ? contact.logoUrl : contact.pictureUrl}
                                        icon={
                                            contact.id !== profileId && canEdit
                                                ? IconClose({
                                                      fill: statusColor,
                                                      width: "10px",
                                                      height: "10px"
                                                  })
                                                : undefined
                                        }
                                        successIcon={
                                            (contact as any).status === CalendarEntryParticipationStatus.ACCEPTED
                                                ? IconCheckmarkSuccessfullMini({
                                                      fill: statusColor,
                                                      stroke: "transparent",
                                                      width: "15px",
                                                      height: "20px"
                                                  })
                                                : undefined
                                        }
                                        declineIcon={
                                            !canEdit && (contact as any).status === CalendarEntryParticipationStatus.DECLINED
                                                ? IconClose({
                                                      fill: statusColor,
                                                      stroke: "transparent",
                                                      width: "12px",
                                                      height: "12px"
                                                  })
                                                : undefined
                                        }
                                        onIconClick={() => removePerson(contact)}
                                        onAvatarPanelClick={() => openDetailPage(contact)}
                                        labelColor={statusColor}
                                        backgroundColor={"#FFF"}
                                    ></PersonChip>
                                )
                            })}
                            {contactsInvited.length > 1 &&
                                !isDateInValid(day, start, end) &&
                                props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                                    <AvailabilityButton onClick={() => setShowAvailable(true)}>
                                        {branding.calendarEntryModalPageContent.availabilityButtonText}
                                        {IconChevronRightSolid({ width: "13", height: "13", fill: "#fff" })}
                                    </AvailabilityButton>
                                )}
                        </PeopleDisplayArea>
                        {props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                            <StyledSearchPersonInput
                                calendarEntryModal={true}
                                timezone={selectedTimezone}
                                start={dateWithUTCIncrasedByTimezone(selectedTimezone, start!, day!)}
                                end={dateWithUTCIncrasedByTimezone(selectedTimezone, end!, day!)}
                                placeholder={branding.calendarEntryModalPageContent.searchPersonPlaceholder}
                                personClicked={onParticipantInvited}
                                excludeIds={contactsInvited.map((p) => p.id)}
                                topClear="7px"
                                topSearch="0px"
                            />
                        )}
                        {(props.viewMode === CalendarEntryModalViewMode.VIEW ||
                            props.viewMode === CalendarEntryModalViewMode.EDIT) && (
                            <ChatWithParticipantsDiv>
                                {findMyParticipationStatus() === CalendarEntryParticipationStatus.ACCEPTED && (
                                    <ChatWithParticipantsButtonLink onClick={chatWithParticipants}>
                                        {branding.calendarEntryListView.chatButtonTitle}
                                    </ChatWithParticipantsButtonLink>
                                )}
                            </ChatWithParticipantsDiv>
                        )}
                        <NotesContainer>
                            <Label>
                                {/* {branding.calendarEntryModalPageContent.notes + " (" + notes.length + "/" + MAX_NOTES_LENGTH + ")"} */}
                                {branding.calendarEntryModalPageContent.notes}
                            </Label>
                            <StyledNotesInput
                                disabled={!canEdit}
                                rows={3}
                                spellCheck={false}
                                value={notes}
                                onChange={onNotesChanged}
                                as="textarea"
                                placeholder={canEdit ? branding.calendarEntryModalPageContent.notesPlaceholder : ""}
                            />
                            <TextLimit textLength={notes?.length || 0} maxTextLength={MAX_NOTES_LENGTH} />
                        </NotesContainer>

                        {props.viewMode === CalendarEntryModalViewMode.VIEW && (
                            <SubmitButtonContainer>
                                <ExportMeetingContainer>
                                    <ExportMeetingsLink onClick={exportMeeting}>
                                        {branding.calendarEntryModalPageContent.exportMeeting}
                                    </ExportMeetingsLink>
                                </ExportMeetingContainer>
                                <JoinMeetingContainer>
                                    <CancelButton
                                        viewMode={CalendarEntryModalViewMode.VIEW}
                                        customColor={true}
                                        className="d-flex align-items-center justify-content-center"
                                        onClick={() => {
                                            setShowDeclineModal(true)
                                        }}
                                    >
                                        {branding.calendarEntryListView.declineButtonTitle}
                                    </CancelButton>

                                    <SubmitButton
                                        viewMode={CalendarEntryModalViewMode.VIEW}
                                        className="d-flex align-items-center justify-content-center"
                                        onClick={joinMeeting}
                                        disabled={
                                            isExplorationOrPostEventPhase ||
                                            joinMeetingDisabled ||
                                            (myParticipationStatus &&
                                                myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED)
                                        }
                                    >
                                        {branding.calendarEntryListView.callButtonTitle}
                                    </SubmitButton>
                                </JoinMeetingContainer>
                            </SubmitButtonContainer>
                        )}

                        {(props.viewMode === CalendarEntryModalViewMode.CREATE ||
                            props.viewMode === CalendarEntryModalViewMode.EDIT) && (
                            <SubmitButtonContainer>
                                {props.viewMode === CalendarEntryModalViewMode.EDIT && (
                                    <CancelMeetingContainer>
                                        <CancelButton
                                            viewMode={CalendarEntryModalViewMode.EDIT}
                                            customColor={true}
                                            className="d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                setShowCancelModal(true)
                                            }}
                                        >
                                            {branding.calendarEntryModalPageContent.deleteMeeting}
                                        </CancelButton>
                                    </CancelMeetingContainer>
                                )}
                                <OtherButtonsContainer>
                                    {props.viewMode === CalendarEntryModalViewMode.CREATE && (
                                        // <SubmitButtonDanger onClick={() => props.close()} className="d-flex align-items-center">
                                        //     {branding.globalTexts.cancel}
                                        // </SubmitButtonDanger>
                                        <CancelButton
                                            viewMode={CalendarEntryModalViewMode.CREATE}
                                            onClick={() => props.close()}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            {branding.globalTexts.cancel}
                                        </CancelButton>
                                    )}
                                    {props.viewMode === CalendarEntryModalViewMode.CREATE && (
                                        <SubmitButton
                                            viewMode={CalendarEntryModalViewMode.CREATE}
                                            className="d-flex align-items-center justify-content-center"
                                            onClick={() => createOrUpdateCalendarEntry(selectedTimezone)}
                                        >
                                            {branding.calendarEntryModalPageContent.sendInvite}
                                        </SubmitButton>
                                    )}

                                    {props.viewMode === CalendarEntryModalViewMode.EDIT && (
                                        // <SubmitButtonDanger
                                        //     className="d-flex align-items-center"
                                        //     onClick={() => {
                                        //         setShowConfirmationDialog(true)
                                        //     }}
                                        // >
                                        //     {branding.calendarEntryModalPageContent.deleteMeeting}
                                        // </SubmitButtonDanger>
                                        <CancelButton
                                            viewMode={CalendarEntryModalViewMode.EDIT}
                                            className="d-flex align-items-center justify-content-center"
                                            onClick={() => props.close()}
                                        >
                                            {branding.globalTexts.cancel}
                                        </CancelButton>
                                    )}
                                    {props.viewMode === CalendarEntryModalViewMode.EDIT && (
                                        <ValidationIcon
                                            id="submitButtonInput"
                                            tooltipText={branding.calendarEntryModalPageContent.calendarNotValid}
                                            valid={(!isDateInValid(day, start, end) as boolean) && title.length > 0}
                                            focused={dayFocused && startTimeFocused && endTimeFocused && titleFocused}
                                        />
                                    )}
                                    {props.viewMode === CalendarEntryModalViewMode.EDIT && (
                                        <SubmitButton
                                            viewMode={CalendarEntryModalViewMode.EDIT}
                                            className="d-flex align-items-center justify-content-center"
                                            onClick={() => createOrUpdateCalendarEntry(selectedTimezone)}
                                        >
                                            {branding.calendarEntryModalPageContent.saveChanges}
                                        </SubmitButton>
                                    )}
                                </OtherButtonsContainer>
                            </SubmitButtonContainer>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            // props.viewMode === CalendarEntryModalViewMode.VIEW ||
                            props.viewMode === CalendarEntryModalViewMode.EDIT && (
                                <SubmitButtonContainerLinks>
                                    <ButtonLinks
                                        disabled={
                                            isExplorationOrPostEventPhase ||
                                            joinMeetingDisabled ||
                                            (myParticipationStatus &&
                                                myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED)
                                        }
                                        onClick={joinMeeting}
                                    >
                                        {branding.calendarEntryListView.callButtonTitle}
                                    </ButtonLinks>
                                    <ButtonLinks onClick={exportMeeting}>
                                        {branding.calendarEntryModalPageContent.exportMeeting}
                                    </ButtonLinks>

                                    {/* <ButtonLinks disabled={numberOfAcceptedParticipants() <= 1 || (myParticipationStatus && myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED)} onClick={chatWithParticipants}>{branding.calendarEntryListView.chatButtonTitle}</ButtonLinks> */}
                                </SubmitButtonContainerLinks>
                            )
                        }
                    </Modal.Footer>
                </ModalRootDiv>
                {showAvailable && props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                    <AvailableUsers
                        editMode={props.viewMode === CalendarEntryModalViewMode.EDIT}
                        startTime={start ?? undefined}
                        endTime={end ?? undefined}
                        selectedDate={day ?? undefined}
                        contactsInvited={contactsInvited}
                        timezone={selectedTimezone}
                        close={() => setShowAvailable(false)}
                    ></AvailableUsers>
                )}
                {showErrorModal && (
                    <DataUpdateAlert
                        message={errorModalMessage}
                        close={() => setShowErrorModal(false)}
                        show={showErrorModal}
                        type="calendar-entry-modal"
                        timeoutSeconds={3}
                        titleImage={IconIndicatorErrorBlack({ fill: branding.sideIconBar.sideIconColorDark })}
                        position="bottom"
                    />
                )}
            </CalendarEntryMainModal>
            <DeleteCalendarEntryModal
                backdrop="static"
                type="cancel"
                animation={false}
                show={showCancelModal}
                deleteCalendarEntry={() => deleteCalendarEntry()}
                setShowConfirmationDialog={() => setShowCancelModal(false)}
            />

            <DeleteCalendarEntryModal
                backdrop="static"
                type="decline"
                animation={false}
                show={showDeclineModal}
                deleteCalendarEntry={() => declineMeeting()}
                setShowConfirmationDialog={() => setShowDeclineModal(false)}
            />
        </>
    )
}

export default CalendarEntryModal

export const useCalendarEntryModal = (closeCallback?: () => void, openCallback?: () => void) => {
    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState<CalendarEntryModalProps>({} as any)

    const showCalendarEntryModal = (props: CalendarEntryModalProps) => {
        setData(props)
        if (openCallback) openCallback()
        setIsVisible(true)
    }

    const CalendarModal = () =>
        ReactDOM.createPortal(
            <React.Fragment>
                {isVisible && (
                    <CalendarEntryModal
                        {...data}
                        close={() => {
                            setIsVisible(false)

                            if (closeCallback) {
                                closeCallback()
                            }
                        }}
                    />
                )}
            </React.Fragment>,
            document.body
        )

    return {
        showCalendarEntryModal,
        CalendarModal
    }
}
