import { useCallback, useEffect, useState } from "react"
import * as React from "react"
import { Button, Modal } from "react-bootstrap"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { trackVisit } from "../../backendServices/TrackingServices"
import branding from "../../branding/branding"
import { defaultLogger as logger } from "../../globalStates/AppState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { ConnectionStates, GetConnectionDetails, setInitialStates } from "../../utils/SayHello"
import DataUpdateAlert from "../DataUpdateAlert"
import { IconCheckmarkSuccessfull, IconClose } from "../Icons"
import TextLimit from "../TextLimit"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { doConnectAction, sendOrganizationConnectionRequest } from "../../backendServices/SeriesOfTopicsUserServices"
import { trackTakeAction } from "../../utils/GTMTracking"

export const ModalRoot = styled(Modal)`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    svg {
        color: ${branding.primaryColor};
    }

    .close {
        text-shadow: none;
        color: ${branding.mainInfoColor};
    }

    .modal-header-close {
        outline: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 26px;
        font-weight: 10;
        line-height: 1.66666667;
        color: ${branding.mainInfoColor};
        width: 30px;
        padding: 0 4px;
        background: none;
        border-style: none;
        margin-right: 0px;
        margin-top: -10px;
    }

    .modal-content {
        font-family: ${branding.font1};
        padding: 0 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
        padding: 35px 0 20px 0;
    }
    .modal-footer {
        justify-content: space-between;
        border: none;
        padding: 0px 8px;
    }

    .modal-body {
        padding: 12px 15px 10px 15px;
    }

    .modal-title {
        font-family: ${branding.font1};
        margin-left: 15px;
        font-size: 18px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 3px;
    }

    .btn-primary {
        font-family: ${branding.font1};
        width: 30%;
        display: inline;
        background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor ?? "#000"};
        color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
        /* border: 1px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};
        border-radius: 5px; */

        :hover {
            background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor ?? "#e8e8e8"} !important;
            color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor ?? "#000"} !important;
        }
    }

    & .btn-primary:focus {
        outline: none !important;
    }

    .btn-secondary {
        display: inline;
        width: 30%;
        font-family: ${branding.font1};
        color: ${branding.sayHelloModal.submitBtnSecondaryTextColor ?? "#000"} !important;
        background-color: ${branding.sayHelloModal.submitBtnSecondaryBgColor ?? "#fff"};
        border: none;
        border-color: ${branding.sayHelloModal.submitBtnSecondaryBorderColor ?? "#fff"};
        border-radius: none;
    }

    & .btn-secondary:focus {
        outline: none !important;
    }
    .subtitleText {
        font-family: ${branding.font1};
        display: absolute;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        margin: 10px 15px 25px 15px;
    }

    .headerImage {
        margin-top: 5px;
        margin-left: 15px;
    }

    .customString {
        font-weight: bold;
    }

    .messageBox {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        /* margin-left: 8px; */
        font-family: ${branding.font1};
    }
`

export const TextareaRoot = styled.div`
    .form-group {
        font-size: 12px;
        margin-bottom: 0px !important;
    }

    &.form-group:focus {
        outline: none !important;
    }
    .form-control {
        font-size: 12px;
        border: 1px solid #d9d9d9;
        border-radius: 5px !important;
        color: #202428 !important;
        transition: none !important;
        height: 70px;
    }
    .form-control::placeholder {
        color: #727272 !important;
    }

    menu,
    ol,
    ul,
    li {
        list-style: inside;
    }
`
interface ModalContentProps {
    requested: boolean
    accepted: boolean
    ignored: boolean
    openRequest: boolean
    message?: string
    onChange(e: React.ChangeEvent<HTMLTextAreaElement>): void
}

const ModalContent = (props: ModalContentProps) => {
    const maxTextLength: number = 150
    const message = props.accepted
        ? branding.sayHelloModal.cancelConnectionMessage
        : props.ignored
        ? branding.sayHelloModal.ignoredMessage
        : props.openRequest
        ? branding.sayHelloModal.openRequestMessage
        : branding.sayHelloModal.cancelRequestMessage

    if (!props.accepted && !props.requested && !props.ignored && !props.openRequest) {
        return (
            <TextareaRoot className="form-group">
                <textarea
                    maxLength={maxTextLength}
                    autoFocus={true}
                    value={props.message}
                    name="modalInputMessage"
                    onChange={props.onChange}
                    className="form-control"
                    placeholder={branding.sayHelloModal.sendMessagePlaceholder}
                ></textarea>
                <TextLimit textLength={props.message?.length || 0} maxTextLength={maxTextLength} />
            </TextareaRoot>
        )
    } else {
        return (
            <TextareaRoot className="form-group">
                <span className="messageBox">{message}</span>
            </TextareaRoot>
        )
    }
}

interface OrganizationRequestModalContentProps {
    message?: string
    onChange(e: React.ChangeEvent<HTMLTextAreaElement>): void
}

const RequestOrganizationModalContent = (props: OrganizationRequestModalContentProps) => {
    const maxTextLength: number = 150
    return (
        <TextareaRoot className="form-group">
            <textarea
                maxLength={maxTextLength}
                value={props.message}
                name="modalInputMessage"
                onChange={props.onChange}
                className="form-control"
                placeholder={branding.sayHelloModal.sendMessagePlaceholder}
            ></textarea>
            <TextLimit textLength={props.message?.length || 0} maxTextLength={maxTextLength} />
        </TextareaRoot>
    )
}

interface Closable {
    handleClose(): void
}
interface ModalProps {
    children?: React.ReactNode
    targetId: string
    setSayHelloResponseStatus?(status: string): void
    setCurrentConnectionStatus?(status: string): void
    organizationModal?: boolean
    removePosition?: boolean
    organizationName?: string
    contact?: any
}

const IgnoreButton = styled.div`
    /* width: 90px;
    height: 40px;
    font-size: 18px;
    border: none;
    background: white;
    color: #FFF;
    border-radius: 25px;
    cursor: pointer; */

    /* width: 25%;
    height: 40px;
    cursor: pointer;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    border: none;

    &:hover {
        text-decoration: none;
    } */
    display: block;
    width: 30%;
    height: 35px;
    /* margin-top: 20px; */
    color: ${branding.recommendModal.cancelBtnPrimaryTextColor ?? "#000"};
    background-color: transparent;
    border: 1px solid ${branding.recommendModal.cancelBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;
    justify-content: center;

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`
export const SubmitButton = styled.div`
    width: 200px;
    height: 40px;
    cursor: pointer;
    font-size: 18px;
`
export const SubmitButtonWithoutIgnore = styled.div`
    cursor: pointer;
    font-size: 18px;
    margin-left: 45px;

    .btn-primary {
        background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor ?? "#000"};
        color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
        border-color: ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};
    }
`
export const SubmitButtonContainer = styled.div<{ justifyContent?: string }>`
    width: 100%;
    justify-content: ${(props) => props.justifyContent ?? "flex-end"};
    display: inline-flex;
    margin-bottom: 30px;

    &.disabled {
        pointer-events: none !important;
    }
`
export const DisagreeButton = styled.div`
    /* width: 50%;
    height: 30px;
    cursor: pointer;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};

    &:hover {
        text-decoration: none;
    } */
    display: block;
    width: 30%;
    height: 35px;
    /* margin-top: 20px; */
    color: ${branding.recommendModal.cancelBtnPrimaryTextColor ?? "#000"};
    background-color: transparent;
    border: 1px solid ${branding.recommendModal.cancelBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;
    justify-content: center;

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`
export const SubmitButtonNew = styled(Button)<{ width?: string }>`
    display: block;
    width: ${(props) => props.width ?? "30%"};
    height: 35px;
    /* margin-top: 20px; */
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
    border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    cursor: pointer;
    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }

    &:focus {
        outline: none !important;
    }
`
const CloseButton = styled.div`
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
    svg {
        color: ${branding.mainInfoColor};
    }
`

const SayHelloModal = (props: ModalProps & Closable) => {
    const loggedInUserId = useLoggedInState().user()?.profileId || ""

    const [isLoaded, setIsLoaded] = useState(false)

    const [openRequest, setOpenRequest] = useState(false)
    const [requested, setRequested] = useState(false)
    const [accepted, setAccepted] = useState(false)
    const [ignored, setIgnored] = useState(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)

    const headerText = props.organizationModal
        ? branding.sayHelloModal.headerTextOrganizationRequest
        : requested || accepted
        ? requested
            ? branding.sayHelloModal.headerTextCancelRequest
            : branding.sayHelloModal.cancelConnectionTitle
        : openRequest
        ? branding.sayHelloModal.openRequestMessageModal
        : branding.sayHelloModal.headerTextSayHello
    const submitText = openRequest
        ? branding.sayHelloModal.submitTextAccept
        : accepted
        ? branding.sayHelloModal.submitTextForCancelButton
        : requested || ignored
        ? branding.sayHelloModal.submitTextYes
        : branding.sayHelloModal.submitTextSend
    const closeText = openRequest
        ? branding.sayHelloModal.cancelText
        : accepted
        ? branding.sayHelloModal.cancelTextForCancelButton
        : ignored
        ? branding.sayHelloModal.cancelTextYes
        : requested
        ? branding.sayHelloModal.cancelTextRequested
        : branding.sayHelloModal.cancelText
    // const headerImage = props.organizationModal
    //     ? IconCallBack({ fill: branding.sideIconBar.sideIconColorDark })
    //     : requested
    //     ? IconConnectWithdraw({ fill: branding.sideIconBar.sideIconColorDark })
    //     : accepted
    //     ? IconRemoveStaff({ fill: branding.sideIconBar.sideIconColorDark })
    //     : openRequest
    //     ? IconConnectSendRequest({ fill: branding.sideIconBar.sideIconColorDark })
    //     : IconConnect({ fill: branding.sideIconBar.sideIconColorDark })
    const [modalInputMessage, setmodalInputMessage] = useState<string>("")

    const [name, setName] = useState<string>("")

    useEffect(() => {
        if (props.organizationModal && props.organizationName) {
            setName(props.organizationName)
        } else {
            if (props.contact) {
                setName([props.contact.firstName, props.contact.lastName].filter(Boolean).join(" "))
            }
        }
        // eslint-disable-next-line
    }, [])

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setmodalInputMessage(e.target.value)
    }

    function handleSubmit(event: React.FormEvent<HTMLButtonElement>, action?: string) {
        if (openRequest) {
            if (action && action === "ignore") {
                connectActionHandler("ignore", null)
                props.handleClose()
            } else {
                connectActionHandler("accept", null)
                props.handleClose()
            }
        } else if (requested || accepted || ignored) {
            connectActionHandler("cancel", null)
            setAccepted(false)
            setRequested(false)
            setIgnored(false)
            setOpenRequest(false)
            setmodalInputMessage("")
            props.handleClose()
        } else {
            connectActionHandler("request", modalInputMessage)
            //setShowAlert(true);
        }
        //props.handleClose()
    }

    function handleOrganizationRequest(event: React.FormEvent<HTMLButtonElement>) {
        trackTakeAction(`Interest Organization ${props.organizationName}`, "RegisterInterest", "Organization")
        sendOrganizationConnectionRequest({
            profileId: loggedInUserId as string,
            organizationId: props.targetId,
            message: modalInputMessage
        })
            .then((resp) => {
                if ((resp as BackendServiceError).httpStatus) {
                    // FIXME
                } else {
                    trackVisit(loggedInUserId as string, props.targetId, "INTEREST#SENT")
                }

                props.handleClose()
            })
            .catch((err) => {
                // Logged in BackendServices
            })
    }

    //function which makes POST request to /doConnectAction endpoint
    function connectActionHandler(action: string, message: string | null) {
        doConnectAction({
            profileId: loggedInUserId as string,
            targetProfileId: props.targetId,
            message: message,
            action: action
        })
            .then((response: any) => {
                if (response.content) {
                    const status = response.content.profile.myConnectionStatus
                    const obj = setInitialStates(status)
                    if (props.setSayHelloResponseStatus) props.setSayHelloResponseStatus(status)
                    setValues(obj)

                    if (status === ConnectionStates.REQUESTED) {
                        setShowAlert(true)
                        return
                    }
                }
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                // Logged in BackendServices
            })
    }

    useEffect(() => {
        if (props.organizationModal) {
            setIsLoaded(true)
        } else {
            // Used for Connect with person modal
            GetConnectionDetails(loggedInUserId, props.targetId)
                .then((res) => {
                    const obj = setInitialStates(res as string)
                    if (props.setCurrentConnectionStatus) props.setCurrentConnectionStatus(res as string)
                    setValues(obj)
                    setIsLoaded(true)
                })
                .catch((error) => {
                    logger.error({
                        message: "SayHelloModal Get Connect Details failed",
                        errorMessage: error.message,
                        errorStack: error.stack
                    })
                })
        }
        //eslint-disable-next-line
    }, [props.targetId])

    function setValues(obj: any) {
        setRequested(obj.requested)
        setOpenRequest(obj.openRequest)
        setAccepted(obj.accepted)
        setIgnored(obj.ignored)
    }

    let content: JSX.Element = <div />
    if (isLoaded && props.organizationModal) {
        content = (
            <div>
                <div className="modal-header">
                    <h3 className="modal-title">{headerText}</h3>
                    {/* <button type="button" className="close" onClick={props.handleClose} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> */}
                    <CloseButton onClick={props.handleClose}>
                        {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                    </CloseButton>
                </div>
                {props.organizationModal && name && (
                    <div className="subtitleText">
                        <span>
                            {branding.sayHelloModal.subtitleRegisterInterestsPart1}
                            <span className="customString">{name}</span>
                            {branding.sayHelloModal.subtitleRegisterInterestsPart2}
                            <span className="customString">
                                {branding.sayHelloModal.subtitleRegisterInterestsPart3 ? name : ""}
                            </span>
                            {branding.sayHelloModal.subtitleRegisterInterestsPart3}
                        </span>
                    </div>
                )}
                <div className="modal-body">
                    <RequestOrganizationModalContent
                        onChange={handleChange}
                        message={modalInputMessage}
                    ></RequestOrganizationModalContent>
                </div>
                <div className="modal-footer">
                    <SubmitButtonContainer>
                        {/* <button type="button" className="btn btn-secondary" onClick={props.handleClose}>
                    {closeText}
                </button>
                <button type="submit" className="btn btn-primary" onClick={handleOrganizationRequest}>
                    {submitText}
                </button> */}
                        <DisagreeButton onClick={props.handleClose} className="d-flex align-items-center">
                            {closeText}
                        </DisagreeButton>
                        <SubmitButtonNew
                            type="submit"
                            onClick={handleOrganizationRequest}
                            className="d-flex align-items-center justify-content-center"
                        >
                            {submitText}
                        </SubmitButtonNew>
                    </SubmitButtonContainer>
                </div>
            </div>
        )
    } else if (isLoaded) {
        content = (
            <div>
                <div className="modal-header">
                    <h3 className="modal-title">{headerText}</h3>
                    <CloseButton onClick={props.handleClose}>
                        {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                    </CloseButton>
                </div>
                {!requested && !accepted && !openRequest && !ignored && name && (
                    <div className="subtitleText">
                        <span>
                            {branding.sayHelloModal.subtitleConnectRequestPart1}
                            <span className="customString">{name} </span>
                            {branding.sayHelloModal.subtitleConnectRequestPart2}
                        </span>
                    </div>
                )}
                <div className="modal-body">
                    <ModalContent
                        requested={requested}
                        accepted={accepted}
                        ignored={ignored}
                        onChange={handleChange}
                        message={modalInputMessage}
                        openRequest={openRequest}
                    ></ModalContent>
                </div>
                <div className="modal-footer">
                    <SubmitButtonContainer>
                        {openRequest && (
                            <IgnoreButton
                                className="d-flex align-items-center"
                                onClick={(e: any) => handleSubmit(e, "ignore")}
                                hidden={!openRequest}
                            >
                                {branding.sayHelloModal.submitTextIgnore}
                            </IgnoreButton>
                        )}
                        {openRequest && (
                            <SubmitButtonNew
                                type="submit"
                                className="d-flex align-items-center justify-content-center"
                                onClick={handleSubmit}
                            >
                                {submitText}
                            </SubmitButtonNew>
                        )}

                        {!openRequest && (
                            <DisagreeButton
                                hidden={openRequest}
                                onClick={props.handleClose}
                                className="d-flex align-items-center"
                            >
                                {closeText}
                            </DisagreeButton>
                        )}
                        {!openRequest && (
                            <SubmitButtonNew
                                type="submit"
                                hidden={openRequest}
                                onClick={handleSubmit}
                                className="d-flex align-items-center justify-content-center"
                            >
                                {submitText}
                            </SubmitButtonNew>
                        )}
                    </SubmitButtonContainer>
                </div>
            </div>
        )
    }

    return (
        <>
            {showAlert ? (
                <DataUpdateAlert
                    message={branding.sayHelloModal.alertMessage}
                    show={showAlert}
                    type="say-hello-modal"
                    titleImage={IconCheckmarkSuccessfull({})}
                    color="#00B300"
                    timeoutSeconds={3}
                    position="top"
                    close={() => {
                        setShowAlert(false)
                        props.handleClose()
                    }}
                />
            ) : (
                <ModalRoot backdrop="static" show={true} animation={false} onHide={() => props.handleClose()}>
                    <div onClick={(e) => e.stopPropagation()}>{content}</div>
                </ModalRoot>
            )}
        </>
    )
}

export default SayHelloModal

// There is an issue when using this custom-hook when organizationModal=false: the DataUpdateAlert would not show up.
// Instead the Modal would show up and offer to withdraw the request right after it was created.
// The state showAlert does not correctly rerender the component for some reason.
// So for now we don't use the custom-hook in this case and just use the function-component SayHelloModal directly.
export const useSayHelloModal = (onCloseCallback?: () => void) => {
    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState<ModalProps>({} as any)

    const closeCallback = useCallback(() => {
        if (onCloseCallback) onCloseCallback()
    }, [onCloseCallback])

    const show = (props: ModalProps) => {
        setData(props)
        setIsVisible(true)
    }

    const Modal = () =>
        ReactDOM.createPortal(
            <React.Fragment>
                {isVisible && (
                    <SayHelloModal
                        {...data}
                        handleClose={() => {
                            closeCallback()
                            setIsVisible(false)
                        }}
                    />
                )}
            </React.Fragment>,
            document.body
        )

    return {
        showSayHelloModal: show,
        SayHelloModal: Modal
    }
}
