import { LocalVideo, MeetingStatus, MicrophoneActivity, useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { useContext, useEffect, useState } from "react"
import branding from "../../../branding/branding"
import { IconHandYellow } from "../../../ui/Icons"
import { RaisedHandContext } from "../../ConferenceRoom/ConferenceRoom"
import { LocalAvatarNamePlate } from "../LocalAvatar/LocalAvatar.styled"
import { LocalVideoRoot } from "./LocalVideo.styled"
import { disableFirefoxPip } from "../../utils/disableFirefoxPip"

function LocalVideoCustom() {
    const meetingManager = useMeetingManager()
    const [chimeAttendeeId] = useState(meetingManager.meetingSession?.configuration.credentials?.attendeeId)
    const raisedHands = useContext(RaisedHandContext)
    useEffect(() => {
        disableFirefoxPip()
    }, [])

    if (meetingManager.meetingStatus !== MeetingStatus.Succeeded) return null

    return (
        <LocalVideoRoot>
            <LocalVideo />
            <LocalAvatarNamePlate>
                {raisedHands &&
                    raisedHands.includes(meetingManager.meetingSession?.configuration.credentials?.externalUserId || "") && (
                        <IconHandYellow height="18px" width="18px" />
                    )}
                {branding.conferenceTexts.localVideoLabel}
                <MicrophoneActivity className="activity" attendeeId={chimeAttendeeId || ""} />
            </LocalAvatarNamePlate>
        </LocalVideoRoot>
    )
}

export default LocalVideoCustom
