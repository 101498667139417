import * as React from "react"
import styled, { keyframes } from "styled-components"
import { IconThreeDotsMenu, VGIcon, IconMobile } from "../ui/Icons"
import branding from "../branding/branding"
import { Tooltip } from "react-bootstrap"
import { device } from "../utils/Device"
import { onAppDownloadClick } from "../MobileWebsiteCheck"
import { CustomOverlayTrigger } from "./CustomOverlayTrigger"

const MenuContainer = styled.div<{ expandedHeight: number; backgroundColor?: string }>`
    font-family: ${branding.font1};
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    transition: height 0.2s ease-out;
    height: ${(props) => props.expandedHeight}px;
    background-color: ${(props) => props.backgroundColor};

    @media ${device.mobile} {
        padding-left: 11px;
        margin-top: 20px;
        margin-bottom: 20px;
        min-height: 120px;
        height: 170px;
    }

    @media (max-width: 390px) {
        padding-left: 8px;
    }

    @media (max-width: 375px) {
        padding-left: 4px;
    }

    @media (max-width: 360px) {
        padding-left: 0px;

        &.staffEntry {
            margin-left: -5px;
            margin-right: -5px;
        }
    }

    &.collapsed {
        height: 0px;
        min-height: 0px;
        padding-top: 0px;
    }

    & svg {
        /* width: 20px;
        height: 20px;
        color: ${branding.primaryColor}; */
    }

    &.big svg {
        width: 25px;
        height: 25px;
    }

    &.big div {
        font-size: inherit !important;

        @media (max-width: 1500px) {
            font-size: 12px !important;
        }

        @media (max-width: 1300px) {
            font-size: 11px !important;
        }

        @media (max-width: 1250px) {
            font-size: 10px !important;
        }
    }
`

const Menu = styled.div`
    margin-top: 20px;
    width: 100%;
    flex-grow: 1;
    position: relative;
`

const LabeledIconButtonContainer = styled.div`
    position: relative;
    width: 100%;
`

const IconButtonsContainer = styled.div<{ isDarkMode: boolean }>`
    display: table;
    table-layout: fixed;
    width: 100%;
    position: absolute;
    top: ${(props) => (props.isDarkMode ? "45%" : "50%")};
    transform: translateY(-50%);

    &.collapsed {
        display: none;
    }
    & :nth-child(1) {
        animation-delay: 100ms;
    }
    & :nth-child(2) {
        animation-delay: 200ms;
    }

    &.mobileProfilePage {
        padding-left: 6px;
        padding-right: 6px;
    }
`
const opacityAnimation = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

const IconButtonsRow = styled.div`
    display: table-row;
    & > * {
        display: table-cell;
    }

    animation-name: ${opacityAnimation};
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-fill-mode: backwards;
    padding-bottom: 10px;

    @media (max-width: 1600px) {
        white-space: normal;
        word-break: break-word;
    }
`

const LabeledIconButtonContent = styled.div<{ isDarkMode: boolean }>`
    margin: 0 5px 10px 5px;
    padding: 9px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${(props) => (props.isDarkMode ? "#313233" : branding.contextMenuBranding.contextBackgroundColor)};

    &.personTileBg {
        background-color: transparent;
    }

    &.big {
        background-color: #fff;
    }

    &:hover {
        background-color: ${(props) =>
            props.isDarkMode ? "#313233" : branding.contextMenuBranding.contextBackgroundColorOnHover};
        color: ${(props) => props.isDarkMode && "#727272"};

        & svg path {
            fill: ${(props) => props.isDarkMode && "#727272"};
        }
        &.personTileBgHover {
            background-color: ${(props) =>
                props.isDarkMode ? "#313233" : branding.contextMenuBranding.contextBgColorOnHoverPersonTiles};
        }
    }
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
    &.disabled:hover {
        background-color: transparent;
    }

    @media ${device.mobile} {
        background: ${branding.crsTabs.defaultBorderColor};
        width: 100px;
        height: 75px;
    }
`
const LabeledIconButtonIcon = styled.div`
    margin-bottom: 10px;
`
const LabeledIconButtonLabel = styled.div<{ isDarkMode: boolean }>`
    font-size: 10px; /* was 12px */
    line-height: 12px;
    font-family: ${branding.font1};
    color: ${(props) => (props.isDarkMode ? "#fff" : branding.primaryColor)};
    text-align: center;

    &.personTileBg {
        color: white;
    }

    @media only screen and (max-width: 1400px) {
        font-size: 8.5px;
        word-break: keep-all;
    }
    @media only screen and (max-width: 1100px) {
        font-size: 7px;
        word-break: keep-all;
    }

    @media ${device.mobile} {
        font-size: 12px;
        line-height: 14px;
        word-break: keep-all;

        padding-left: 6px;
        padding-right: 6px;
    }

    &.big {
        @media (max-width: 1190px) {
            display: none;
        }
    }
`

export interface MenuItemProps {
    title: string
    icon: any
    disabled?: boolean
    hint?: string
    onClick: () => void
    notVisible?: boolean
    hideOnMobile?: boolean
    hideOnDesktop?: boolean
}

export interface ContextMenuProps {
    collapsed: boolean
    className?: string
    itemsPerRow?: number
    items: () => MenuItemProps[]
    onItemClickHandler?: Function
    noLabels?: boolean
    mobile?: boolean
    isDarkMode?: boolean
    backgroundColor?: string
}

const ContextMenu: React.FC<ContextMenuProps> = ({
    collapsed,
    className,
    items,
    itemsPerRow = 3,
    onItemClickHandler,
    noLabels = false,
    mobile = false,
    isDarkMode = false,
    backgroundColor
}) => {
    const clsName = (collapsed ? "collapsed" : "expanded") + (className ? " " + className : "")

    const itemList = (itemArr: MenuItemProps[]) => {
        const x: any[] = []

        if (mobile) {
            let itemArrTemp = itemArr.filter((x) => !x.hideOnMobile)

            if (branding.contextMenuBranding.appDownloadEnabled) {
                itemArrTemp.push({
                    title: branding.contextMenuBranding.appDownloadButtonText,
                    hint: branding.contextMenuBranding.appDownloadButtonText,
                    icon: IconMobile({ fill: branding.sideIconBar.sideIconColorDark }),
                    onClick: () => {
                        onAppDownloadClick()
                    }
                })
            }

            for (var i = 0; i < itemArrTemp.length; i = i + itemsPerRow) {
                x.push(
                    <IconButtonsRow key={i}>
                        {itemArrTemp.slice(i, i + itemsPerRow).map((item, index) => item && createItem(item, index))}
                    </IconButtonsRow>
                )
            }
        } else {
            let itemArrTemp = itemArr.filter((x) => !x.hideOnDesktop)

            for (var j = 0; j < itemArrTemp.length; j = j + itemsPerRow) {
                x.push(
                    <IconButtonsRow key={j}>
                        {itemArrTemp.slice(j, j + itemsPerRow).map((item, index) => item && createItem(item, index))}
                    </IconButtonsRow>
                )
            }
        }
        return x
    }

    const createItem = (item: MenuItemProps, index: number) => {
        return (
            <LabeledIconButtonContainer key={index}>
                {item.hint && (
                    <CustomOverlayTrigger
                        trigger={["click", "focus", "hover"]}
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip
                                id="option-tooltip"
                                style={{ pointerEvents: "none", fontFamily: branding.font1, fontSize: "14px" }}
                            >
                                {item.hint && !item.disabled ? (item.hint as any) : ""}
                            </Tooltip>
                        }
                    >
                        <LabeledIconButtonContent
                            onClick={
                                item.disabled
                                    ? () => {}
                                    : (event) => {
                                          event.stopPropagation()
                                          event.preventDefault()
                                          item.onClick()

                                          if (onItemClickHandler) onItemClickHandler()
                                      }
                            }
                            className={(item.disabled ? "disabled" : "") + "  " + (className || "")}
                            isDarkMode={isDarkMode}
                        >
                            <LabeledIconButtonIcon>{item.icon}</LabeledIconButtonIcon>
                            {noLabels || (
                                <LabeledIconButtonLabel isDarkMode={isDarkMode} className={className}>
                                    {item.title}
                                </LabeledIconButtonLabel>
                            )}
                        </LabeledIconButtonContent>
                    </CustomOverlayTrigger>
                )}
                {!item.hint && (
                    <LabeledIconButtonContent
                        onClick={
                            item.disabled
                                ? () => {}
                                : (event) => {
                                      event.stopPropagation()
                                      event.preventDefault()
                                      item.onClick()
                                      if (onItemClickHandler) onItemClickHandler()
                                  }
                        }
                        className={item.disabled ? "disabled" : ""}
                        isDarkMode={isDarkMode}
                    >
                        <LabeledIconButtonIcon>{item.icon}</LabeledIconButtonIcon>
                        {noLabels || (
                            <LabeledIconButtonLabel isDarkMode={isDarkMode} className={className}>
                                {item.title}
                            </LabeledIconButtonLabel>
                        )}
                    </LabeledIconButtonContent>
                )}
            </LabeledIconButtonContainer>
        )
    }

    var itemArr = collapsed ? [] : items()
    //hiding controls from the context menu depending on branding values
    if (itemArr && itemArr.length > 0) {
        branding.contextMenuBranding.contextMenuItemsVisibility.map((item, index) => {
            if (itemArr[index]) itemArr[index].notVisible = !item.visible
            return null
        })
    }

    itemArr = itemArr.filter((x) => !x.notVisible)
    const rows = Math.ceil(itemArr.length / itemsPerRow)

    return (
        <MenuContainer
            backgroundColor={backgroundColor}
            expandedHeight={rows * (noLabels ? 40 : 80) + 10}
            className={clsName + " context-menu-container"}
        >
            {!collapsed && (
                <Menu>
                    <IconButtonsContainer
                        isDarkMode={isDarkMode}
                        className={(collapsed ? "collapsed" : "expanded") + " " + clsName}
                    >
                        {itemList(itemArr)}
                    </IconButtonsContainer>
                </Menu>
            )}
        </MenuContainer>
    )
}

export default ContextMenu

const HoverButtonContainer = styled.div`
    background-color: transparent;
    padding: 10px 10px;
    transition: background-color 0.7s;
    border-radius: 25px;
    color: ${branding.primaryColor};
    fill: white;
    cursor: pointer;
    &:hover {
        background-color: hsl(0, 0%, 80%);
    }

    &.invisible {
        visibility: hidden;
    }
    &.selected svg {
        fill: ${branding.primaryColor};
    }
`

export interface HoverButtonProps {
    onClick: () => void
    Icon?: VGIcon
    selected: boolean
}

export const HoverButton: React.FC<HoverButtonProps> = ({ onClick, Icon, selected }) => {
    return (
        <HoverButtonContainer
            className={selected ? "selected" : ""}
            onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                onClick()
            }}
        >
            {Icon || IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
        </HoverButtonContainer>
    )
}
