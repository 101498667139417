import { GTMEventType, LoginState } from "../branding/GTMBranding"
import branding from "../branding/branding"
import { LoginType } from "../contentArea/login/LoginSiteBranding"
import { getActiveLanguage } from "../globalStates/LanguageState"
import { getMyIdFromLocalStorage, getMyTypeFromLocalStorage } from "../globalStates/LoggedInUser"

declare global {
    interface Window {
        dataLayer: any[] | undefined
    }
}

/* #region MAIN FUNCTION */

export function saveToDataLayer(data: any, eventType: GTMEventType) {
    if (branding.gtmConfig.tagScript) {
        let gaEvent: any = {
            event: eventType as string,
            ...data,
            language: getActiveLanguage(),
            system_loc: "Event Guide",
            login_state: getLoggedInState(),
            customer_type: getCustomerType()
        }

        if (getFairNumber()) {
            gaEvent = { ...gaEvent, exhibition_number: getFairNumber() }
        }

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(gaEvent)

        if ("true" === localStorage.getItem("virtualguide-gtm-debug")) {
            console.log("GTM track", gaEvent)
        }
    }
}

/* #endregion */

/* #region LOGIN EVENT */

export interface LoginEvent {
    item_name: string
    classification: TakeActionEventClass
    content_type: string
    method: string
}

export function trackLogin(method: LoginType) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.login)) return

    let event: LoginEvent = {
        item_name: "Login Button",
        classification: "Login",
        content_type: "Registration",
        method: method
    }

    saveToDataLayer(event, GTMEventType.login)
}

/* #endregion */

/* #region SEARCH EVENT */

export interface SearchEvent {
    search_term: string
    content_type: string
}

export function trackSearch(searchTerm: string, contentType: string) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.search)) return

    let event: SearchEvent = {
        search_term: searchTerm,
        content_type: contentType
    }

    saveToDataLayer(event, GTMEventType.search)
}

/* #endregion */

/* #region SELECT CONTENT EVENT */

export type SelectContentEventClass =
    | "Overallview"
    | "List"
    | "Details"
    | "Favorite"
    | "Enter"
    | "Relevant"
    | "Requests"
    | "Chat"
    | "Favorite"
    | "Send"
    | "Accept"
    | "Decline"
    | "Confirmed"
    | "Declined"
    | "Profile"
    | "View"
    | "Edit"
    | "Download"
    | "Top"
    | "System"
    | "Microphone"
    | "Speaker"
    | "Sound"
    | "ScreenShare"
    | "Switch"
    | "Top"
    | "Join Info desk"
    | "Request Meeting"
    | "Register Interest"
    | "Recommend"
    | "Favorite"
    | "Download"
    | "Backoffice"
    | "Team"
    | "Visitors"
    | "ContactRequest"
    | "MeetingRequest"
    | "AccessRequest"
    | "Statistics"
    | "Business"
    | "Buyers"
    | "Sellers"
    | "ExploreMyConnections"
    | "FindNewPeople"
    | "Organizer"
    | "Organization"
    | "Story"
    | "List"
    | "FillerTile"
    | "ListView"
    | "TileView"
    | "ZoomIn"
    | "ZoomOut"
    | "Navigation"

export interface SelectContentEvent {
    item_name: string
    classification: SelectContentEventClass
    content_type: string
    page_URL: string
    location?: string
    organization_name?: string
    file_type?: string
    file_name?: string
}

export function trackSelectContent(
    itemName: string,
    classification: SelectContentEventClass,
    contentType: string,
    location?: string,
    organizationName?: string,
    fileType?: string,
    fileName?: string
) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.select_content)) return

    let event: SelectContentEvent = {
        item_name: itemName,
        classification: classification,
        content_type: contentType,
        page_URL: window.location.pathname
    }

    if (location) {
        event = { ...event, location: location }
    }

    if (organizationName) {
        event = { ...event, organization_name: organizationName }
    }

    if (fileType) {
        event = { ...event, file_type: fileType }
    }

    if (fileName) {
        event = { ...event, file_name: fileName }
    }

    saveToDataLayer(event, GTMEventType.select_content)
}

/* #endregion */

/* #region TAKE ACTION EVENT */

export type TakeActionEventClass =
    | "Login"
    | "Email"
    | "PrivacyPolicy"
    | "TermsOfUse"
    | "ResendCode"
    | "EmailAddress"
    | "ConfirmationCode"
    | "Password"
    | "ExternalLink"
    | "Meeting"
    | "Registration"
    | "ChangeState"
    | "SetFavorite"
    | "Download"
    | "RegisterInterest"
    | "Meeting"

export interface TakeActionEvent {
    item_name: string
    classification: TakeActionEventClass
    content_type: string
    flag?: string
}

export function trackTakeAction(itemName: string, classification: TakeActionEventClass, contentType: string, flag?: string) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.take_action)) return

    let event: TakeActionEvent = {
        item_name: itemName,
        classification: classification,
        content_type: contentType
    }

    if (flag) {
        event = { ...event, flag: flag }
    }

    saveToDataLayer(event, GTMEventType.take_action)
}

/* #endregion */

/* #region PAGE VIEW EVENT */

export type PageViewEventClass =
    | "Home"
    | "List"
    | "Details"
    | "View"
    | "Registration"
    | "Favorite"
    | "Overallview"
    | "AddAppointment"
    | "EditAppointment"
    | "ViewAppointment"
    | "Profile"
    | "Media"
    | "MyConnections"
    | "FindPeople"
    | "Organizer"
    | "Organization"
    | "Story"
    | "General"
    | "MyOrganization"
    | "RequestMeeting"
    | "RegisterInterest"
    | "Team"
    | "Visitors"
    | "ContactRequest"
    | "MeetingRequest"
    | "AccessRequest"
    | "Statistics"
    | "JoinInfoDesk"
    | "ElementList"

export interface PageViewEvent {
    page_URL: string
    screen_name: string
    classification: PageViewEventClass
    content_type: string
}

let lastNavigation: string | undefined

export function trackPageView(
    pageTitle: string,
    classification: PageViewEventClass,
    contentType: string,
    ignoreDuplicates?: boolean
) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.page_view) || !pageTitle) return

    let newNavigation = window.location.pathname + contentType + pageTitle

    if (ignoreDuplicates) {
        newNavigation += pageTitle
    }
    if (newNavigation === lastNavigation) return

    lastNavigation = newNavigation

    let event: PageViewEvent = {
        page_URL: window.location.pathname,
        screen_name: pageTitle,
        classification: classification,
        content_type: contentType
    }

    if (window.location.pathname.includes("/person/")) {
        event = {
            ...event,
            page_URL: "/person/",
            screen_name: "Person details"
        }
    }

    saveToDataLayer(event, GTMEventType.page_view)
}

/* #endregion */

/* #region SHARE EVENT */

export interface ShareEvent {
    shared_item_name: string
    content_type: string
    sharing_method: string
}

export function trackShareEvent(sharedItemName: string, contentType: string) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.share)) return

    let event: ShareEvent = {
        shared_item_name: sharedItemName,
        content_type: contentType,
        sharing_method: "inAppSharing"
    }

    saveToDataLayer(event, GTMEventType.share)
}

/* #endregion */

/* #region VIEW SEARCH RESULT EVENT */
export interface ViewSearchResultEvent {
    item_name: string
    content_type: string
    location: string
    search_term?: string
}
export function trackViewSearchResult(
    itemId: string,
    itemName: string,
    contentType: string,
    location: string,
    searchTerm?: string
) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.view_search_result_list)) return

    const currentState = window.sessionStorage.getItem("trackViewSearchResult")

    const currentStateAsArray = currentState ? currentState?.split(",") : []

    if (currentStateAsArray?.includes(itemId)) {
        return
    }

    const newState = [currentState, itemId].filter(Boolean).join(",")

    window.sessionStorage.setItem("trackViewSearchResult", newState)

    let event: ViewSearchResultEvent = {
        item_name: itemName,
        content_type: contentType,
        location: location
    }

    if (searchTerm && searchTerm?.length! > 0 && searchTerm !== "[]") {
        event = { ...event, search_term: searchTerm }
    }

    saveToDataLayer(event, GTMEventType.view_search_result_list)
}

/* #endregion */

/* #region VIEW PROMOTION EVENT */
export interface ViewPromotionEvent {
    item_name: string
    content_type: string
    page_URL: string
    banner_type?: string
    organization_name?: string
}

export function trackViewPromotion(itemName: string, organizationId?: string, organizationName?: string, isBanner?: boolean) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.view_promotion)) return

    const currentState = window.sessionStorage.getItem("trackViewPromotion")

    const currentStateAsArray = currentState ? currentState?.split(",") : []

    const itemIdentifier = itemName + organizationId

    if (currentStateAsArray?.includes(itemIdentifier)) {
        return
    }

    const newState = [currentState, itemIdentifier].filter(Boolean).join(",")

    window.sessionStorage.setItem("trackViewPromotion", newState)

    let event: ViewPromotionEvent = {
        item_name: itemName,
        content_type: "Promotion",
        page_URL: window.location.pathname,
        banner_type: "Event Guide Top Banner"
    }

    if (isBanner) {
        event = { ...event, banner_type: "Event Guide Top Banner" }
    }
    if (organizationName) {
        event = { ...event, organization_name: organizationName }
    }

    saveToDataLayer(event, GTMEventType.view_promotion)
}

/* #endregion */

/* #region BANNER CLICK EVENT */
export interface BannerClickEvent {
    item_id: string
    item_name: string
    classification: string
    page_URL: string
    banner_type: string
    organization_name?: string
}

export function trackBannerClick(itemId: string, itemName: string, classification: string, organizationName?: string) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.banner_click)) return

    let event: BannerClickEvent = {
        item_id: itemId,
        item_name: itemName,
        classification: classification,
        page_URL: window.location.pathname,
        banner_type: "Event Guide Top Banner"
    }

    if (organizationName) {
        event = { ...event, organization_name: organizationName }
    }

    saveToDataLayer(event, GTMEventType.banner_click)
}

/* #endregion */

/* #region VIDEO ENGAGEMENT EVENT */
export interface VideoEngagementEvent {
    video_provider: string
    video_title: string
    video_url: string
    video_percent: string
}

export function trackVideoEngagement(eventType: GTMEventType, videoTitle: string, videoUrl: string, videoPercent: number) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.video_engagement)) return

    let event: VideoEngagementEvent = {
        video_provider: "Event Guide",
        video_title: videoTitle,
        video_url: window.location.pathname,
        video_percent: videoPercent.toString()
    }

    saveToDataLayer(event, eventType)
}

/* #endregion */

/* #region UTILITIES */

function getLoggedInState(): LoginState {
    return getMyIdFromLocalStorage() ? "Logged_in" : "Logged_out"
}

function getFairNumber(): string | undefined {
    return branding.gtmConfig.fairNumber || undefined
}

function getCustomerType(): string {
    const myType = getMyTypeFromLocalStorage()

    if (!myType) {
        return branding.gtmConfig.customerTypes.other
    }
    switch (myType) {
        case "speaker":
        case "staff":
            return branding.gtmConfig.customerTypes.staff
        case "press":
            return branding.gtmConfig.customerTypes.press
        case "guest":
            return branding.gtmConfig.customerTypes.guest
        case "attendee":
        case "none":
            return branding.gtmConfig.customerTypes.attendee
        default:
            return branding.gtmConfig.customerTypes.other
    }
}

/**
 * https://corussoft.atlassian.net/wiki/spaces/eventguide/pages/9466937442/TagManager+and+SSO
 * @returns
 */
export function generateGlParam() {
    const cookies: { [key in string]: string } = Object.fromEntries(document.cookie.split("; ").map((c) => c.split("=")))

    const modifiedCookies: { [key in string]: string } = {}
    for (const key in cookies) {
        // Only use TagManager Cookies
        if (key !== "_ga" && !key.startsWith("_ga_")) continue

        const cookieValue = cookies[key]
        let newCookieValue = cookieValue

        // We want everything after the second dot
        newCookieValue = newCookieValue.substring(cookieValue.indexOf("."))
        newCookieValue = newCookieValue.substring(cookieValue.indexOf("."))

        modifiedCookies[key] = newCookieValue
    }

    if (!(window as any).google_tag_data?.glBridge?.generate) {
        console.warn("window.google_tag_data.glBridge.generate missing. Analytics maybe not working?")
        return ""
    }
    const glToken = (window as any).google_tag_data.glBridge.generate(modifiedCookies)
    return glToken
}

/* #endregion */
