import { SyntheticEvent, useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import {
    IconBottomLeft,
    IconBottomRight,
    IconCameraOff,
    IconTopLeft,
    IconTopRight,
    IconTrash,
    IconUpload
} from "../../../ui/Icons"
import { DeviceMessage, DeviceMessageContainer, DeviceSettingsRoot, SettingsGroup } from "../AudioSettings/AudioSettings.styled"
import {
    CustomBackgroundActionsOverlay,
    IconContainer,
    NoContentShift,
    TrashIconContainer
} from "../BackgroundSettings/BackgroundSettings.styled"
import { SettingsSectionTitle } from "../Settings/Settings.styled"
import { PreviewVideoContainer, PreviewVideoOverlay } from "../VideoSettings/VideoSettings.styled"
import { LogoGalleryContainer, LogoGalleryItem, LogoPositionButton, LogoPositionButtonsContainer } from "./LogoSettings.styled"
import PreviewVideo from "../PreviewVideo/PreviewVideo"

import { LogoPositions } from "../../enums/LogoPosition"
import branding from "../../../branding/branding"
import { useVideoContext } from "../../context/VideoContext"
import { PreMeetingSettingType, usePreMeetingSettingContext } from "../../context/PreMeetingSettingsContext"
import { useDevicesV2 } from "../../hooks/useDevices"
import { UserMediaItem } from "../../../backendServices/UserMediaServices"
import SettingsErrorMessage from "../../../conference/components/settings/SettingsErrorMessage/SettingsErrorMessage"
import { MeetingStatus, useMeetingStatus } from "amazon-chime-sdk-component-library-react"

function LogoSettings() {
    const {
        setSelectedLogo,
        uploadLogo,
        getLogoGalleryItems,
        getSelectedLogo,
        removeLogo,
        setLogoPosition,
        getLogoPosition,
        getIsLogoProcessorActive,
        getCustomUploadedLogos,
        deleteCustomUploadedLogo,
        getErrorMessage,
        resetErrorMessage
    } = useVideoContext()
    const logoInputRef = useRef<HTMLInputElement | null>(null)
    const preMeetingSettingsContext = usePreMeetingSettingContext()
    const [hasCameraPermission, setHasCameraPermission] = useState(true)
    const devices = useDevicesV2()

    const meetingStatus = useMeetingStatus()
    const videoContext = useVideoContext()
    let isPreviewVisible = true

    if (meetingStatus !== MeetingStatus.Succeeded) {
        isPreviewVisible = preMeetingSettingsContext.getSetting(PreMeetingSettingType.VIDEOINPUTSTATE)
    } else if (meetingStatus === MeetingStatus.Succeeded) {
        isPreviewVisible = videoContext.isVideoEnabled()
    }

    useEffect(() => {
        preMeetingSettingsContext.applyStoredVideoSettings()
        return () => {
            resetErrorMessage()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setHasCameraPermission(devices.hasVideoInputPermission())
        // eslint-disable-next-line
    }, [devices])

    return (
        <>
            {hasCameraPermission ? (
                <DeviceSettingsRoot>
                    <SettingsGroup>
                        <PreviewVideoContainer>
                            {isPreviewVisible ? (
                                <NoContentShift>
                                    <PreviewVideo />
                                    <PreviewVideoOverlay>
                                        <span>{branding.audioVideoSettings.preview}</span>
                                    </PreviewVideoOverlay>
                                </NoContentShift>
                            ) : (
                                <DeviceMessageContainer>
                                    <DeviceMessage>
                                        <IconCameraOff fill="#fff" width="25px" height="25px" />
                                    </DeviceMessage>
                                    <DeviceMessage>
                                        {branding.communicationAreaAudioVideoSettingsVideoDisabledMessage}
                                    </DeviceMessage>
                                </DeviceMessageContainer>
                            )}
                        </PreviewVideoContainer>
                        <LogoPositionButtonsContainer>
                            <LogoPositionButton
                                isActive={getLogoPosition() === LogoPositions.TOP_RIGHT && getIsLogoProcessorActive()}
                                onClick={() => setLogoPosition(LogoPositions.TOP_RIGHT)}
                            >
                                {IconTopRight({ fill: "#fff", width: "12", height: "12" })}
                                {branding.audioVideoSettings.radioLogoPositionTopRight}
                            </LogoPositionButton>
                            <LogoPositionButton
                                isActive={getLogoPosition() === LogoPositions.TOP_LEFT && getIsLogoProcessorActive()}
                                onClick={() => setLogoPosition(LogoPositions.TOP_LEFT)}
                            >
                                {IconTopLeft({ fill: "#fff", width: "12", height: "12" })}
                                {branding.audioVideoSettings.radioLogoPositionTopLeft}
                            </LogoPositionButton>
                            <LogoPositionButton
                                isActive={getLogoPosition() === LogoPositions.BOTTOM_RIGHT && getIsLogoProcessorActive()}
                                onClick={() => setLogoPosition(LogoPositions.BOTTOM_RIGHT)}
                            >
                                {IconBottomLeft({ fill: "#fff", width: "12", height: "12" })}
                                {branding.audioVideoSettings.radioLogoPositionBottomRight}
                            </LogoPositionButton>
                            <LogoPositionButton
                                isActive={getLogoPosition() === LogoPositions.BOTTOM_LEFT && getIsLogoProcessorActive()}
                                onClick={() => setLogoPosition(LogoPositions.BOTTOM_LEFT)}
                            >
                                {IconBottomRight({ fill: "#fff", width: "12", height: "12" })}
                                {branding.audioVideoSettings.radioLogoPositionBottomLeft}
                            </LogoPositionButton>
                        </LogoPositionButtonsContainer>
                    </SettingsGroup>
                    <SettingsGroup>
                        <Container className="p-0">
                            <Row>
                                <Col>
                                    <SettingsSectionTitle>
                                        {branding.audioVideoSettings.logoGalleryTitleLabel}
                                    </SettingsSectionTitle>
                                </Col>
                                <Col>
                                    <IconContainer
                                        onClick={() => {
                                            if (logoInputRef.current) {
                                                logoInputRef.current.value = ""
                                            }
                                            logoInputRef.current?.click()
                                        }}
                                    >
                                        {IconUpload({ fill: "#fff", width: "15px", height: "15px" })}
                                    </IconContainer>
                                    <input
                                        ref={logoInputRef}
                                        onChange={(e: SyntheticEvent) => {
                                            uploadLogo(e)
                                        }}
                                        type="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        style={{ display: "none" }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <LogoGalleryContainer>
                                        <LogoGalleryItem isActive={!getSelectedLogo()} onClick={() => removeLogo()} logo={""}>
                                            {branding.audioVideoSettings.radioNoLogo}
                                        </LogoGalleryItem>

                                        {getLogoGalleryItems()?.length &&
                                            getLogoGalleryItems().map((logoGalleryItem, index) => {
                                                return (
                                                    <LogoGalleryItem
                                                        key={index}
                                                        isActive={getSelectedLogo() === logoGalleryItem.url}
                                                        logo={logoGalleryItem.url}
                                                    >
                                                        <CustomBackgroundActionsOverlay
                                                            onClick={() => {
                                                                setSelectedLogo(logoGalleryItem)
                                                            }}
                                                        ></CustomBackgroundActionsOverlay>

                                                        {getCustomUploadedLogos()
                                                            .map((item: UserMediaItem) => item.url)
                                                            .includes(logoGalleryItem.url) && (
                                                            <>
                                                                <div className="actions">
                                                                    <TrashIconContainer
                                                                        onClick={() =>
                                                                            deleteCustomUploadedLogo(logoGalleryItem.url)
                                                                        }
                                                                    >
                                                                        {IconTrash({
                                                                            width: "12px",
                                                                            height: "12x",
                                                                            fill: "#fff"
                                                                        })}
                                                                    </TrashIconContainer>
                                                                </div>
                                                            </>
                                                        )}
                                                    </LogoGalleryItem>
                                                )
                                            })}
                                    </LogoGalleryContainer>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="p-0 pt-2">
                            <Row>
                                <Col>
                                    <SettingsErrorMessage message={getErrorMessage()} />
                                </Col>
                            </Row>
                        </Container>
                    </SettingsGroup>
                </DeviceSettingsRoot>
            ) : (
                <DeviceSettingsRoot>
                    <DeviceMessageContainer>
                        <DeviceMessage>
                            <IconCameraOff fill="#fff" width="25px" height="25px" />
                        </DeviceMessage>
                        <DeviceMessage>{branding.communicationAreaAudioVideoSettingsVideoPermissionMessage}</DeviceMessage>
                    </DeviceMessageContainer>
                </DeviceSettingsRoot>
            )}
        </>
    )
}

export default LogoSettings
