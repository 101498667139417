import styled from "styled-components"
import { useEffect, useState } from "react"
import * as React from "react"
import CenteredLoader from "../ui/CenteredLoader"
import { useLanguageState } from "../globalStates/LanguageState"
import TopBar from "../navigationArea/TopBar"
import EmptyTile from "./reception/EmptyTile"
import { ContentScrollContainer } from "../ui/ScrollContainer"
import GuestUserBanner from "./guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "./guestUserBanner/GuestUserBannerSharedState"
import { Suggestion } from "../backendServices/Types"
import { useSearchContext } from "./useSearchContext"
import { ResultListLayout } from "../ui/ResultListLayout"
import branding from "../branding/branding"
import { OptionTypeBase, ValueType, ActionMeta, components } from "react-select"
import { StyledSelect } from "./GlobalStyledComponents"
import { useAppState } from "../globalStates/AppState"
import { OnlyBookmarksButton } from "./business/BusinessAreaPageContent"
import { device, MobileVersionContainer } from "../utils/Device"
import TopBannerSharedState from "./advertisingBanner/TopBannerSharedState"
import { useSuggestContext } from "../navigationArea/useSuggestContext"
import InView from "react-intersection-observer"
import { CategoryImg } from "../ui/BadgeArea"
import { SelectThemeCustom } from "../conference/components/settings/Settings"
import { useHistory } from "react-router"
import { ResetFilterButton } from "./detailPages/components/ResetFilterButtonComponent"
import { trackSearch } from "../utils/GTMTracking"
import { useLoggedInState } from "../globalStates/LoggedInUser"

/* #region  Dropdown Custom Components Section  */
const CustomOptionRoot = styled(components.Option)`
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${branding.globalSearchResultPage.dropdownOptionColor ?? "#202428"} !important;
    background-color: transparent !important;
    font-family: ${branding.font1};
    padding: 10px 15px !important;

    &:hover {
        background-color: #ccc !important;
        cursor: pointer;
    }
`
export const CheckboxParent = styled.div`
    display: flex;
    align-items: center;
    font: ${branding.font1};
    margin-right: 20px;
`

const CheckboxInput = styled.input`
    min-width: 12px;
    width: 12px;
    height: 12px;
    background: #fff;
    border: 1px solid #000;
`

export const Checkbox = (props: any) => <CheckboxInput type="checkbox" {...props} />

export const CustomSelectOption = ({ children, ...props }: any) => {
    const emptyCheckBoxes = props.selectProps.emptyCheckBoxes
    return (
        <CustomOptionRoot {...props}>
            <div className="d-flex">
                <CheckboxParent style={{ marginRight: props.data.iconSmallUrl ? "10px" : "20px" }}>
                    <Checkbox checked={props.isSelected && !emptyCheckBoxes} readOnly />
                </CheckboxParent>
                {props.data.iconSmallUrl && (
                    <div style={{ display: "flex", justifyContent: "center", width: "40px" }}>
                        <CategoryImg
                            style={{ marginTop: "1px", marginRight: "5px" }}
                            key={props.data.id + props.data.badgeName + "img"}
                            src={props.data.iconSmallUrl}
                        />
                    </div>
                )}
                {children}
            </div>
        </CustomOptionRoot>
    )
}

const CustomPlaceholderRoot = styled(components.Placeholder)`
    font-family: ${branding.font1};

    & .placeholder-default {
        display: flex;
        align-items: center;
        color: ${branding.globalSearchResultPage.dropdownPlaceholderDefaultColor ?? "#727272"};
    }

    & .placeholder-custom {
        display: flex;
        align-items: center;
        color: ${branding.globalSearchResultPage.dropdownPlaceholderCustomColor ?? "#202428"};
    }
`

export const CustomSelectPlaceholder = ({ children, ...props }: any) => {
    const selectedValues = props.getValue()
    const count = selectedValues.length
    const firstEntitySelectedLabel = selectedValues[0]?.label
    const placeholderDefault = props.selectProps.placeholder
    const placeholderCustom = `+${count - 1}`
    const emptyCheckBoxes = props.selectProps.emptyCheckBoxes
    return (
        <CustomPlaceholderRoot {...props}>
            {emptyCheckBoxes || !count || count === 0 ? (
                <div className="placeholder-default">{placeholderDefault}</div>
            ) : (
                <div className="placeholder-custom">
                    <div className="mr-2">{firstEntitySelectedLabel}</div>
                    {count > 1 && <div>{placeholderCustom}</div>}
                </div>
            )}
        </CustomPlaceholderRoot>
    )
}
/* #endregion */

const FilterSectionWrapper = styled.div<{ showShadow?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 25px 30px;
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};
    position: relative;
    z-index: 10;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};

    & .dropdown-resetBtn-wrapper {
        height: 35px;
    }

    & [class*="-control"],
    [class*="-IndicatorsContainer"] {
        height: 100%;
        min-height: unset !important;
    }

    & [class*="-control"] {
        padding-left: 15px;
    }

    & [class*="-ValueContainer"],
    [class*="-placeholder"] {
        padding: 0;
        margin: 0;
    }

    & [class*="-menu"],
    [class*="-control"]:hover {
        box-shadow: unset !important;
        border: 1px solid ${branding.globalSearchResultPage.dropdownBorderColor ?? "#727272"};
    }

    & .svg-icons-wrapper {
        span,
        svg {
            pointer-events: none;
        }
    }

    @media ${device.mobile} {
        padding-left: 15px;
        padding-right: 15px;
    }
`

const ContentRoot = styled.div<{ guestBannerHeight: number; additionalHeight: number }>`
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    display: flex;
    flex-direction: column;
    height: ${(props) => `calc(100vh - ${props.guestBannerHeight}px - ${props.additionalHeight}px)`};
    overflow-x: hidden;

    & .ScrollbarsCustom-Scroller {
        overflow-x: hidden !important;
        padding-bottom: 20px;
    }

    & .ScrollbarsCustom-Content {
        overflow: hidden;
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    }

    & .svg-icons-wrapper {
        span,
        svg {
            pointer-events: none;
        }
    }
`

const ContainerRoot = styled.div``

const NextPageLoader = styled(CenteredLoader)`
    height: 120px;
`

/*********************************************************************************************
 * site assembly
 **********************************************************************************************/
const ResultsContainer = styled.div`
    height: calc(100vh - 40px);
    overflow: hidden;
`

const scrollPositionKey: string = "scrollPositionGlobalSearch"

interface ResultPageHistoryState {
    scrollPosition: number
}

const GlobalSearchResultPage: React.FunctionComponent = React.memo((props) => {
    // GLOBAL STATES
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const appState = useAppState()
    const suggestState = useSuggestContext()
    const history = useHistory()
    const userState = useLoggedInState()

    // BANNERS
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()

    // SEARCH
    const [loaded, setLoaded] = useState<boolean>(false)
    const { searchParams, results, isLoading, searchFunctions } = useSearchContext()
    const entityFilterOptions = branding.globalSearchResultPage.searchEntities.map((entity) => {
        return { label: entity.title, value: entity.id, entityType: entity.entityType }
    })

    //SCROLL PERSISTANCE
    const [scrollPosition] = useState<number>(parseInt(window.sessionStorage.getItem(scrollPositionKey) ?? "0"))

    // OTHER
    const { hideOnScroll, setHideOnScroll } = TopBannerSharedState()

    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setHideOnScroll(scrollValues.scrollTop > 0)
        } else {
            setHideOnScroll(true)
        }

        window.sessionStorage.setItem(scrollPositionKey, scrollValues.scrollTop.toString())
    }

    const resetScroll = () => {
        history.replace({
            state: {
                ...(history.location.state as ResultPageHistoryState),
                scrollPosition: 0
            }
        })
    }
    const showNetworkingWarning = (): boolean => {
        return (
            searchParams.entityTypes &&
            searchParams.entityTypes[0] === "networking_user" &&
            !userState.isMatchActive() &&
            searchParams.entityTypes.length === 1
        )
    }

    const searchParamsForTracking: string = [
        searchParams.entityTypes,
        searchParams.searchValue,
        JSON.stringify(searchParams.searchEntities)
    ]
        .filter(Boolean)
        .join(",")

    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(branding.globalSearchResultPage.pageTitle)

        if (localStorage.getItem("tempSuggestion")) {
            let suggestion: Suggestion = JSON.parse(localStorage.getItem("tempSuggestion") || "")
            suggestState.addExternalSuggestion(suggestion)

            localStorage.removeItem("tempSuggestion")
        }
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        appState.setIsOnExhibitorsPageContentOrGlobalSearchResultPageContent(true)
        return function cleanUp() {
            appState.setIsOnExhibitorsPageContentOrGlobalSearchResultPageContent(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!loaded) return
        setLoaded(true)
        resetScroll()
    }, [searchParams.searchValue]) // eslint-disable-line

    useEffect(() => {
        if (searchParams) {
            trackSearch(searchParamsForTracking, "Global")
        }
        // eslint-disable-next-line
    }, [searchParamsForTracking])

    useEffect(() => {
        window.sessionStorage.removeItem(scrollPositionKey)
    }, [scrollPosition])

    let content: JSX.Element
    let totalCount = 0
    Object.values(results).forEach((result) => {
        if (result) totalCount += result.count
    })

    if (searchParams.page === 0 && isLoading) {
        content = (
            <div style={{ marginTop: "15%" }}>
                <CenteredLoader />
            </div>
        )
    } else {
        const hasMoreData = searchFunctions.hasMoreData()
        content = (
            <ContentRoot
                guestBannerHeight={guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0}
                additionalHeight={150}
            >
                <ContentScrollContainer handleScroll={onScroll} initialScrollTop={scrollPosition}>
                    {totalCount === 0 && !hasMoreData ? (
                        <div style={{ marginTop: "10%" }}>
                            <EmptyTile
                                header={
                                    showNetworkingWarning()
                                        ? branding.communicationArea.activateNetworkingText
                                        : branding.sideIconBar.emptyResultMessage
                                }
                                bgColor="transparent"
                                hideButton={true}
                            />
                        </div>
                    ) : (
                        <>
                            <ResultListLayout
                                sections={results}
                                isSponsor={false}
                                isMediaPartner={false}
                                isStartup={false}
                                isResultPage={true}
                                searchParams={searchParamsForTracking}
                            />
                            {hasMoreData && (
                                <InView
                                    threshold={0.1}
                                    onChange={(inView) => {
                                        if (inView) searchFunctions.nextPage()
                                    }}
                                >
                                    <NextPageLoader />
                                </InView>
                            )}
                        </>
                    )}
                </ContentScrollContainer>
            </ContentRoot>
        )
    }

    return (
        <>
            <div style={{ background: "#fff" }}>
                <GuestUserBanner setRef={setGuestUserBannerRef} />
                <TopBar />

                {/* FILTER SECTION */}
                <FilterSectionWrapper showShadow={hideOnScroll}>
                    <div className="dropdown-resetBtn-wrapper d-flex w-100">
                        {/* DROPDOWN */}
                        <StyledSelect
                            components={{
                                Option: CustomSelectOption,
                                Placeholder: CustomSelectPlaceholder
                            }}
                            placeholder={branding.globalSearchResultPage.dropdownPlaceholder}
                            isMulti={true}
                            isSearchable={false}
                            isClearable={true}
                            options={entityFilterOptions}
                            value={entityFilterOptions.filter((option) =>
                                searchParams.searchEntities.find((searchEntity) => searchEntity.id === option.value)
                            )}
                            hideSelectedOptions={false}
                            controlShouldRenderValue={false}
                            closeMenuOnSelect={false}
                            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                                if (value !== null) {
                                    const newSearchEntities = value.map((v: OptionTypeBase) => {
                                        return { title: v.label, id: v.value, entityType: v.entityType }
                                    })
                                    searchFunctions.setSearchEntities(newSearchEntities)
                                }
                                resetScroll()
                            }}
                            theme={SelectThemeCustom}
                            emptyCheckBoxes={searchParams.emptyCheckBoxes ?? false}
                            height={"38px"}
                        />

                        {/* RESET BUTTON */}
                        <ResetFilterButton
                            onResetFilterButtonClick={() => {
                                searchFunctions.reset()
                                resetScroll()
                            }}
                            filterDisabled={searchParams.emptyCheckBoxes}
                            text={branding.globalSearchResultPage.resetBtnTitle}
                            visibility={true}
                            buttonHeight={"38px"}
                        />

                        <MobileVersionContainer>
                            <div style={{ marginLeft: "10px" }}>
                                <OnlyBookmarksButton
                                    bookmarkFilter={searchParams.showOnlyBookmarks}
                                    updateBookmarkFilter={() => searchFunctions.setBookmarksOnly(!searchParams.showOnlyBookmarks)}
                                />
                            </div>
                        </MobileVersionContainer>
                    </div>
                </FilterSectionWrapper>

                {/* RESULTS */}
                <ResultsContainer>
                    <ContainerRoot>{content}</ContainerRoot>
                </ResultsContainer>
            </div>
        </>
    )
})

export default GlobalSearchResultPage
